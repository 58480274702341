import React, { useState } from "react";

import ContentControlGroup from "../group";
import ContentControlInput from "../input";
import { StyledInput } from "./content-control-font-size-styled";

const ContentControlFontStyleView = ({style, onChangeItems}) => {
  const handleChange = (value) => {
    onChangeItems({
      key: "style",
      data: {
        fontSize: +value
      }
    });
  }

  return (
    <ContentControlGroup>
      <ContentControlInput value={style.fontSize} onChange={handleChange} />
    </ContentControlGroup>
  );
};

export default ContentControlFontStyleView;
