import request from "../../utils/request";

export const login = (data) => request.post(`login`, data);

export const register = (data) => request.post(`register`, data);

export const sendResetEmail = (data) => request.post(`email/reset/send`, data);

export const resetPassword = (data) =>
  request.post(`password/reset/${data.code}`, { password: data.password });
