import React, { useEffect, useState } from "react";

import ContentControlBorderView from "./content-control-border-view";

const ContentControlBorderContainer = props => {
  const [localWidth, setLocalWidth] = useState(props.style.borderWidth);
  const [show, setShow] = useState(!!props.style.borderWidth);

  useEffect(() => {
    if (props.defaultShow) handleAdd();
    else handleRemove();
  }, [props.defaultShow]);

  useEffect(() => {
    // if (props.style.borderWidth) setLocalWidth(props.style.borderWidth);
  }, [props.style.borderWidth]);

  useEffect(() => {
    setShow(!!props.style.borderWidth);
  }, [props.style]);

  const handleChange = ({ value, key }) => {
    props.onChangeItems({
      key: "style",
      data: {
        [key]: value
      }
    });
  };

  const handleChangeLocalWidth = value => {
    console.log("value", value);

    setLocalWidth(value);

    if (value) {
      handleChange({ value, key: "borderWidth" });
    } else handleChange({ value: 1, key: "borderWidth" });
  };

  const handleAdd = () => {
    console.log("handleAdd");

    props.onChangeItems({
      key: "style",
      data: {
        borderWidth: 1
        // borderColor: "rgba(0,0,0,1)"
      }
    });

    setLocalWidth(1);
    setShow(true);
  };

  const handleRemove = () => {
    console.log("handleRemove");

    props.onChangeItems({
      key: "style",
      data: {
        borderWidth: 0
      }
    });

    setLocalWidth(0);
    setShow(false);

    // props.onRemove({
    //   // keys: ["borderWidth", "borderColor"],
    //   keys: ["borderWidth"],
    //   type: "style",
    // });
  };

  return (
    <ContentControlBorderView
      {...props}
      show={show}
      localWidth={localWidth}
      onChange={handleChange}
      onAdd={handleAdd}
      onRemove={handleRemove}
      onChangeLocalWidth={handleChangeLocalWidth}
    />
  );
};

export default ContentControlBorderContainer;
