import { responsiveSize } from "../../../../../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  margin-right: ${responsiveSize(40)};

  display: flex;
  flex-direction: column;
  flex: 3.8;
`;

export const StyledDescription = styled.p`
  margin-bottom: ${responsiveSize(32)};

  font-size: ${responsiveSize(16)};
  line-height: ${responsiveSize(22)};
`;

export const StyledTitle = styled.p`
  margin-bottom: ${responsiveSize(16)};

  font-weight: 500;
  font-size: ${responsiveSize(20)};
  line-height: ${responsiveSize(22)};
`;

export const StyledDate = styled.p`
  margin-bottom: ${responsiveSize(4)};

  font-weight: 500;
  font-size: ${responsiveSize(12)};
  line-height: ${responsiveSize(22)};

  opacity: 0.5;
`;

export const StyledText = styled.p`
  font-size: ${responsiveSize(16)};
  line-height: ${responsiveSize(22)};
`;

export const StyledItem = styled.div`
  &:not(:last-child) {
    margin-bottom: ${responsiveSize(10)};
  }
`;
