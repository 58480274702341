import React, { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";

import Modal from "../../../components/modal";
import ModalContainerForm from "./components/form";
import { StyledModal } from "./modal-container-styles";

const ModalContainerView = ({
  showModal,
  children,
  leftComponent,
  onClose,
}) => {
  return (
    <StyledModal as={Modal} showModal={showModal} onClose={onClose}>
      {/* <ModalContainerInfo /> */}
      {leftComponent}

      <ModalContainerForm>{children}</ModalContainerForm>
    </StyledModal>
  );
};

export default ModalContainerView;
