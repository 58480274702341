import { responsiveSize } from "../../../../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  /* margin-right: ${responsiveSize(64)}; */
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledButton = styled.div.attrs({
  // styleContainer: { marginRight: responsiveSize(24) },

  styleText: {
    textTransform: "uppercase"
  }
})``;

export const StyledIcon = styled.img`
  height: ${responsiveSize(24)};

  transition: all 0.1s;

  cursor: pointer;

  &:hover {
    transition: all 0.3s;

    opacity: 0.6;
  }
`;

export const StyledLine = styled.div`
  width: ${responsiveSize(1)};
  height: ${responsiveSize(29)};

  margin: 0 ${responsiveSize(16)};

  background: #3e4141;

  opacity: 0.1;
`;
