import React, { useEffect, useMemo, useState } from "react";
import {
  StyledContainer,
  StyledIcon,
  StyledLabel,
  StyledValue,
} from "./textarea-styled";

import Icon from "../icon";
import { responsiveSize } from "../../utils/font";

const InputView = ({
  className,
  width = "100%",
  color = "#3E4141",
  value,
  rows = 5,
  valid = true,
  defaultValue,
  placeholder,
  onChange,
  onBlur,
}) => {
  return (
    <StyledContainer
      className={className}
      style={{ width, color }}
      placeholder={placeholder}
      valid={valid}
      value={value}
      rows={rows}
      onChange={(event) => onChange(event.target.value)}
      onBlur={onBlur}
    />
  );
};

export default InputView;
