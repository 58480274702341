const categoryOptions = [
  {
    label: "Presentation",
    value: "presentation",
  },
  {
    label: "Presentation2",
    value: "presentation2",
  },
  {
    label: "Presentation3",
    value: "presentation3",
  },
  {
    label: "Presentation4",
    value: "presentation4",
  },
  {
    label: "Presentation5",
    value: "presentation5",
  },
];

export { categoryOptions };
