import React from "react";

import { Button, Icon } from "../../../../components";

import {
  StyledContainer,
  StyledContent,
  StyledValue
} from "./editor-content-scaling-styled";

const EditorContentScalingView = ({
  scale,
  onToggleFullScreen,
  onChangeScale
}) => {
  return (
    <StyledContainer>
      <StyledContent>
        <Icon
          type="button"
          name="minus"
          size="26"
          onClick={() => onChangeScale(scale - 0.05)}
        />

        <StyledValue>{Math.floor(scale * 100)}%</StyledValue>

        <Icon
          type="button"
          name="plus"
          size="26"
          onClick={() => onChangeScale(scale + 0.05)}
        />
      </StyledContent>

      <Button
        title="Full screen"
        icon={require(`./image/resize.svg`)}
        round
        size="base"
        backgroundColor="#D8D9D9"
        titleColor="#B2B3B3"
        onClick={onToggleFullScreen}
      />
    </StyledContainer>
  );
};

export default EditorContentScalingView;
