import { Button, Icon } from "../../../../../../components";
import { StyledButton, StyledContainer } from "./editor-header-export-styled";

import CanvasHeaderPublishingModal from "./components/modals/publishing";
import React from "react";

// import { Button } from "semantic-ui-react";

const EditorHeaderExportView = ({
  showModalExport,
  onChangeShowModalExport,
  onExport
}) => {
  return (
    <StyledContainer>
      <StyledButton
        as={Button}
        // styleContainer={{ marginRight: 24 }}
        title="Save"
        icon={require(`./images/save.svg`)}
        round
        backgroundColor="#D8D9D9"
        titleColor="#B2B3B3"
        onClick={onChangeShowModalExport}
      />

      {/* <Button
        title="Export"
        icon={require(`./images/export.svg`)}
        onClick={onExport}
      /> */}

      <CanvasHeaderPublishingModal
        showModal={showModalExport}
        onClose={onChangeShowModalExport}
      />
    </StyledContainer>
  );
};

export default EditorHeaderExportView;
