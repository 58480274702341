import { responsiveSize } from "../../../../../../../../utils/font";
import styled from "styled-components";

export const StyledIcon = styled.div`
  opacity: ${props => (props.active ? 1 : 0.5)};
  transition: all 0.5s;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: ${responsiveSize(16)};
  }
`;

export const StyledColor = styled.div`
  margin-right: ${responsiveSize(16)};
`;

export const StyledColorPicker = styled.div`
  margin-right: ${responsiveSize(16)};
`;