import * as Actions from "./constants";

export const initState = {
  store: [],
  storeLoading: false,

  gallery: [],
  galleryLoading: false,

  project: null,
  projectLoading: false,

  createProjectLoading: false,
};

/**
 * Common reducer
 * @param state
 * @param action
 * @returns {*}
 */
function commonReducer(state = initState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case Actions.LOADING_STORE_DATA:
      return {
        ...state,
        storeLoading: true,
      };
    case Actions.LOADING_STORE_DATA_SUCCESS:
      return {
        ...state,
        ...{ store: payload, storeLoading: false },
      };
    case Actions.LOADING_STORE_DATA_ERROR:
      return {
        ...state,
        storeLoading: false,
      };

    case Actions.LOADING_GALLERY_DATA:
      return {
        ...state,
        galleryLoading: true,
      };
    case Actions.LOADING_GALLERY_DATA_SUCCESS:
      return {
        ...state,
        ...{ gallery: payload, galleryLoading: false },
      };
    case Actions.LOADING_GALLERY_DATA_ERROR:
      return {
        ...state,
        galleryLoading: false,
      };

    case Actions.LOADING_PROJECT_DATA:
      return {
        ...state,
        projectLoading: true,
      };
    case Actions.LOADING_PROJECT_DATA_SUCCESS:
      return {
        ...state,
        ...{ project: payload, projectLoading: false },
      };
    case Actions.LOADING_PROJECT_DATA_ERROR:
      return {
        ...state,
        projectLoading: false,
      };

    case Actions.CREATE_PROJECT:
      return {
        ...state,
        createProjectLoading: true,
      };
    case Actions.CREATE_PROJECT_SUCCESS:
      console.log("CREATE_PROJECT_SUCCESS", payload);
      let resProject = state.project.unshift(payload);

      return {
        ...state,
        ...{ project: resProject, createProjectLoading: false },
      };
    case Actions.CREATE_PROJECT_ERROR:
      return {
        ...state,
        createProjectLoading: false,
      };

    default:
      return state;
  }
}

export default commonReducer;
