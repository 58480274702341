import {
  itemsSelector,
  selectedItemSelector,
  selectedSlideIdSelector,
  selectedSliderIndexesSelector,
  slidesSelector
} from "../../../../../../modules/editor/selectors";
import {
  saveItems,
  saveSlides
} from "../../../../../../modules/editor/actions";

import EditorSidebarContainer from "./editor-sidebar-container";
import { connect } from "react-redux";
import { logout } from "../../../../../../modules/auth/actions";

const mapStateToProps = state => ({
  items: itemsSelector(state),
  selectedItem: selectedItemSelector(state),
  slides: slidesSelector(state),
  selectedSlideId: selectedSlideIdSelector(state),
  selectedSliderIndexes: selectedSliderIndexesSelector(state)
});

const mapDispatchToProps = {
  saveItems,
  saveSlides,
  logout
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorSidebarContainer);
