import {
  StyledContainer,
  StyledContent,
  StyledHeader,
  StyledInfo,
  StyledMeta,
  StyledMetaLabel,
  StyledMetaValue,
  StyledName,
  StyledPreview,
} from "./grid-card-template-styled";

import Icon from "../../../icon";
import { Link } from "react-router-dom";
import MouseEffect from "../../../mouse-effect";
import React from "react";
import { animated } from "react-spring";

const StoreProjectsGridCardView = ({ data, animateStyle }) => {
  return (
    <MouseEffect>
      <StyledContainer as={animated.div} style={animateStyle}>
        <Link to="/canvas/new">
          <StyledHeader>
            <StyledPreview
              image={data.preview}
              // image={require("./images/1.jpg")}
            />
          </StyledHeader>
        </Link>

        <StyledContent>
          <StyledInfo>
            <StyledName>{data.name}</StyledName>

            <Icon name="arrow-down" />
          </StyledInfo>

          <StyledMeta>
            <StyledMetaLabel>Size</StyledMetaLabel>

            <StyledMetaValue>2048x1024</StyledMetaValue>
          </StyledMeta>
        </StyledContent>
      </StyledContainer>
    </MouseEffect>
  );
};

export default StoreProjectsGridCardView;
