import * as yup from "yup";

import { Button, Input } from "../../components";
import {
  StyledButtonSocial,
  StyledContainer,
  StyledDescription,
  StyledForm,
  StyledInput,
  StyledOr,
  StyledRow,
  StyledTitle,
} from "./modal-reset-password-styles";

import ModalContainer from "../root/container";
import ModalInfo from "../root/info";
import React from "react";
import { useFormik } from "formik";

const validationSchema = yup.object().shape({
  password: yup.string().required().min(4).max(20),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password"), null])
    .required("Password confirm is required"),
});

const ModalResetPasswordView = ({
  className,
  showModal,
  loading,
  onSubmit,
  onClose,
}) => {
  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirm: "",
    },
    validationSchema,
    onSubmit,
  });

  return (
    <ModalContainer
      showModal={showModal}
      leftComponent={<ModalInfo />}
      onClose={onClose}
    >
      <StyledContainer className={className}>
        <StyledTitle>New password</StyledTitle>

        <StyledDescription>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel
          tempus dui, et interdum velit.
        </StyledDescription>

        <StyledForm>
          <StyledInput
            as={Input}
            first
            placeholder="Password"
            name="password"
            type="password"
            valid={!formik.errors.password || !formik.touched.password}
            value={formik.values.password}
            onChange={formik.handleChange("password")}
            onBlur={formik.handleBlur("password")}
          />

          <StyledInput
            as={Input}
            placeholder="Confirm Password"
            name="passwordConfirm"
            type="password"
            valid={
              !formik.errors.passwordConfirm || !formik.touched.passwordConfirm
            }
            value={formik.values.passwordConfirm}
            onChange={formik.handleChange("passwordConfirm")}
            onBlur={formik.handleBlur("passwordConfirm")}
          />
        </StyledForm>

        <Button
          title="Save"
          size="large"
          loading={loading}
          full
          onClick={() => formik.handleSubmit()}
        />
      </StyledContainer>
    </ModalContainer>
  );
};

export default ModalResetPasswordView;
