export const containerStyle = {
  top: 0,
  left: 0,

  transform: "rotate(0deg)",
  borderRadius: 0,

  zIndex: 1
};

export const elements = {
  text: {
    style: {
      width: 115,
      height: 85,
      fontSize: 70,
      lineHeight: 1.2,
      fontWeight: "400",
      fontStyle: "normal",
      fontFamily: "Arial",
      color: "#000",
      textAlign: "left",
      textDecoration: "overline",
      borderColor: "rgba(0,0,0,1)"
    }
  },

  block: {
    style: {
      width: 200,
      height: 200,

      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderColor: "rgba(0,0,0,1)",
      // borderWidth: 0,

      backgroundColor: "#E5E5E5"
    }
  },

  circle: {
    style: {
      width: 200,
      height: 200,

      borderRadius: "50%",
      borderColor: "rgba(0,0,0,1)",

      backgroundColor: "#E5E5E5"
    }
  },

  line: {
    style: {
      width: 200,
      height: 8,

      borderRadius: 0,

      backgroundColor: "#E5E5E5"
    }
  },

  image: {
    style: {
      width: 200,
      height: 200,

      // backgroundColor: "#fff",

      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderColor: "rgba(0,0,0,1)"
    }
  },

  upload: {
    style: {
      width: 600,
      height: 600,

      borderRadius: 0,

      backgroundColor: "#E5E5E5"
    },

    uploader: {
      top: "auto",
      left: "auto",
      height: 200,
      width: 200
    }
  }
};

export const strValuesElements = [
  "fontWeight",
  "color",
  "backgroundColor",
  "borderColor",
  "shadowColor",
  "textShadowColor",
  "transform",
  "fontFamily",
  "fontStyle",
  "textAlign",
  "url",
  "src"
];

export const renderContainer = {
  width: {
    text: "W"
  },
  height: {
    text: "H"
  }

  // left: {
  //   text: "X",
  // },
  // top: {
  //   text: "Y",
  // },
  // width: {
  //   text: "W",
  // },
  // height: {
  //   text: "H",
  // },
  // transform: {
  //   icon: "transform",
  // },
  // borderRadius: {
  //   icon: "borderRadius",
  // },
  // zIndex: {
  //   text: "zI",
  // },
};

// const config = {
//   top: 0,

//   width: 100,
//   height: 100,
//   fontSize: 16,
//   fontWeigth: "400",
// };
