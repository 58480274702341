import { responsiveSize } from "../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  /* width: 50%;

  padding: ${responsiveSize(40)}; */

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledTitle = styled.h1`
  margin-bottom: ${responsiveSize(20)};
  font-weight: 700;
  font-size: ${responsiveSize(24)};
  line-height: ${responsiveSize(28)};
  font-family: "Roboto";
`;

export const StyledForm = styled.div`
  width: 100%;
`;

export const StyledRow = styled.div`
  margin-bottom: ${responsiveSize(20)};
  width: 100%;

  display: flex;
`;

export const StyledInput = styled.div`
  height: ${responsiveSize(40)};

  margin-right: ${(props) => (props.offset ? responsiveSize(20) : 0)};
  padding: 0 ${responsiveSize(10)};
`;

export const StyledOr = styled.div`
  margin: ${responsiveSize(10)} 0;

  text-align: center;

  font-size: ${responsiveSize(12)};
  line-height: ${responsiveSize(30)};
`;

export const StyledButtonSocial = styled.div.attrs((props) => ({
  styleContainer: { marginRight: props.offset ? responsiveSize(20) : 0 },

  styleIcon: {
    margin: 0,
  },
}))``;

// export const StyledButtonSocial = styled.div`
//   margin-right: ${(props) => (props.offset ? responsiveSize(20) : 0)};
// `;
