import styled from "styled-components";

export const StyledContainer = styled.div`
  height: 69px;

  display: flex;

  justify-content: space-between;

  background: #fff;
  border-bottom: 1px solid #ececec;

  cursor: default;
  user-select: none;
`;

export const StyledGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledButton = styled.div`
  margin-right: 12px;
`;
