import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import GalleryVew from "./gallery-view";

const GalleryContainer = (props) => {
  return (
    <GalleryVew
      {...props}
    />
  );
};

export default GalleryContainer;
