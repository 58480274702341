import request from "../../utils/request";

export const loadEditorData = id => request.get(`get/project/${id}`);

export const uploadTemplate = ({ form, id }) =>
  request.post(id != "new" ? `update/project/${id}` : `create/project`, form, {
    headers: {
      // Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data"
    }
  });
