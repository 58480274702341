import { responsiveSize } from "../../../../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  margin-top: 1.5rem;
  /* padding: 1.5rem 1.13rem; */
  /* margin-top: ${responsiveSize(8)}; */

  /* padding: 0 10px; */
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  border: 1px solid #fff;
`;

export const StyledRow = styled.div`
  margin: 4px 0;

  width: 100%;

  display: flex;
`;

export const StyledItem = styled.div`
  width: 100%;

  /* padding: 3px 5px; */
`;

export const StyledLabel = styled.p`
  margin: 1rem;
  margin-bottom: 0.69rem;

  font-size: ${responsiveSize(12)};
  text-transform: uppercase;
  /* text-align: flex-end; */

  color: #3e4141;

  opacity: 0.5;
`;
