export const LOADING_STORE_DATA = "auth/LOADING_STORE_DATA";
export const LOADING_STORE_DATA_SUCCESS = "auth/LOADING_STORE_DATA_SUCCESS";
export const LOADING_STORE_DATA_ERROR = "auth/LOADING_STORE_DATA_ERROR";

export const LOADING_GALLERY_DATA = "auth/LOADING_GALLERY_DATA";
export const LOADING_GALLERY_DATA_SUCCESS = "auth/LOADING_GALLERY_DATA_SUCCESS";
export const LOADING_GALLERY_DATA_ERROR = "auth/LOADING_GALLERY_DATA_ERROR";

export const LOADING_PROJECT_DATA = "auth/LOADING_PROJECT_DATA";
export const LOADING_PROJECT_DATA_SUCCESS = "auth/LOADING_PROJECT_DATA_SUCCESS";
export const LOADING_PROJECT_DATA_ERROR = "auth/LOADING_PROJECT_DATA_ERROR";

export const CREATE_PROJECT = "auth/CREATE_PROJECT";
export const CREATE_PROJECT_SUCCESS = "auth/CREATE_PROJECT_SUCCESS";
export const CREATE_PROJECT_ERROR = "auth/CREATE_PROJECT_ERROR";
