import styled from "styled-components";

import { responsiveSize } from "../../../../../../utils/font";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${responsiveSize(70)};
`;

export const StyledItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: ${props => props.background};

  transition: all 0.4s;

  &:hover {
    /* transform: scale(0.95); */
    opacity: 0.8;
  }
`;

export const StyledText = styled.span`
  margin-top: ${responsiveSize(8)};

  font-size: ${responsiveSize(10)};
  line-height: ${responsiveSize(12)};
  font-family: "Roboto";

  color: ${props => props.color};
`;
