import * as Actions from "./constants";

import { sizes } from "../../config/sizes";

export const initState = {
  loading: false,
  success: null,

  defaultScale: 1,
  scale: 0,

  canvas: {
    width: sizes.horizontal.width,
    height: sizes.horizontal.height,
    type: "horizontal",
    // backgroundColor: "rgba(200, 67, 67, 1)",
    backgroundColor: "#fff",
    url: null
  },

  slides: [
    {
      id: 1,
      data: [
        {
          id: 1,
          items: {},
          canvas: {}
        }
      ]
    }
  ],

  items: {},

  meta: null,

  groupedSlides: false,

  showEffect: false,

  selectedItemId: null,
  selectedSlideId: "1/1",

  historyStack: {},
  historyStep: 0,
  activeHistoryStep: 0,

  upload: {
    loading: false,
    success: null
  }
};

/**
 * Common reducer
 * @param state
 * @param action
 * @returns {*}
 */
function commonReducer(state = initState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case Actions.LOAD_EDITOR_DATA:
      return { ...state, loading: true, success: null };
    case Actions.LOAD_EDITOR_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        slides: payload.template ? payload.template.slides : state.slides,
        canvas: {
          ...(payload.template ? payload.template.canvas : state.canvas)
        },
        selectedItemId: null,
        meta: {
          name: payload.name,
          description: payload.description,
          category: payload.category,
          preview: payload.preview,
          tags: payload.tags
        }
      };
    case Actions.LOAD_EDITOR_DATA_ERROR:
      return { ...state, loading: false, success: false };

    case Actions.CLEAN_EDITOR_DATA:
      return {
        ...state,
        loading: initState.loading,
        success: initState.success,
        items: initState.items,
        slides: [
          {
            id: 1,
            data: [
              {
                id: 1,
                items: {},
                canvas: {}
              }
            ]
          }
        ],
        canvas: initState.canvas,
        selectedItemId: initState.selectedItemId,
        meta: initState.meta
      };

    case Actions.INIT_SCALE:
      return { ...state, scale: payload, defaultScale: payload };
    case Actions.SAVE_SCALE:
      return { ...state, scale: payload };

    case Actions.SAVE_ITEMS:
      return { ...state, items: payload };

    case Actions.SAVE_SLIDES:
      console.log("SAVE_SLIDES", payload);

      let historyStackKeys = Object.keys(state.historyStack);
      let historyStackLength = historyStackKeys.length;
      let resHistoryStack = {};

      for (let key of historyStackKeys) {
        if (key <= state.activeHistoryStep)
          resHistoryStack[key] = state.historyStack[key];
      }

      if (historyStackLength === 0) {
        resHistoryStack[0] = {
          slides: state.slides
        };
      }

      historyStackKeys = Object.keys(resHistoryStack);
      historyStackLength = historyStackKeys.length;

      resHistoryStack[
        historyStackLength > 0
          ? +historyStackKeys[historyStackLength - 1] + 1
          : 1
      ] = {
        slides: payload
      };

      console.log("resHistoryStack", resHistoryStack);

      return {
        ...state,
        slides: payload,
        historyStack: resHistoryStack,
        activeHistoryStep: state.activeHistoryStep + 1
      };

    case Actions.SAVE_GROPED_SLIDES:
      return {
        ...state,
        groupedSlides: payload
      };

    case Actions.SAVE_CANVAS:
      return {
        ...state,
        canvas: {
          ...state.canvas,
          ...payload
        }
        // historyStack: {
        //   ...state.historyStack,
        //   [Object.keys(state.historyStack)[Object.keys(state.historyStack).length] + 1]: {
        //     // slides: payload,
        //     canvas: state.canvas
        //   }
        // },
        // activeHistoryStep: state.activeHistoryStep + 1
      };

    case Actions.SAVE_CANVAS_TYPE:
      return {
        ...state,
        canvas: {
          ...state.canvas,
          type: payload
        }
      };

    case Actions.SAVE_HISTORY_STACK:
      console.log("SAVE_HISTORY_STACK", payload);

      return {
        ...state
        // historyStack: payload
      };

    case Actions.SAVE_ACTIVE_HISTORY_STEP:
      console.log("SAVE_ACTIVE_HISTORY_STEP", payload);

      console.log(
        "state.historyStack[payload].slides",
        state.historyStack[payload].slides
      );

      return {
        ...state,
        activeHistoryStep: payload,
        slides: state.historyStack[payload].slides
      };

    case Actions.REMOVE_ITEM:
      let deletedRes = { ...state.items };

      if (deletedRes[payload].type !== "image") delete deletedRes[payload];
      else deletedRes[payload] = null;

      return {
        ...state,
        items: {
          ...deletedRes
        }
      };

    case Actions.SAVE_SELECTED_ITEM:
      return { ...state, selectedItemId: payload };

    case Actions.SAVE_SELECTED_SLIDE_ID:
      return { ...state, selectedSlideId: payload };

    // case Actions.SAVE_SLIDES:
    //   return { ...state, slides: payload };

    case Actions.SAVE_SHOW_EFFECT:
      return { ...state, showEffect: payload };

    case Actions.UPLOAD_TEMPLATE:
      return {
        ...state,
        upload: {
          ...state.upload,
          loading: true,
          success: null
        }
      };
    case Actions.UPLOAD_TEMPLATE_SUCCESS:
      return {
        ...state,
        slides: payload.slides,
        canvas: payload.canvas,
        upload: {
          ...state.upload,
          loading: false,
          success: true
        }
      };
    case Actions.UPLOAD_TEMPLATE_ERROR:
      return {
        ...state,
        upload: {
          ...state.upload,
          loading: false,
          success: false
        }
      };

    default:
      return state;
  }
}

export default commonReducer;
