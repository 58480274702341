import React, { useEffect, useMemo, useState } from "react";

import CanvasHeaderModalPublishingView from "./canvas-header-modal-publishing-view";
import { useParams } from "react-router-dom";

const CanvasHeaderModalPublishingContainer = (props) => {
  const [preview, setPreview] = useState({});

  let { id } = useParams();

  useEffect(() => {
    if (props.upload) props.onClose()
  }, [props.upload])

  useEffect(() => {
    if (props.meta && props.meta.preview) setPreview(props.meta.preview)
  }, [props.meta])

  useEffect(() => {
    if (props.showModal && props.meta && props.meta.preview) setPreview(props.meta.preview)
  }, [props.showModal])

  const handlerSubmit = (data) => {
    console.log("handlerSubmit", { ...data, preview, id });
    
    props.uploadTemplate({ ...data, preview, id });
  };

  return (
    <CanvasHeaderModalPublishingView {...props} preview={preview} onSubmit={handlerSubmit} onChangePreview={setPreview} />
  );
};

export default CanvasHeaderModalPublishingContainer;
