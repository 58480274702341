import React, { useEffect, useState } from "react";
import { containerStyle, elements } from "../../../../config/elements";

import EditorSidebarView from "./editor-sidebar-view";
import _ from "lodash";
import { useHistory } from "react-router-dom";

const EditorSidebarContainer = ({
  defaultScale,
  items,
  saveItems,
  saveSlides,
  selectedItem,
  selectedSlideId,
  selectedSliderIndexes,
  slides,
  logout
}) => {
  let history = useHistory();

  const [activeItem, setActiveItem] = useState("move");
  const [fileLoadRef, setFileLoadRef] = useState(null);

  const handleAddItem = ({ type, value }) => {
    let res = JSON.parse(JSON.stringify(slides));
    let [rowId, columnId] = selectedSlideId.split("/");

    let length = Object.keys(
      res[selectedSliderIndexes.row].data[selectedSliderIndexes.column].items
    ).length;

    let id =
      length != 0
        ? +Object.keys(
            res[selectedSliderIndexes.row].data[selectedSliderIndexes.column]
              .items
          )[length - 1].split("/")[0] + 1
        : 1;
    let nextId = `${id}/${rowId}/${columnId}`;

    res[selectedSliderIndexes.row].data[selectedSliderIndexes.column].items[
      nextId
    ] = {
      id: nextId,
      type,
      containerStyle: containerStyle,
      style: elements[type].style,
      value: "hello2"
    };

    if (elements[type].uploader)
      res[selectedSliderIndexes.row].data[selectedSliderIndexes.column].items[
        nextId
      ].uploader = elements[type].uploader;

    if (type == "image") {
      res[selectedSliderIndexes.row].data[selectedSliderIndexes.column].items[
        nextId
      ].src = value.url;
      res[selectedSliderIndexes.row].data[selectedSliderIndexes.column].items[
        nextId
      ].file = value.file;
    } else {
      res[selectedSliderIndexes.row].data[selectedSliderIndexes.column].items[
        nextId
      ].value = "text";
    }

    saveSlides(res);
  };

  const handlerSelectItem = ({ type, value, subType }) => {
    if (activeItem !== type || subType) {
      if (type !== "image") {
        setActiveItem(type);
      }

      if (type === "image" || type === "text" || type === "block") {
        handleAddItem({ type: type === "block" ? subType : type, value });
      }
    } else {
      setActiveItem("move");
    }
  };

  const handleLogout = () => {
    logout();

    history.push(`/store`);
  };

  return (
    <EditorSidebarView
      selectedItem={selectedItem}
      activeItem={activeItem}
      onChangeFileLoadRef={setFileLoadRef}
      onAddItem={handleAddItem}
      onSelectItem={handlerSelectItem}
      onLogout={handleLogout}
    />
  );
};

export default EditorSidebarContainer;
