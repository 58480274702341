import {
  loadingSelector,
  resetPasswordSelector,
} from "../../modules/auth/selectors";

import ModalResetPasswordContainer from "./modal-reset-password-container";
import { connect } from "react-redux";
import { resetPassword } from "../../modules/auth/actions";
import { saveAuthShowModal } from "../../modules/auth/actions";

const mapStateToProps = (state) => ({
  loading: loadingSelector(state),
  resetPasswordStatus: resetPasswordSelector(state),
});

const mapDispatchToProps = {
  resetPassword,
  saveAuthShowModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalResetPasswordContainer);
