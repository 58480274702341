import { responsiveSize } from "../../../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  min-width: 50%;

  padding: ${responsiveSize(40)};

  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
