import {
  defaultScaleSelector,
  itemsSelector,
  metaSelector,
  scaleSelector,
  uploadSelector
} from "../../../../modules/editor/selectors";
import {
  saveItems,
  saveScale,
  uploadTemplate
} from "../../../../modules/editor/actions";

import EditorHeaderContainer from "./editor-header-container";
import { connect } from "react-redux";

const mapStateToProps = state => ({
  scale: scaleSelector(state),
  defaultScale: defaultScaleSelector(state),
  items: itemsSelector(state),
  upload: uploadSelector(state),
});

const mapDispatchToProps = {
  saveItems,
  saveScale,
  uploadTemplate
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorHeaderContainer);
