import React, { useEffect, useState } from "react";
import { StyledCancel, StyledContainer } from "./sidebar-submenu-styled";
import { animated, useSpring } from "react-spring";

import EditorSidebarItem from "../item";
import PropTypes from "prop-types";

const EditorSidebarView = ({ items, className, show, onCancel, onSelect }) => {
  const [animateValue, setAnimateValue] = useState(true);

  useEffect(() => {
    if (show) setAnimateValue(true);
    else {
      setTimeout(() => {
        setAnimateValue(false);
      }, 400);
    }
  }, [show]);

  const { transform, opacity } = useSpring({
    opacity: show ? 1 : 0,
    transform: `perspective(1000px) translateX(${show ? 0 : -256}px)`,
    duration: 400
  });

  return (
    <StyledContainer
      className={className}
      as={animated.div}
      style={{
        opacity
        // transform: transform.interpolate((t) => `${t}`),
      }}
      show={animateValue}
    >
      {items.map((item, index) => (
        <EditorSidebarItem
          {...item}
          key={index}
          // active={
          //   selectedItem ? selectedItem.type === item.type : index === 0
          // }
          onSelect={onSelect}
          // onClick={() => onselect(item.type)}
          // onClick={() => {
          //   onAddItem({ type: item.type });
          // }}
        />
      ))}

      <StyledCancel onClick={onCancel}>Cancel</StyledCancel>
    </StyledContainer>
  );
};

EditorSidebarView.propTypes = {
  items: PropTypes.array
};

EditorSidebarView.defaultProps = {
  items: []
};

export default EditorSidebarView;
