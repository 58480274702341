import React, { useEffect, useState } from "react";

// import * as THREE from "three";

import { DragControls } from "three/examples/jsm/controls/DragControls";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { SVGLoader } from "three/examples/jsm/loaders/SVGLoader.js";
import { TessellateModifier } from "three/examples/jsm/modifiers/TessellateModifier";
import { TransformControls } from "three/examples/jsm/controls/TransformControls.js";
import { TTFLoader } from "three/examples/jsm/loaders/TTFLoader.js";
import { MeshText2D, textAlign } from "three-text2d";
import helvetikerFont from "./helvetiker_bold.typeface.json";

import WebGLFont from "./components/web-gl-font";
import { shaders } from "./components/web-gl-font/shaders";
// import Stats from "three/examples/jsm/libs/stats.module";

import Figure from "../test/Figure";

const loadFont = require("load-bmfont");
const createGeometry = require("three-bmfont-text");
const MSDFShader = require("three-bmfont-text/shaders/msdf");

global.THREE = require("three");
const THREE = global.THREE;

// const OrbitControls = require("three-orbit-controls")(THREE);

// Font assets
const fontFile = require("./fonts/Lato-Black.fnt");
const fontAtlas = require("./fonts/Lato-Black.png");

const CanvasContentView = ({ items }) => {
  const [container, setContainer] = useState(null);
  const [camera, setCamera] = useState(null);
  const [cameraEffect, setCameraEffect] = useState(null);
  const [scene, setScene] = useState(null);
  const [sceneEffect, setSceneEffect] = useState(null);
  const [objects, setObjects] = useState([]);
  const [renderer, setRenderer] = useState(null);
  const [fonts, setFonts] = useState({});
  const [starGeo, setStarGeo] = useState(null);
  const [stars, setStars] = useState(null);
  const [orbitControls, setOrbitControls] = useState(null);

  useEffect(() => {
    if (renderer) {
      render();

      animate();
    }
  }, [renderer]);

  useEffect(() => {
    const _items = Object.values(items);

    if (_items.length && scene) {
      _items.forEach(item => {
        // const type = new WebGLFont({
        //   word: "FALLING",
        //   position: [-90, -10, 0],
        //   rotation: [Math.PI, 0, 0],
        //   zoom: 150
        // });
        // renderText(item);
        // const figure = new Figure(scene, () => {
        //   // this.update();
        // });

        if (item.type === "block") renderBlock(item);
        else if (item.type === "circle")
          renderCircle({ ...item, segments: 32 });
        else if (item.type === "polygon") renderRriangle(item);
        else if (item.type === "image") renderPhoto(item);
        else if (item.type === "video") renderVideo(item);
        else if (item.type === "svg") renderSvg(item);
        else if (item.type === "text") renderText(item);
      });
    }
  }, [items, scene]);

  useEffect(() => {
    if (objects.length) {
      const dragControls = new DragControls(
        [...objects],
        camera,
        renderer.domElement
      );
      dragControls.addEventListener("drag", render);

      dragControls.addEventListener("hoveron", function() {
        orbitControls.enabled = false;
      });

      dragControls.addEventListener("hoveroff", function() {
        orbitControls.enabled = true;
      });
      // dragControls.addEventListener("mousedown", function() {
      //   // orbitControls.enabled = true;
      // });
      dragControls.addEventListener("dragstart", function(event) {
        // orbitControls.enabled = false;
        event.object.material.opacity = 0.33;
      });
      dragControls.addEventListener("dragend", function(event) {
        // orbitControls.enabled = true;
        event.object.material.opacity = 1;
      });
    }
  }, [objects]);

  useEffect(() => {
    let _container,
      _camera,
      _cameraEffect,
      _scene,
      _sceneEffect,
      _renderer,
      _orbitControls;

    let controls, group;
    let enableSelection = false;

    const objects = [];

    const mouse = new THREE.Vector2(),
      raycaster = new THREE.Raycaster();

    init();

    // animate();

    function init() {
      _container = document.getElementById("_container");

      // const stats = new Stats();
      // _container.appendChild(stats.dom);

      const width = Math.floor(_container.getBoundingClientRect().width);
      const height = Math.floor(_container.getBoundingClientRect().height);

      // document.body.appendChild(_container);

      // _cameraEffect = new THREE.PerspectiveCamera(70, width / height, 1, 1000);
      // _cameraEffect = new THREE.PerspectiveCamera(
      //   60,
      //   window.innerWidth / window.innerHeight,
      //   1,
      //   1000
      // );
      // _cameraEffect.position.z = 1;
      // _cameraEffect.rotation.x = Math.PI / 2;

      _camera = new THREE.PerspectiveCamera(60, width / height, 1, 1000);
      _camera.position.z = 535;
      _camera.rotation.x = Math.PI / 2;

      //

      _renderer = new THREE.WebGLRenderer({ antialias: true });
      _renderer.setPixelRatio(window.devicePixelRatio);
      _renderer.setSize(width, height);
      // _renderer.setScissor(width / 2, height / 2, 200, 200);
      // _renderer.setScissorTest(true);
      _container.appendChild(_renderer.domElement);

      // _camera = new THREE.OrthographicCamera(
      //   width / -2,
      //   width / 2,
      //   height / 2,
      //   height / -2,
      //   1,
      //   1000
      // );
      // _camera.position.z = 535;

      _orbitControls = new OrbitControls(_camera, _renderer.domElement);
      // orbitControl.listenToKeyEvents(window); // optional

      //controls.addEventListener( 'change', render ); // call this only in static scenes (i.e., if there is no animation loop)

      _orbitControls.enableDamping = false; // an animation loop is required when either damping or auto-rotation are enabled
      _orbitControls.dampingFactor = 0.05;
      _orbitControls.enableRotate = false;
      // _orbitControls.enabled = false;
      // _orbitControls.maxDistance = 100;
      _orbitControls.screenSpacePanning = true;
      _orbitControls.enablePan = true;

      _orbitControls.minDistance = 50;
      _orbitControls.maxDistance = 1000;

      _orbitControls.maxPolarAngle = Math.PI / 2;
      _orbitControls.keys = {
        LEFT: 37, //left arrow
        UP: 38, // up arrow
        RIGHT: 39, // right arrow
        BOTTOM: 40 // down arrow
      };
      _orbitControls.mouseButtons = {
        LEFT: THREE.MOUSE.PAN,
        MIDDLE: THREE.MOUSE.DOLLY
        // RIGHT: THREE.MOUSE.PAN
      };
      // _orbitControls.enablePan = false;

      // const orbitControl = new OrbitControls(_camera, _renderer.domElement);
      // // controls.enablePan = true;
      // orbitControl.enableRotate = false;
      // // controls.enablePan = false;
      // orbitControl.minZoom = 1;
      // orbitControl.maxZoom = 1;

      _scene = new THREE.Scene();

      // _sceneEffect = new THREE.Scene();

      _scene.background = new THREE.Color(0xffffff);

      const group = new THREE.Group();
      _scene.add(group);

      //

      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.6);
      directionalLight.position.set(0.75, 0.75, 1.0).normalize();
      _scene.add(directionalLight);

      const ambientLight = new THREE.AmbientLight(0x000000, 0.2);
      _scene.add(ambientLight);

      const gridSize = Math.max(width, height);

      // const _cameraHelper = new THREE.CameraHelper(_camera);
      // _scene.add(_cameraHelper);

      const helper = new THREE.GridHelper(
        gridSize + 200,
        Math.floor((gridSize - 100) / 25),
        "#e2e2e2",
        "#eee"
      );
      helper.rotation.x = Math.PI / 2;
      helper.position.z = -200;
      group.add(helper);

      const _starGeo = new THREE.Geometry();
      for (let i = 0; i < 5000; i++) {
        const star = new THREE.Vector3(
          Math.random() * width - width / 2,
          Math.random() * width - width / 2,
          Math.random() * width - width / 2
        );
        star.velocity = 0;
        star.acceleration = 0.01;
        _starGeo.vertices.push(star);
      }

      let sprite = new THREE.TextureLoader().load("/star.png");
      let starMaterial = new THREE.PointsMaterial({
        color: 0xaaaaaa,
        size: 2,
        map: sprite
      });

      const _stars = new THREE.Points(_starGeo, starMaterial);
      _scene.add(_stars);

      // renderBlock();

      // renderVideo();

      // renderPhoto();

      // renderSvg();

      _container.addEventListener("click", onClick, false);
      window.addEventListener("keydown", onKeyDown, false);
      window.addEventListener("keyup", onKeyUp, false);

      setRenderer(_renderer);
      setContainer(_container);
      setScene(_scene);
      setCamera(_camera);
      setStarGeo(_starGeo);
      setStars(_stars);
      setOrbitControls(_orbitControls);
    }

    function onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      renderer.setSize(window.innerWidth, window.innerHeight);

      render();
    }

    function onKeyDown(event) {
      enableSelection = event.keyCode === 16 ? true : false;
    }

    function onKeyUp() {
      enableSelection = false;
    }

    function onClick(event) {
      event.preventDefault();

      if (enableSelection === true) {
        const draggableObjects = controls.getObjects();
        draggableObjects.length = 0;

        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

        raycaster.setFromCamera(mouse, _camera);

        const intersections = raycaster.intersectObjects(objects, true);

        if (intersections.length > 0) {
          const object = intersections[0].object;

          if (group.children.includes(object) === true) {
            object.material.emissive.set(0x000000);
            _scene.attach(object);
          } else {
            object.material.emissive.set(0xaaaaaa);
            group.attach(object);
          }

          controls.transformGroup = true;
          draggableObjects.push(group);
        }

        if (group.children.length === 0) {
          controls.transformGroup = false;
          draggableObjects.push(...objects);
        }
      }
    }
  }, []);

  function animate() {
    // console.log("HELLO");
    starGeo.vertices.forEach(p => {
      p.velocity += p.acceleration;
      p.z += p.velocity;

      if (p.z > 500) {
        p.z = -300;
        p.velocity = 0;
      }
    });
    starGeo.verticesNeedUpdate = true;
    stars.rotation.z += 0.003;
    // stars.rotation.y += 0.001;
    // stars.rotation.x += 0.001;

    requestAnimationFrame(animate);

    render();
  }

  function render() {
    renderer.render(scene, camera);
    // renderer.render(sceneEffect, cameraEffect);
  }

  function renderRriangle() {
    var geom = new THREE.Geometry();
    var v1 = new THREE.Vector3(0, 0, 0);
    var v2 = new THREE.Vector3(100, 0, 0);
    var v3 = new THREE.Vector3(100, 100, 0);
    var triangle = new THREE.Triangle(v1, v2, v3);
    var normal = triangle.normal();
    geom.vertices.push(triangle.a);
    geom.vertices.push(triangle.b);
    geom.vertices.push(triangle.c);
    geom.faces.push(new THREE.Face3(0, 1, 2, normal));
    var mesh = new THREE.Mesh(geom, new THREE.MeshNormalMaterial());
    // mesh.rotation.x = 20;
    scene.add(mesh);
    setObjects([...objects, mesh]);
  }

  function renderBlock() {
    const control = new TransformControls(camera, renderer.domElement);
    control.setMode("rotate");

    control.addEventListener("dragging-changed", function(event) {
      orbitControls.enabled = !event.value;
    });

    const geometry = new THREE.BoxBufferGeometry(100, 100, 0);

    const object = new THREE.Mesh(
      geometry,
      new THREE.MeshBasicMaterial({ color: "blue" })
      // new THREE.MeshLambertMaterial({ color: Math.random() * 0xffffff })
    );

    scene.add(object);

    control.attach(object);
    scene.add(control);

    setObjects([...objects, object]);
  }

  function renderCircle({ segments = 1 }) {
    const geometry = new THREE.CircleGeometry(50, segments);

    const object = new THREE.Mesh(
      geometry,
      new THREE.MeshBasicMaterial({ color: "blue" })
    );

    scene.add(object);

    setObjects([...objects, object]);
  }

  function renderText({ value }) {
    // loadFont("/fonts/Lato-Black.fnt", (err, font) => {
    //   const geometry = createGeometry({
    //     font,
    //     text: "hello"
    //   });

    //   const loader = new THREE.TextureLoader();
    //   loader.load("/fonts/Lato-Black.png", texture => {
    //     setTimeout(() => {
    //       _init(geometry, texture);
    //       // this.animate();
    //     }, 1500);
    //   });
    // });

    // function _init(geometry, texture) {
    //   createMesh(geometry, texture);
    // }

    // function createMesh(geometry, texture) {
    //   // Material
    //   const material = new THREE.RawShaderMaterial(
    //     MSDFShader({
    //       vertexShader: shaders.vertex,
    //       fragmentShader: shaders.fragment,
    //       color: "fontColor",
    //       map: texture,
    //       side: THREE.DoubleSide,
    //       transparent: true,
    //       negate: false
    //     })
    //   );

    //   // Create time variable from prestablished shader uniforms
    //   material.uniforms.time = { type: "f", value: 0.0 };

    //   // Mesh
    //   const mesh = new THREE.Mesh(geometry, material);
    //   mesh.position.set([-90, -10, 0]);
    //   mesh.rotation.set([Math.PI, 0, 0]);
    //   scene.add(mesh);
    // }

    // loader.load("/helvetiker_bold.typeface.json", function(font) {
    // const geometry = createGeometry({
    //   font,
    //   text: "hello"
    // });

    // });

    // // Material
    // const material = new THREE.RawShaderMaterial(
    //   MSDFShader({
    // vertexShader: shaders.vertex,
    // fragmentShader: shaders.fragment,
    //     color: "green",
    //     map: texture,
    //     side: THREE.DoubleSide,s
    //     transparent: true,
    //     negate: false
    //   })
    // );

    // Create time variable from prestablished shader uniforms
    // material.uniforms.time = { type: "f", value: 0.0 };

    // // Mesh
    // const mesh = new THREE.Mesh(geometry, this.material);
    // mesh.position.set([-90, -10, 0]);
    // mesh.rotation.set(Math.PI, 0, 0);
    // scene.add(mesh);

    const loader = new THREE.FontLoader();
    loader.load("/helvetiker_bold.typeface.json", function(font) {
      init(font);
      // animate();
    });

    function init(font) {
      let geometry = new THREE.TextGeometry("Test text", {
        font: font,

        size: 40,
        height: 5,
        curveSegments: 3,

        bevelThickness: 2,
        bevelSize: 1,
        bevelEnabled: true
      });

      geometry.center();

      const tessellateModifier = new TessellateModifier(8, 6);

      // geometry = tessellateModifier.modify(geometry);

      // const numFaces = geometry.attributes.position.count / 3;

      // const colors = new Float32Array(numFaces * 3 * 3);
      // const displacement = new Float32Array(numFaces * 3 * 3);

      // const color = new THREE.Color();

      // for (let f = 0; f < numFaces; f++) {
      //   const index = 9 * f;

      //   const h = 0.2 * Math.random();
      //   const s = 0.5 + 0.5 * Math.random();
      //   const l = 0.5 + 0.5 * Math.random();

      //   color.setHSL(h, s, l);

      //   const d = 10 * (0.5 - Math.random());

      //   for (let i = 0; i < 3; i++) {
      //     colors[index + 3 * i] = color.r;
      //     colors[index + 3 * i + 1] = color.g;
      //     colors[index + 3 * i + 2] = color.b;

      //     displacement[index + 3 * i] = d;
      //     displacement[index + 3 * i + 1] = d;
      //     displacement[index + 3 * i + 2] = d;
      //   }
      // }

      // geometry.setAttribute(
      //   "customColor",
      //   new THREE.BufferAttribute(colors, 3)
      // );
      // geometry.setAttribute(
      //   "displacement",
      //   new THREE.BufferAttribute(displacement, 3)
      // );

      // //

      const uniforms = {
        amplitude: { value: 0.0 }
      };

      const shaderMaterial = new THREE.ShaderMaterial({
        uniforms: uniforms,
        vertexShader: document.getElementById("vertexshader").textContent,
        fragmentShader: document.getElementById("fragmentshader").textContent
      });

      const mesh = new THREE.Mesh(geometry, shaderMaterial);

      scene.add(mesh);

      setObjects([...objects, mesh]);
    }
  }

  function renderSvg({ src }) {
    // instantiate a loader
    const loader = new SVGLoader();

    // load a SVG resource
    loader.load(
      // resource URL
      src,
      // called when the resource is loaded
      function(data) {
        const paths = data.paths;
        const group = new THREE.Group();

        for (let i = 0; i < paths.length; i++) {
          const path = paths[i];

          const material = new THREE.MeshBasicMaterial({
            color: path.color,
            side: THREE.DoubleSide,
            depthWrite: false
          });

          const shapes = path.toShapes(true);

          for (let j = 0; j < shapes.length; j++) {
            const shape = shapes[j];
            const geometry = new THREE.ShapeBufferGeometry(shape);
            const mesh = new THREE.Mesh(geometry, material);
            group.add(mesh);
          }
        }

        // objects.push(group);
        scene.add(group);
        // render();
      },
      // called when loading is in progresses
      function(xhr) {
        console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
      },
      // called when loading has errors
      function(error) {
        console.log("An error happened");
      }
    );
  }

  function renderVideo({ src }) {
    const control = new TransformControls(camera, renderer.domElement);
    control.setMode("rotate");

    control.addEventListener("dragging-changed", function(event) {
      orbitControls.enabled = !event.value;
    });

    const video = document.createElement("video");
    video.src = src;
    video.muted = true;
    video.playsinline = true;
    video.loop = true;
    video.autoplay = true;
    video.style = { display: "none" };

    video.load();
    video.play();

    video.onloadedmetadata = e => {
      // alert(JSON.stringify(video.videoWidth));
      const proportion = 300 / video.videoWidth;

      //Create your video texture:
      const videoTexture = new THREE.VideoTexture(video);

      // alert(videoTexture.width);
      const videoMaterial = new THREE.MeshBasicMaterial({
        map: videoTexture,
        side: THREE.FrontSide,
        toneMapped: false
      });
      videoMaterial.needsUpdate = true;

      //Create screen
      const screen = new THREE.PlaneGeometry(
        Math.floor(video.videoWidth * proportion),
        Math.floor(video.videoHeight * proportion),
        0
      );
      const videoScreen = new THREE.Mesh(screen, videoMaterial);
      scene.add(videoScreen);

      control.attach(videoScreen);
      scene.add(control);
      objects.push(videoScreen);
      // setObjects([...objects, videoScreen]);
    };
    // video.onload = () => {
    //   alert(video.width);
    // };
  }

  function renderPhoto({ src }) {
    // const image = document.createElement("img");
    // image.src = src;
    const control = new TransformControls(camera, renderer.domElement);
    control.setMode("rotate");

    control.addEventListener("dragging-changed", function(event) {
      orbitControls.enabled = !event.value;
    });

    var loader = new THREE.TextureLoader();

    loader.load(src, texture => {
      const proportion = 200 / texture.image.width;

      const material = new THREE.MeshBasicMaterial({
        map: texture,
        color: "transparent"
      });

      //Create screen
      const screen = new THREE.PlaneGeometry(
        Math.floor(texture.image.width * proportion),
        Math.floor(texture.image.height * proportion),
        0
      );
      const videoScreen = new THREE.Mesh(screen, material);
      scene.add(videoScreen);

      setObjects([...objects, videoScreen]);

      control.attach(videoScreen);
      scene.add(control);
    });
  }

  return (
    <div style={{ flex: 1 }}>
      {/* <p style={{ color: "green" }}>{JSON.stringify(items)}</p> */}
      <div
        id="_container"
        style={{ height: "100%", width: "100%", background: "green" }}
      ></div>
      <article class="tile">
        <figure class="tile__figure">
          <img
            src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80"
            data-hover="https://images.unsplash.com/photo-1522609925277-66fea332c575?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80"
            class="tile__image"
            alt="My image"
            width="300"
          />
        </figure>
      </article>
    </div>
  );
};

export default CanvasContentView;

// import React, { Component } from "react";

// global.THREE = require("three");
// const THREE = global.THREE;
// const OrbitControls = require("three-orbit-controls")(THREE);
// const loadFont = require("load-bmfont");
// const createGeometry = require("three-bmfont-text");
// const MSDFShader = require("three-bmfont-text/shaders/msdf");

// // Font assets
// const fontFile = require("./fonts/Lato-Black.fnt");
// const fontAtlas = require("./fonts/Lato-Black.png");

// // Nice colors
// const colors = require("nice-color-palettes");
// const palette = colors[4];
// const background = palette[4];

// export default class WebGLFont {
//   constructor(opts = {}) {
//     // Options obj
//     this.options = opts;

//     // Variables
//     this.vars = {
//       word: this.options.word,
//       position: [...this.options.position],
//       rotation: [...this.options.rotation],
//       zoom: this.options.zoom,
//       vertex: this.options.vertex,
//       fragment: this.options.fragment
//     };

//     // Scene
//     this.scene = new THREE.Scene();

//     // Camera
//     this.camera = new THREE.PerspectiveCamera(
//       75,
//       window.innerWidth,
//       window.innerHeight,
//       0.1,
//       1000
//     );
//     this.camera.position.z = this.vars.zoom;

//     // Renderer
//     this.renderer = new THREE.WebGLRenderer({
//       canvas: document.querySelector("#app"),
//       antialias: true
//     });
//     this.renderer.setClearColor(background);
//     this.renderer.setSize(window.innerWidth, window.innerHeight);
//     this.renderer.setPixelRatio(window.devicePixelRatio);

//     // Controls
//     this.controls = new OrbitControls(this.camera, this.renderer.domElement);

//     // Load font files to initialize renderer
//     this.loadBMF();
//   }

//   loadBMF() {
//     // Create geometry of packed glyphs
//     loadFont(fontFile, (err, font) => {
//       this.geometry = createGeometry({
//         font,
//         text: this.vars.word
//       });
//     });

//     // Load texture containing font glyphs
//     this.loader = new THREE.TextureLoader();
//     this.loader.load(fontAtlas, texture => {
//       setTimeout(() => {
//         this.init(this.geometry, texture);
//         this.animate();
//       }, 1500);
//     });
//   }

//   init(geometry, texture) {
//     this.createMesh(geometry, texture);
//     this.onResize();
//     window.addEventListener("resize", () => this.onResize(), false);
//     this.render();
//   }

//   createMesh(geometry, texture) {
//     // Material
//     this.material = new THREE.RawShaderMaterial(
//       MSDFShader({
//         map: texture,
//         color: 0x000000,
//         side: THREE.DoubleSide,
//         transparent: true,
//         negate: false
//       })
//     );

//     // Mesh
//     this.mesh = new THREE.Mesh(geometry, this.material);
//     this.mesh.position.set(...this.vars.position);
//     this.mesh.rotation.set(...this.vars.rotation);
//     this.scene.add(this.mesh);
//   }

//   onResize() {
//     let w = window.innerWidth;
//     let h = window.innerHeight;

//     w < 640
//       ? (this.camera.position.z = 250)
//       : (this.camera.position.z = this.vars.zoom);

//     this.camera.aspect = w / h;
//     this.camera.updateProjectionMatrix();

//     this.renderer.setSize(w, h);
//   }

//   animate() {
//     requestAnimationFrame(this.animate.bind(this));
//     this.render();
//   }

//   render() {
//     this.renderer.render(this.scene, this.camera);
//   }
// }

// // const type = new WebGLFont({
// //   word: "OCEAN",
// //   position: [-80, -10, 0],
// //   rotation: [Math.PI, 0, 0],
// //   zoom: 100
// // });
