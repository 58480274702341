import { Link, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import React, { useEffect } from "react";
import { animated, config, useSpring, useTransition } from "react-spring";

import Canvas from "../../domains/canvas";
import Editor from "../../domains/editor";
import Gallery from "../../domains/gallery";
import NoMatch from "../../domains/no-match";
import Slider from "react-slick";
import Store from "../../domains/store";
import StoreModalForgot from "../../modals/forgot";
import StoreModalLogin from "../../modals/login";
import StoreModalRegister from "../../modals/register";
import StoreModalResetPassword from "../../modals/reset-password";
import { Transition } from "react-spring/renderprops";
import { useLocation } from "react-router-dom";

const RootView = ({ showModal, token, onChangeShowModal }) => {
  let location = useLocation();

  console.log("!!!!!showModal!!!!!!", showModal);

  return (
    <>
      <Switch>
        <Route exact path="/:id">
          <Canvas />
        </Route>

        <Route exact path="*">
          <NoMatch />
        </Route>
      </Switch>

      <StoreModalRegister
        // showModal={true}
        showModal={showModal == "register"}
        onClose={onChangeShowModal}
      />

      <StoreModalLogin
        showModal={showModal == "login"}
        onClose={onChangeShowModal}
      />

      <StoreModalForgot
        // showModal={true}
        showModal={showModal == "forgot"}
        onClose={onChangeShowModal}
      />

      <StoreModalResetPassword
        // showModal={true}
        showModal={showModal == "resetPassword"}
        onClose={onChangeShowModal}
      />
    </>
  );
};

export default RootView;
