import React from "react";

import { Link } from "react-router-dom";

import EditorSidebarItem from "./components/item";

import {
  StyledContainer,
  StyledContent,
  StyledLogo,
  StyledSubitems
} from "./sidebar-styled";

import { useHistory } from "react-router-dom";

const EditorSidebarView = ({ items, subitems, active, selectedItem }) => {
  let history = useHistory();

  return (
    <StyledContainer image={require("./images/bg.jpg")}>
      <StyledContent>
        <StyledLogo as={Link} to={"/"} />

        {items.map((item, index) => (
          <EditorSidebarItem
            {...item}
            key={index}
            active={selectedItem ? selectedItem === item.type : index === 0}
            onClick={() => {
              history.push(item.url);
            }}
          />
        ))}

        {subitems && (
          <StyledSubitems>
            {subitems.map((item, index) => (
              <EditorSidebarItem {...item} key={index} active={false} />
            ))}
          </StyledSubitems>
        )}
      </StyledContent>
    </StyledContainer>
  );
};

export default EditorSidebarView;
