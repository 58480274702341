import { responsiveSize } from "../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  height: ${(props) => responsiveSize(props.options[props.size].height)};
  width: ${(props) => (props.full ? "100%" : "auto")};

  padding: 7px 12px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${(props) =>
    props.round ? "transparent" : props.backgroundColor};
  border: 1px solid ${(props) => props.backgroundColor};
  border-radius: ${(props) =>
    responsiveSize(props.options[props.size].borderRadius)};

  cursor: pointer;

  transition: all 0.5s;

  &:hover {
    /* transition: all 0.3s; */

    opacity: 0.6;
    /* transform: scale(1.05); */
  }
`;

export const StyledContent = styled.div`
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: ${(props) => (props.loading ? 0 : 1)};
`;

export const StyledLoader = styled.div`
  /* width: 100%;
  height: 100%; */

  flex-direction: row;

  justify-content: center;
  align-items: center;

  position: absolute;
  z-index: 1;
`;

export const StyledIcon = styled.img`
  height: ${responsiveSize(16)};

  margin-right: 10px;
`;

export const StyledText = styled.p`
  font-weight: 500;
  font-size: ${(props) => responsiveSize(props.options[props.size].fontSize)};
  line-height: 1;

  text-align: center;
  text-transform: uppercase;

  color: ${(props) => props.titleColor};
`;

export const StyledTextLoader = styled.p`
  margin-right: 5px;
  font-size: 12px;
  color: ${(props) => (props.round ? props.backgroundColor : props.titleColor)};
`;

// import styled from "styled-components";

// import { responsiveSize } from "../../utils/font";

// export const StyledContainer = styled.div`
//   height: ${props => responsiveSize(props.options[props.size].height)};

//   padding: ${responsiveSize(7)} ${responsiveSize(12)};

//   display: flex;
//   justify-content: center;
//   align-items: center;

//   background-color: ${props =>
//     props.round ? "transparent" : props.backgroundColor};
//   border: 1px solid ${props => props.backgroundColor};
//   border-radius: ${props => props.options[props.size].borderRadius}px;

//   cursor: pointer;

//   transition: all 0.3s;

//   &:hover {
//     transition: all 0.3s;

//     opacity: 0.8;
//     transform: scale(1.06);
//   }
// `;

// export const StyledContent = styled.div`
//   height: 100%;

//   display: flex;
//   justify-content: center;
//   align-items: center;

//   opacity: ${props => (props.loading ? 0 : 1)};
// `;

// export const StyledLoader = styled.div`
//   width: 100%;
//   height: 100%;

//   flex-direction: row;

//   justify-content: center;
//   align-items: center;

//   position: absolute;
//   z-index: 1;
// `;

// export const StyledIcon = styled.img`
//   height: 16px;

//   margin-right: ${responsiveSize(10)};
// `;

// export const StyledText = styled.p`
//   font-weight: 500;
//   font-size: ${responsiveSize(12)};
//   line-height: ${responsiveSize(14)};

//   text-align: center;
//   text-transform: uppercase;

//   color: ${props => props.titleColor};
// `;

// export const StyledTextLoader = styled.p`
//   margin-right: ${responsiveSize(5)};
//   font-size: ${responsiveSize(12)};
//   color: ${props => (props.round ? props.backgroundColor : props.titleColor)};
// `;
