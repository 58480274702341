import ContentControlGroup from "../group";
import ContentControlInput from "../input";
import React from "react";
import { StyledLock } from "./content-control-size-styled";

const ContentControlSizeView = ({ style, lock, onChangeLock, onChange }) => {
  return (
    <ContentControlGroup>
      <ContentControlInput
        icon={require("./images/w.svg")}
        size="big"
        value={style.width}
        valueType="number"
        onChange={value =>
          onChange({
            value,
            key: "width"
          })
        }
      />

      <StyledLock
        lock={lock}
        src={
          lock
            ? require("./images/lock-active.svg")
            : require("./images/lock.svg")
        }
        style={{ tintColor: "green" }}
        onClick={() => onChangeLock(!lock)}
      />

      <ContentControlInput
        icon={require("./images/h.svg")}
        size="big"
        value={style.height}
        valueType="number"
        onChange={value =>
          onChange({
            value,
            key: "height"
          })
        }
      />
    </ContentControlGroup>
  );
};

export default ContentControlSizeView;
