import { responsiveSize } from "../../../../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  position: relative;
  display: flex;
  height: 100%;
`;

export const StyledBody = styled.div`
  width: ${responsiveSize(120)};

  position: absolute;
  top: 0px;
  left: 0px;

  height: 100%;

  z-index: 10;
`;

export const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  /* background: url(${props => props.image}) no-repeat 100% 100%; */
  /* background-size: cover; */
`;

export const StyledContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #fff;
  /* rgba(241, 241, 241, 0.9); */
  /* backdrop-filter: blur(${responsiveSize(30)}); */
`;

export const StyledItem = styled.div`
  width: 100%;
  position: relative;
`;

export const StyledSubmenu = styled.div`
  position: absolute;
  left: 0px;
  top: 100%;

  background: rgba(241, 241, 241, 0.8);

  backdrop-filter: blur(30px);

  /* z-index: 99999; */
`;

export const StyledLogo = styled.div`
  width: ${responsiveSize(56)};
  height: ${responsiveSize(38)};
  margin: ${responsiveSize(23)} ${responsiveSize(32)} ${responsiveSize(29)};
  
  /* width: ${responsiveSize(56)};
  height: ${responsiveSize(38)};
  
  margin: ${responsiveSize(15.5)} 0px; */
  /* margin: ${responsiveSize(20.5)} 0px; */

  background: #f71b43;
  border-radius: ${responsiveSize(43)};
`;

export const StyledFooterIcon = styled.img`
  transition: all 0.6s;
`;

export const StyledFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${responsiveSize(16)} 0px;
  margin-top: auto;

  border-top: 1px solid #d0d1d2;

  transition: all 0.4s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);

    ${StyledFooterIcon} {
      transform: scale(0.9);
    }
  }
`;

export const StyledItems = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${responsiveSize()};
  /* margin-left: ${responsiveSize(120)}; */
`;

// export const StyledSlot = styled.div`
//   position: absolute;
//   top: ${responsiveSize(69)};
//   bottom: 0px;
//   left: 100%;

//   width: ${responsiveSize(256)};

//   background: rgba(241, 241, 241, 0.9);
//   /* Background Blur */

//   backdrop-filter: blur(30px);

//   z-index: 1;
// `;
