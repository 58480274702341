import {
  StyledContainer,
  StyledIcon,
  StyledText,
} from "./editor-sidebar-item-styled";

import React from "react";

const EditorSidebarItemView = ({ icon, label, active, onClick }) => {
  return (
    <StyledContainer active={active} onClick={onClick}>
      <StyledIcon src={icon} alt="icon" />

      <StyledText>{label}</StyledText>
    </StyledContainer>
  );
};

export default EditorSidebarItemView;
