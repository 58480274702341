import { responsiveSize } from "../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  /* width: 50%;

  padding: ${responsiveSize(40)}; */
  padding: 0 ${responsiveSize(16)} 0 ${responsiveSize(8)};

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledTitle = styled.h1`
  margin-bottom: ${responsiveSize(15)};

  font-weight: 700;
  font-size: ${responsiveSize(24)};
  line-height: ${responsiveSize(28)};
  font-family: "Roboto";
`;

export const StyledDescription = styled.p`
  font-size: ${responsiveSize(16)};
  line-height: ${responsiveSize(19)};
  font-family: "Roboto";
`;

export const StyledForm = styled.div`
  width: 100%;

  margin: ${responsiveSize(24)} 0;
`;

export const StyledInput = styled.div`
  height: ${responsiveSize(40)};

  margin-bottom: ${(props) =>
    props.first ? responsiveSize(16) : responsiveSize(0)};

  padding: 0 ${responsiveSize(10)};
`;
