import styled from "styled-components";

import { responsiveSize } from "../../utils/font";

export const StyledContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${responsiveSize(194)};
  height: 100%;

  background: url(${props => props.image}) no-repeat;

  background-size: ${responsiveSize(120)} 100%;
`;

export const StyledContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  /* align-items: center; */

  background-color: rgba(241, 241, 241, 0.9);
  backdrop-filter: blur(${responsiveSize(30)});
`;

export const StyledLogo = styled.div`
  width: ${responsiveSize(56)};
  height: ${responsiveSize(38)};
  margin: ${responsiveSize(23)} ${responsiveSize(32)} ${responsiveSize(29)};
  /* padding: 0px ${responsiveSize(32)}; */

  background: #f71b43;
  border-radius: ${responsiveSize(43)};
`;

export const StyledSubitems = styled.div`
  margin-top: auto;
  margin-bottom: ${responsiveSize(40)};
`;
