export const items = [
  {
    id: 1,
    name: "Template_3_Edit",
    preview: require("./images/1.jpg"),
    user: { name: "test", surname: "test" },
    created: "24.06.2020"
  },

  {
    id: 2,
    name: "Template_2_Edit",
    preview: require("./images/7.jpg"),
    user: { name: "test", surname: "test" },
    created: "24.06.2020"
  },

  {
    id: 3,
    name: "Template_1_Edit",
    preview: require("./images/8.jpg"),
    user: { name: "test", surname: "test" },
    created: "24.06.2020"
  },

  {
    id: 4,
    name: "Template_4_Edit",
    preview: require("./images/9.jpg"),
    user: { name: "test", surname: "test" },
    created: "24.06.2020"
  }
];
