import React, { useLayoutEffect } from "react";
import {
  StyledContainer,
  StyledHeader,
  StyledSort,
} from "./store-projects-styles";

import StoreProjectsGrid from "./components/grid";
import StoreProjectsHeader from "./components/header";
import StoreProjectsSort from "./components/sort";

const StoreProjectsView = ({
  data,
  searchStr,
  onChangeSearchStr,
  onChangeActive,
}) => {
  console.log("data", data);
  return (
    <StyledContainer>
      <StyledHeader
        as={StoreProjectsHeader}
        searchStr={searchStr}
        onChangeSearchStr={onChangeSearchStr}
      />

      <StyledSort as={StoreProjectsSort} />

      <StoreProjectsGrid data={data} onChangeActive={onChangeActive} />
    </StyledContainer>
  );
};

export default StoreProjectsView;
