import React, { useEffect, useMemo, useState } from "react";

import ModalForgotView from "./modal-forgot-view";

const ModalForgotContainer = (props) => {
  useEffect(() => {
    console.log("forgotStatusSelector", props.forgotStatus);
    if (props.forgotStatus) props.saveAuthShowModal(false);
  }, [props.forgotStatus]);

  const handlerSubmit = (data) => {
    props.forgot({
      email: data.email,
    });
  };

  return <ModalForgotView {...props} onSubmit={handlerSubmit} />;
};

export default ModalForgotContainer;
