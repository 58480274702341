import { responsiveSize } from "../../../../../../../../utils/font";
import styled from "styled-components";

export const StyledIcon = styled.img`
  width: ${responsiveSize(24)};
  height: ${responsiveSize(24)};

  transition: all 0.6s;

  opacity: ${props => (props.type != "animation" ? 1 : 0.5)};
`;

export const StyledContainer = styled.div`
  position: relative;

  width: ${responsiveSize(85)};
  height: 100%;

  cursor: pointer;
`;

export const StyledContent = styled.div`
  width: 100%;
  height: 100%;
  /* padding: ${responsiveSize(16)} 0px; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-bottom: 2px solid
    ${props => (props.active ? "#f71b43" : "transparent")};
  /* border-left: 2px solid transparent; */

  background-color: ${props => (props.active ? "#fff" : "transparent")};

  opacity: ${props => (props.active ? 1 : 0.5)};
  transition: all 0.6s;

  &:hover {
    /* opacity: 0.85; */

    ${StyledIcon} {
      transform: rotate(360deg);
    }
  }
`;

export const StyledSubmenu = styled.div`
  position: absolute;
  left: 0px;
  top: 100%;
  padding: 8px 0px;

  background: rgba(241, 241, 241, 0.8);

  backdrop-filter: blur(30px);

  z-index: 99;
`;

export const StyledText = styled.div`
  margin-top: ${responsiveSize(8)};

  font-size: ${responsiveSize(10)};
  line-height: ${responsiveSize(12)};
  text-align: center;
  text-transform: uppercase;
  color: #3e4141;

  opacity: ${props => (props.type != "animation" ? 1 : 0.5)};
`;

export const StyledArrow = styled.img`
  position: absolute;
  bottom: 0px;
  right: 0px;
`;
