export const LOAD_EDITOR_DATA = "editor/LOAD_EDITOR_DATA";
export const LOAD_EDITOR_DATA_SUCCESS = "editor/LOAD_EDITOR_DATA_SUCCESS";
export const LOAD_EDITOR_DATA_ERROR = "editor/LOAD_EDITOR_DATA_ERROR";

export const CLEAN_EDITOR_DATA = "editor/CLEAN_EDITOR_DATA_SUCCESS";

export const SAVE_SCALE = "editor/SAVE_SCALE";
export const INIT_SCALE = "editor/INIT_SCALE";
export const SAVE_ITEMS = "editor/CHANGE_ITEMS";
export const SAVE_CANVAS = "editor/SAVE_CANVAS";
export const SAVE_CANVAS_TYPE = "editor/SAVE_CANVAS_TYPE";

export const SAVE_SLIDES = "editor/SAVE_SLIDES";
export const ADD_SLIDE = "editor/ADD_SLIDE";
export const REMOVE_SLIDE = "editor/REMOVE_SLIDE";
export const CLEAN_SLIDE = "editor/CLEAN_SLIDE";
export const SAVE_GROPED_SLIDES = "editor/SAVE_GROPED_SLIDES";

export const SAVE_SHOW_EFFECT = "editor/SAVE_SHOW_EFFECT";

export const SAVE_HISTORY_STACK = "editor/SAVE_HISTORY_STACK";
export const SAVE_ACTIVE_HISTORY_STEP = "editor/SAVE_ACTIVE_HISTORY_STEP";

export const SAVE_SELECTED_ITEM = "editor/CHANGE_SELECTED_ITEM";
export const SAVE_SELECTED_SLIDE_ID = "editor/SAVE_SELECTED_SLIDE_ID";
export const REMOVE_ITEM = "editor/REMOVE_ITEM";

export const UPLOAD_TEMPLATE = "editor/UPLOAD_TEMPLATE";
export const UPLOAD_TEMPLATE_SUCCESS = "editor/UPLOAD_TEMPLATE_SUCCESS";
export const UPLOAD_TEMPLATE_ERROR = "editor/UPLOAD_TEMPLATE_ERROR";
