import * as yup from "yup";

import { Button, Input } from "../../components";
import {
  StyledButtonSocial,
  StyledContainer,
  StyledDescription,
  StyledForm,
  StyledInput,
  StyledOr,
  StyledRow,
  StyledTitle,
} from "./modal-forgot-styles";

import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import ModalContainer from "../root/container";
import ModalInfo from "../root/info";
import React from "react";
import { useFormik } from "formik";

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
});

const ModalForgotView = ({
  className,
  showModal,
  loading,
  onSubmit,
  onClose,
}) => {
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit,
  });

  return (
    <ModalContainer
      showModal={showModal}
      leftComponent={<ModalInfo />}
      onClose={onClose}
    >
      <StyledContainer className={className}>
        <StyledTitle>Reset Password</StyledTitle>

        <StyledDescription>
          Don't worry! You may have forgotten your password, but we can help you
          out. Enter your email you a link to reset your password.
        </StyledDescription>

        <StyledInput
          as={Input}
          placeholder="Email Address"
          name="email"
          type="email"
          valid={!formik.errors.email || !formik.touched.email}
          value={formik.values.email}
          onChange={formik.handleChange("email")}
          onBlur={formik.handleBlur("email")}
        />

        <Button
          title="Send reset link"
          size="large"
          loading={loading}
          full
          onClick={() => formik.handleSubmit()}
        />
      </StyledContainer>
    </ModalContainer>
  );
};

export default ModalForgotView;
