import { responsiveSize } from "../../../../../../../utils/font";
import styled from "styled-components";

const BORDER_RADIUS = 4;

export const StyledContainer = styled.div`
  height: ${responsiveSize(476)};
  width: ${responsiveSize(785)};

  border-radius: ${responsiveSize(BORDER_RADIUS)};
  box-shadow: 0px ${responsiveSize(10)} ${responsiveSize(20)}
    rgba(0, 0, 0, 0.05);

  background: url(${(props) => props.image}) no-repeat center;
  background-size: cover;

  cursor: pointer;
`;
