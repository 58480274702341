import { Button, Input, Modal } from "semantic-ui-react";
import React, { createRef, useState } from "react";
import { StyledContainer, StyledFileLoad } from "./file-loader-styled";

const FileLoaderView = ({
  className,
  close,
  full = true,
  accept = "image/*",
  onClick,
  children
}) => {
  const [fileLoadRef, setFileLoadRef] = useState(null);

  const handleLoadFile = () => {
    fileLoadRef.click();
  };

  const handlerAddImage = event => {
    let file = fileLoadRef.files[0];

    if (file)
      onClick({
        file,
        url: URL.createObjectURL(fileLoadRef.files[0])
      });
  };

  return (
    <StyledContainer className={className} full={full} onClick={handleLoadFile}>
      {children}
      {close}
      <StyledFileLoad
        id="file"
        type="file"
        accept={accept}
        ref={ref => setFileLoadRef(ref)}
        onChange={handlerAddImage}
      />
    </StyledContainer>
  );
};

export default FileLoaderView;
