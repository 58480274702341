import EditorSettingsTypeContainer from "./editor-settings-type-container";

import { connect } from "react-redux";

import { canvasSelector } from "../../../../../../../../modules/editor/selectors";

import {
  saveCanvasType,
  saveCanvas
} from "../../../../../../../../modules/editor/actions";

const mapStateToProps = state => ({
  canvas: canvasSelector(state)
});

const mapDispatchToProps = {
  saveCanvasType,
  saveCanvas
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorSettingsTypeContainer);
