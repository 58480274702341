import React, { useEffect, useMemo, useState } from "react";

import EditorControlView from "./editor-control-view";
import { strValuesElements } from "../../../../config/elements";

const EditorContentContainer = ({
  items,
  selectedItemId,
  selectedSlideId,
  selectedSliderIndexes,
  slides,
  canvas,
  historyStack,
  saveItems,
  saveCanvas,
  saveSlides,
  saveHistoryStack
}) => {
  const selectedItem = useMemo(() => {
    return slides[selectedSliderIndexes.row].data[selectedSliderIndexes.column]
      .items[selectedItemId];
  }, [slides, selectedSliderIndexes, selectedItemId]);

  const handleChange = (data, isObject = false) => {
    let elemetns = data.length ? data : [data];

    for (let item of elemetns) {
      let { key, value } = item;

      let trasformValue =
        strValuesElements.indexOf(key) != -1 || value == "auto" || key == "file"
          ? value
          : +value;

      canvas = {
        ...canvas,
        [key]: trasformValue
      };
    }

    saveCanvas({
      ...canvas
    });
  };

  const handleChangeSlide = ({ key, data, value }) => {
    if (selectedSlideId && selectedItemId) {
      let res = JSON.parse(JSON.stringify(slides));

      const resValue = data
        ? {
            ...slides[selectedSliderIndexes.row].data[
              selectedSliderIndexes.column
            ].items[selectedItemId][key],
            ...data
          }
        : value;

      res[selectedSliderIndexes.row].data[selectedSliderIndexes.column].items[
        selectedItemId
      ][key] = resValue;

      saveSlides([...res]);
    }


    // console.log("handleChangeSlide", { key, data, value });
    // if (selectedSlideId) {
    //   let res = JSON.parse(JSON.stringify(slides));

    //   // res[selectedSliderIndexes.row].data[selectedSliderIndexes.column].canvas[
    //   //   key
    //   // ] = value;

    //   const resValue = data
    //     ? {
    //         ...data,
    //         ...slides[selectedSliderIndexes.row].data[
    //           selectedSliderIndexes.column
    //         ].canvas[key]
    //       }
    //     : value;

    //   res[selectedSliderIndexes.row].data[selectedSliderIndexes.column].canvas[
    //     key
    //   ] = resValue;

    //   saveSlides(res);
    // }
  };

  const handleRemove = ({ type, keys }) => {
    let res = { ...items };
    let id = selectedSlideId.split("/");

    for (let key of keys) {
      delete res[id[0]][id[1]].items[selectedItemId][type][key];
    }

    saveSlides({ ...res });
  };

  return (
    <EditorControlView
      items={items}
      canvas={canvas}
      slides={slides}
      selectedItemId={selectedItemId}
      selectedSlideId={selectedSlideId}
      selectedSliderIndexes={selectedSliderIndexes}
      selectedItem={selectedItem}
      historyStack={historyStack}
      onChange={handleChange}
      onChangeSlide={handleChangeSlide}
      onRemove={handleRemove}
    />
  );
};

export default EditorContentContainer;
