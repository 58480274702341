import * as Actions from "./constants";

export function loadEditorData(payload) {
  return {
    type: Actions.LOAD_EDITOR_DATA,
    payload
  };
}

export function cleanEditorData() {
  return {
    type: Actions.CLEAN_EDITOR_DATA
  };
}

export function initScale(payload) {
  return {
    type: Actions.INIT_SCALE,
    payload
  };
}

export function saveScale(payload) {
  return {
    type: Actions.SAVE_SCALE,
    payload
  };
}

export function saveItems(payload) {
  return {
    type: Actions.SAVE_ITEMS,
    payload
  };
}

export function saveSlides(payload) {
  return {
    type: Actions.SAVE_SLIDES,
    payload
  };
}

export function saveCanvas(payload) {
  return {
    type: Actions.SAVE_CANVAS,
    payload
  };
}

export function saveCanvasType(payload) {
  return {
    type: Actions.SAVE_CANVAS_TYPE,
    payload
  };
}

export function saveHistoryStack(payload) {
  return {
    type: Actions.SAVE_HISTORY_STACK,
    payload
  };
}

export function saveActiveHistoryStep(payload) {
  return {
    type: Actions.SAVE_ACTIVE_HISTORY_STEP,
    payload
  };
}

export function saveselectedItemId(payload) {
  return {
    type: Actions.SAVE_SELECTED_ITEM,
    payload
  };
}

export function saveSelectedSlideId(payload) {
  return {
    type: Actions.SAVE_SELECTED_SLIDE_ID,
    payload
  };
}

export function removeItem(payload) {
  return {
    type: Actions.REMOVE_ITEM,
    payload
  };
}

export function uploadTemplate(payload) {
  return {
    type: Actions.UPLOAD_TEMPLATE,
    payload
  };
}

export function addSlide(payload) {
  return {
    type: Actions.ADD_SLIDE,
    payload
  };
}

export function removeSlide(payload) {
  return {
    type: Actions.REMOVE_SLIDE,
    payload
  };
}

export function cleanSide(payload) {
  return {
    type: Actions.CLEAN_SLIDE,
    payload
  };
}

export function saveShowEffect(payload) {
  return {
    type: Actions.SAVE_SHOW_EFFECT,
    payload
  };
}

export function saveGroupedSlides(payload) {
  return {
    type: Actions.SAVE_GROPED_SLIDES,
    payload
  };
}
