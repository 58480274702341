import React from "react";

import ContentControlImageView from "./content-control-image-view";

const ContentControlImageContainer = props => {
  const handlerChange = value => {
    props.onChangeItems({
      key: "src",
      value
    });
  };

  return <ContentControlImageView {...props} onChange={handlerChange} />;
};

export default ContentControlImageContainer;
