import { ColorPicker, Icon } from "../../../../../../../../components";
import React, { useState } from "react";
import { StyledColor, StyledColorPicker, StyledIcon } from "./content-control-font-style-styled";

import ContentControlFontStyleColor from "./components/color";
import ContentControlGroup from "../group";

const ContentControlFontStyleView = ({style, onChangeItems}) => {
  const [activeItem, setActiveItem] = useState("bold");

  const handleChange = ({value, key}) => {
    onChangeItems({
      key: "style",
      data: {
        [key]: value
      }
    });
  }

  return (
    <ContentControlGroup>

      {/* <StyledColor as={ContentControlFontStyleColor} /> */}

      <StyledColorPicker>
        <ColorPicker value={style.color} onChange={(value)  => handleChange({key: "color", value})} />
      </StyledColorPicker>

      <StyledIcon
        as={Icon}
        src={require("./images/bold.svg")}
        size={20}
        active={style.fontWeight === "700"}
        onClick={() => handleChange({key: "fontWeight", value: style.fontWeight === "400" ? "700" : "400"})}
      />

      <StyledIcon
        as={Icon}
        src={require("./images/italic.svg")}
        size={20}
        active={style.fontStyle === "italic"}
        onClick={() => handleChange({key: "fontStyle", value: style.fontStyle === "italic" ? "normal" : "italic"})}
      />

      <StyledIcon
        as={Icon}
        src={require("./images/underline.svg")}
        size={20}
        active={style.textDecoration === "underline"}
        onClick={() => handleChange({key: "textDecoration", value: style.textDecoration === "overline" ? "underline" : "overline"})}
      />

      {/* <StyledItem
        active={activeItem === "left"}
        onClick={() => setActiveItem("left")}
      >
        <Icon src={require("./images/left.svg")} />
      </StyledItem>

      <StyledItem
        active={activeItem === "center"}
        onClick={() => setActiveItem("center")}
      >
        <Icon src={require("./images/center.svg")} />
      </StyledItem>

      <StyledItem
        active={activeItem === "right"}
        onClick={() => setActiveItem("right")}
      >
        <Icon src={require("./images/right.svg")} />
      </StyledItem> */}
    </ContentControlGroup>
  );
};

export default ContentControlFontStyleView;
