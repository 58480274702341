import { responsiveSize } from "../../../../../../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledText = styled.p`
  font-size: 16px;
  text-align: center;

  transition: all 0.4s;
`;

export const StyledItem = styled.div`
  min-width: ${responsiveSize(122)};
  padding: ${responsiveSize(12)};

  border-bottom: 2px solid
    ${(props) => (props.active ? "#f71b43" : "transparent")};
  opacity: ${(props) => (props.active ? 1 : 0.5)};

  cursor: pointer;

  transition: all 0.4s;

  &:hover {
    opacity: 0.9;

    ${StyledText} {
      transform: translateY(${responsiveSize(-5)});
    }
  }
`;
