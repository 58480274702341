import { responsiveSize } from "../../../../../../../../../utils/font";
import styled from "styled-components";

export const StyledModal = styled.div`
  width: 35%;

  /* height: 65%; */

  padding: ${responsiveSize(32)} ${responsiveSize(40)} ${responsiveSize(49)}
    ${responsiveSize(40)};
`;

export const StyledTitle = styled.h4`
  margin-bottom: ${responsiveSize(24)};

  font-size: ${responsiveSize(20)};
  line-height: ${responsiveSize(23)};
  font-weight: normal;
  color: #3e4141;
`;

export const StyledItem = styled.div`
  margin-top: ${responsiveSize(24)};

  /* width: 100%; */
`;

export const StyledLabel = styled.h6`
  margin-bottom: ${responsiveSize(8)};

  font-size: ${responsiveSize(12)};
  line-height: ${responsiveSize(14)};
  font-weight: normal;

  text-transform: uppercase;
  opacity: 0.5;
`;

export const StyledInput = styled.div.attrs((props) => ({
  styleInput: {
    fontSize: responsiveSize(16),
    color: "#3E4141",
  },

  styleLabel: {
    fontSize: responsiveSize(16),
    color: "rgba(62, 65, 65, 0.5)",
  },
}))`
  height: ${responsiveSize(35)};
`;

export const StyledSelect = styled.div`
  height: ${responsiveSize(35)};
  width: 100%;

  padding: ${responsiveSize(8)};
  padding-right: ${responsiveSize(12)};

  border: 1px solid #d8d9d9;
`;

export const StyledPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledPriceLabel = styled.p`
  margin-left: ${responsiveSize(24)};

  font-size: ${responsiveSize(12)};
  line-height: ${responsiveSize(14)};

  flex-shrink: 0;
`;

export const StyledFooter = styled.div`
  margin-top: ${responsiveSize(24)};
  padding-top: ${responsiveSize(15)};

  display: flex;
  justify-content: space-between;

  border-top: 1px solid #ececec;
`;

export const StyledFooterPrice = styled.div``;

export const StyledFooterPriceValue = styled.p`
  font-size: ${responsiveSize(24)};
  line-height: ${responsiveSize(28)};
  font-weight: 500;
`;

export const StyledFooterButton = styled.div.attrs((props) => ({
  styleContainer: {
    height: responsiveSize(51),
    paddingHorizontal: responsiveSize(24),
    marginLeft: "auto"
    // paddingVertical: responsiveSize(16),
  },

  styleText: {
    fontSize: responsiveSize(16),
    fontWeight: "400",
  },
}))``;
