import { responsiveSize } from "../../../../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  padding: 0 5px;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  border: 1px solid #fff;
`;

export const StyledRow = styled.div`
  width: 100%;

  display: flex;

  &:not(:last-child) {
    margin-bottom: ${responsiveSize(12)};
  }
`;

export const StyledItem = styled.div`
  width: 100%;
`;
