import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import ModalResetPasswordView from "./modal-reset-password-view";
import queryString from "query-string";

const ModalResetPasswordContainer = (props) => {
  const location = useLocation();
  const query = queryString.parse(location.search);

  useEffect(() => {
    if (props.resetPasswordStatus) props.saveAuthShowModal(false);
  }, [props.resetPasswordStatus]);

  const handlerSubmit = (data) => {
    props.resetPassword({
      password: data.password,
      code: query.code,
    });
  };

  return <ModalResetPasswordView {...props} onSubmit={handlerSubmit} />;
};

export default ModalResetPasswordContainer;
