import {
  StyledButton,
  StyledContainer,
  StyledGroup
} from "./editor-header-styled";

import EditorHeaderScaling from "./componets/scaling";
import EditorHeaderExport from "./componets/export";
import EditorHeaderArtboard from "./componets/artboard";
import EditorHeaderItems from "./componets/items";
import React from "react";
import TopLine from "../../../../components/top-line";

const EditorHeaderView = () => {
  return (
    <TopLine
      activeItem="editor"
      componentLeft={
        <>
          <EditorHeaderItems />
          <EditorHeaderArtboard />
          <EditorHeaderScaling />
        </>
      }
      componentRight={<EditorHeaderExport />}
    />

    //   <StyledGroup>
    //     <StyledButton>
    //       <Button
    //         size="mini"
    //         loading={upload.loading}
    //         primary
    //         onClick={onUpload}
    //       >
    //         Save
    //       </Button>
    //     </StyledButton>

    //     <EditorHeaderScale
    //       defaultScale={defaultScale}
    //       scale={scale}
    //       onChange={onChangeScale}
    //     />
    //   </StyledGroup>
    // </StyledContainer>
  );
};

export default EditorHeaderView;
