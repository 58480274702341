import StoreContainer from "./store-conatiner";
import { connect } from "react-redux";
import { storeSelector } from "../../modules/project/selectors";

// import { loadEditorData } from "../../modules/project/actions";

const mapStateToProps = (state) => ({
  data: storeSelector(state),
});

const mapDispatchToProps = {
  // loadEditorData,
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreContainer);
