import React, { useEffect, useState } from "react";

import CanvasControlBorderRadiusView from "./canvas-control-border-radius-view";

const ContentControlShadowContainer = props => {
  const [show, setShow] = useState(
    !!props.style.borderTopLeftRadius ||
      !!props.style.borderTopRightRadius ||
      !!props.style.borderBottomLeftRadius ||
      !!props.style.borderBottomRightRadius
  );

  useEffect(() => {
    setShow(
      !!props.style.borderTopLeftRadius ||
        !!props.style.borderTopRightRadius ||
        !!props.style.borderBottomLeftRadius ||
        !!props.style.borderBottomRightRadius
    );
  }, [
    props.style.borderTopLeftRadius,
    props.style.borderTopRightRadius,
    props.style.borderBottomLeftRadius,
    props.style.borderBottomRightRadius
  ]);

  const handleChange = ({ value, key }) => {
    props.onChangeItems({
      key: "style",
      data: {
        [key]: value
      }
    });
  };

  const handleAdd = () => {
    props.onChangeItems({
      key: "style",
      data: {
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5
      }
    });

    setShow(true);
  };

  const handleRemove = () => {
    props.onChangeItems({
      key: "style",
      data: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
      }
    });

    setShow(false);
  };

  return (
    <CanvasControlBorderRadiusView
      {...props}
      show={show}
      onChange={handleChange}
      onAdd={handleAdd}
      onRemove={handleRemove}
    />
  );
};

export default ContentControlShadowContainer;
