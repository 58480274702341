import { responsiveSize } from "../../../../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  /* display: flex;
  flex-direction: column; */

  padding: ${responsiveSize(24)};

  border-bottom: 1px solid #ececec;
`;

export const StyledSettings = styled.div`
  width: 100%;
  /* margin-bottom: 54px; */

  /* position: relative; */
`;

export const StyledLabel = styled.p`
  margin-bottom: ${responsiveSize(8)};

  font-size: ${responsiveSize(12)};
  text-transform: uppercase;
  /* text-align: flex-end; */

  color: #3e4141;

  opacity: 0.5;
`;

export const StyledSlides = styled.div`
  margin-top: ${responsiveSize(24)};
`;

export const StyledActions = styled.div`
  display: flex;
`;

export const StyledIcon = styled.img`
  &:not(:last-child) {
    margin-right: ${responsiveSize(8)};
  }
`;

export const StyledContent = styled.div`
  display: flex;
`;

export const StyledButton = styled.div.attrs(props => ({
  styleContainer: {
    width: "100%",
    // marginRight: props.add ? responsiveSize(16) : 0,
    backgroundColor: props.fill ? "#3E4141" : "#fff",
    borderColor: props.fill ? "#3E4141" : "#D8D9D9",
    userSelect: "none"
  },
  styleText: {
    fontSize: responsiveSize(12),

    color: props.fill ? "#fff" : "#3E4141"
  }
}))``;

export const StyledSize = styled.div`
  margin-top: 8px;
`;
