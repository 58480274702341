import CanvasControl, {
  Background,
  Border,
  BorderRadius,
  Rotate,
  Shadow,
  Size,
  ZIndex
} from "../elements";
import React, { useMemo } from "react";
import {
  StyledContainer,
  StyledLabel,
  StyledRow
} from "./editor-control-block-styled";

// import EditorControlBlockContainer from "../root/itemContainer";

const EditorControlBlockView = ({
  style,
  onChangeItems,
  onRemove,
  ...props
}) => {
  return (
    <StyledContainer>
      <StyledLabel>Style</StyledLabel>

      <StyledRow>
        <Size style={style} onChangeItems={onChangeItems} onRemove={onRemove} />
      </StyledRow>

      <StyledRow>
        <Rotate
          style={style}
          onChangeItems={onChangeItems}
          onRemove={onRemove}
        />
      </StyledRow>

      <StyledRow>
        <BorderRadius
          style={style}
          onChangeItems={onChangeItems}
          onRemove={onRemove}
        />
      </StyledRow>

      <StyledRow>
        <Background
          style={style}
          showInfo={true}
          onChangeItems={onChangeItems}
          onRemove={onRemove}
        />
      </StyledRow>

      <StyledRow>
        <Border
          style={style}
          onChangeItems={onChangeItems}
          onRemove={onRemove}
        />
      </StyledRow>

      <StyledRow>
        <Shadow
          style={style}
          onChangeItems={onChangeItems}
          onRemove={onRemove}
        />
      </StyledRow>

      {/* <ZIndex
        last
        style={props.containerStyle}
        // style={style}
        onChangeItems={onChangeItems}
        onRemove={onRemove}
      /> */}
    </StyledContainer>
  );
};

export default EditorControlBlockView;
