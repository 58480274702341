import React, { useMemo } from "react";
import {
  StyledContainer,
  StyledContent,
  StyledDownloads,
  StyledInfo,
  StyledMeta,
  StyledMetaItem,
  StyledMetaLabel,
  StyledName,
  StyledPreview,
  StyledPrice,
  StyledUsername,
} from "./grid-card-styled";

import MouseEffect from "../../../mouse-effect";
import { animated } from "react-spring";

const StoreProjectsGridCardView = ({ data, animateStyle }) => {
  return (
    <MouseEffect>
      <StyledContainer as={animated.div} style={animateStyle}>
        <StyledPreview
          // image={require("./images/1.jpg")}
          image={Object.values(data.preview)[0].src}
        />

        <StyledContent>
          <StyledInfo>
            <StyledName>{data.name}</StyledName>

            <StyledPrice free={data.price === 0}>
              {data.price === 0 ? "Free" : `$${data.price}`}
            </StyledPrice>
          </StyledInfo>

          <StyledMeta>
            <StyledMetaItem>
              <StyledMetaLabel>Created By</StyledMetaLabel>

              <StyledUsername>
                {data.user.name} {data.user.surname}
              </StyledUsername>
            </StyledMetaItem>

            <StyledMetaItem>
              <StyledMetaLabel>Downloads</StyledMetaLabel>

              <StyledDownloads>{data.countDownloads}</StyledDownloads>
            </StyledMetaItem>
          </StyledMeta>
        </StyledContent>
      </StyledContainer>
    </MouseEffect>
  );
};

export default StoreProjectsGridCardView;
