import styled from "styled-components";

import { responsiveSize } from "../../../../../../../../utils/font";

export const StyledLock = styled.img`
  margin: 0px ${responsiveSize(8)};

  transform: rotate(${props => (props.lock ? 90 : 0)}deg);

  transition: all 0.4s;
  cursor: pointer;
`;
