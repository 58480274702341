import { responsiveSize } from "../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  padding: 0 ${responsiveSize(6)};
  width: 100%;
  min-height: ${responsiveSize(30)};

  /* flex: 1; */
  display: flex;
  align-items: center;

  border-radius: ${responsiveSize(4)};

  /* border: 1px solid #d8d9d9; */

  border: solid 1px ${(props) => (props.valid ? "#d8d9d9" : "#EE0D29")};

  /* &:hover {
    border: 1px solid rgba(0, 0, 0, 0.1);
  } */

  transition: all 0.4s;

  text {
    font-size: ${responsiveSize(9)};
  }
`;

export const StyledLabel = styled.p`
  margin: 0;

  line-height: ${responsiveSize(20)};
  font-size: ${responsiveSize(12)};
  color: #b3b3b3;
`;

export const StyledIcon = styled.div`
  height: ${responsiveSize(13)};
  width: ${responsiveSize(13)};
`;

export const StyledValue = styled.input`
  padding: 0;
  margin-left: ${(props) => (props.label ? responsiveSize(6) : 0)};
  width: 100%;

  flex: 1;

  border: none;
  background-color: #fff;

  /* line-height: ${responsiveSize(12)}; */
  font-size: ${responsiveSize(12)};
  /* text-transform: uppercase; */

  &:hover,
  &:focus {
    outline: none;
    border: none;
  }
`;
