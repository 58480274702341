import { responsiveSize } from "../../../../../../../../utils/font";
import styled from "styled-components";

export const StyledLabel = styled.label`
  position: relative;
  display: flex;
  width: ${props => responsiveSize(props.width)};
  height: ${responsiveSize(30)};
`;

export const StyledInput = styled.input`
  padding: 0px ${props => responsiveSize(props.isIcon ? 25 : 8)} 0px
    ${responsiveSize(8)};

  /* flex: 1; */
  width: 100%;
  font-size: ${responsiveSize(12)};
  line-height: ${responsiveSize(14)};

  color: #313131;

  border: 1px solid #c5c6c6;
  border-radius: ${responsiveSize(4)};
  /* outline: none; */
  box-sizing: border-box;
`;

export const StyledIcon = styled.img`
  position: absolute;
  right: ${responsiveSize(7)};
  top: ${responsiveSize(7)};

  z-index: 1;
`;
