import { Button, Icon } from "../../../../../components";
import { StyledContainer, StyledTitle } from "./store-template-body-styles";

import React from "react";
import StoreTemplateBodyContent from "./components/content";
import StoreTemplateBodyInfo from "./components/info";

const StoreTemplateBodyView = ({ className, data }) => {
  return (
    <StyledContainer className={className}>
      <StoreTemplateBodyContent data={data} />

      <StoreTemplateBodyInfo data={data} />
    </StyledContainer>
  );
};

export default StoreTemplateBodyView;
