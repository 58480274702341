import { ColorPicker, Select } from "../../../../../../../../components";
import { StyledInput, StyledSelect } from "./content-control-border-styled";

import ContentControlGroup from "../group";
import ContentControlInput from "../input";
import ContentControlShadowColor from "./components/color";
import React from "react";

const options = [{ label: "Center", value: "center" }];

const ContentControlShadowView = ({
  style,
  show,
  localWidth,
  onChange,
  onAdd,
  onRemove,
  onChangeLocalWidth
}) => {
  return (
    <ContentControlGroup
      placholder="Stroke"
      showContent={show}
      onShow={onAdd}
      onHide={onRemove}
    >
      {/* <ContentControlShadowColor /> */}
      <ColorPicker
        type="square"
        value={style.borderColor}
        defaultValue="rgba(0,0,0,1)"
        onChange={value => onChange({ key: "borderColor", value })}
      />

      <StyledInput
        as={ContentControlInput}
        // value={style.borderWidth}
        value={localWidth}
        defaultValue={1}
        valueType="number"
        size="small"
        iconSize={16}
        icon={require("./images/zondicons.svg")}
        onChange={value => {
          onChangeLocalWidth(value);
          //  onChange({ key: "borderWidth", value: +value })
        }}
      />

      {/* <p>{JSON.stringify(style)}</p> */}

      {/* <StyledSelect as={Select} options={options} /> */}
    </ContentControlGroup>
  );
};

export default ContentControlShadowView;
