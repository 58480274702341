import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Link, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import React, { useLayoutEffect } from "react";
import { animated, config, useSpring, useTransition } from "react-spring";

import { Page } from "../../components";
import StoreProjects from "./components/projects";
import StoreSidebar from "./components/sidebar";
import StoreTemplate from "./template";
import StoreTopLine from "./components/top-line";
import { Transition } from "react-spring/renderprops";
import { useLocation } from "react-router-dom";

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const StoreProjectsView = ({
  data,
  children,
  active,
  showLoginModal,
  onRegister,
  onChangeShowLoginModal,
  onChangeActive,
}) => {
  let location = useLocation();

  // const transitions = useTransition(location, (location) => location.pathname, {
  //   from: { opacity: 0 },
  //   enter: { opacity: 1 },
  //   leave: { opacity: 0 },
  //   config: config.molasses,
  // });

  // alert(location.pathname);

  const { transform, transform2, opacity, opacity2 } = useSpring({
    opacity: location.pathname == "/store" ? 1 : 0,
    opacity2: location.pathname != "/store" ? 1 : 0,
    transform: `perspective(1000px)`,

    transform2: `perspective(1000px) translateX(${
      location.pathname != "/store" ? "0" : "1000px"
    })`,
    config: { mass: 5, tension: 300, friction: 60 },
    duration: 600,
  });

  return (
    <div>
      <Page
        SidebarComponent={<StoreSidebar />}
        ContentComponent={
          <>
            <Switch>
              <Route path="/store/:id">
                <animated.div
                  style={{
                    height: "100%",
                    transform: transform2.interpolate((t) => `${t}  `),
                  }}
                >
                  <StoreTemplate data={data} />
                </animated.div>
              </Route>

              <Route path="/store">
                <animated.div style={{ height: "100%", opacity }}>
                  <StoreProjects data={data} />
                </animated.div>
              </Route>
            </Switch>
          </>
        }
        TopLineComponent={
          <StoreTopLine
            onRegister={onRegister}
            onChangeShowLoginModal={onChangeShowLoginModal}
          />
        }
      />
      {/* 
      <StoreAuthModal
        showLoginModal={showLoginModal}
        onClose={onChangeShowLoginModal}
      /> */}
    </div>
  );
};

export default StoreProjectsView;
