import {
  StyledButton,
  StyledContainer,
  StyledContent,
  StyledDescription,
  StyledTitle,
} from "./modal-container-info-styled";

import { Button } from "../../../components";
import React from "react";

const StoreProjectsModalRegisterView = ({
  className,
  title,
  description,
  buttonText,
  empty = false,
  onClick,
}) => {
  return (
    <StyledContainer className={className} empty={empty}>
      <StyledContent>
        {!empty && (
          <>
            <StyledTitle>{title}</StyledTitle>

            <StyledDescription>{description}</StyledDescription>

            <StyledButton
              as={Button}
              title={buttonText}
              backgroundColor="#fff"
              size="large"
              full
              round
              onClick={onClick}
            />
          </>
        )}
      </StyledContent>
    </StyledContainer>
  );
};

export default StoreProjectsModalRegisterView;
