import * as Actions from "./constants";

import { call, put, select, takeEvery } from "redux-saga/effects";
import { login, register, resetPassword, sendResetEmail } from "./service";

import { tokenSelector } from "./selectors";

function* loginSaga({ payload }) {
  try {
    const res = yield call(login, payload);

    console.log(res);

    yield put({ type: Actions.LOGIN_SUCCESS, payload: res });
  } catch (e) {
    console.log(e);
    yield put({ type: Actions.LOGIN_ERROR });
  }
}

function* registerSaga({ payload }) {
  try {
    const res = yield call(register, payload);

    yield put({
      type: Actions.REGISTER_SUCCESS,
      payload: res,
    });
  } catch (e) {
    console.log(e);
    yield put({ type: Actions.REGISTER_ERROR });
  }
}

function* forgotSaga({ payload }) {
  try {
    const res = yield call(sendResetEmail, payload);

    yield put({
      type: Actions.FORGOT_SUCCESS,
      payload: res,
    });
  } catch (e) {
    console.log(e);
    yield put({ type: Actions.FORGOT_ERROR });
  }
}

function* resetPasswordSaga({ payload }) {
  try {
    const res = yield call(resetPassword, payload);

    yield put({
      type: Actions.RESET_PASSWORD_SUCCESS,
      payload: res,
    });
  } catch (e) {
    console.log(e);
    yield put({ type: Actions.RESET_PASSWORD_ERROR });
  }
}

export default function* iapSaga() {
  yield takeEvery(Actions.LOGIN, loginSaga);
  yield takeEvery(Actions.REGISTER, registerSaga);
  yield takeEvery(Actions.FORGOT, forgotSaga);
  yield takeEvery(Actions.RESET_PASSWORD, resetPasswordSaga);
}
