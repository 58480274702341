import styled from "styled-components";

import { responsiveSize } from "../../utils/font";

export const StyledContainer = styled.div`
  padding: 0 ${responsiveSize(48)} 0 ${responsiveSize(20)};

  height: ${responsiveSize(69)};

  display: flex;

  justify-content: space-between;

  background: #fff;
  border-bottom: 1px solid #ececec;

  cursor: default;
  user-select: none;
`;

export const StyledGroup = styled.div`
  display: flex;
  align-items: center;
`;
