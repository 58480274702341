import * as yup from "yup";

import { Button, Input } from "../../components";
import {
  StyledButtonSocial,
  StyledContainer,
  StyledForm,
  StyledInput,
  StyledOr,
  StyledRow,
  StyledTitle,
} from "./modal-register-styles";

import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import ModalContainer from "../root/container";
import ModalInfo from "../root/info";
import React from "react";
import configApi from "../../config/api";
import { useFormik } from "formik";

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  surname: yup.string().required(),
  email: yup.string().email().required(),
  password: yup.string().required().min(4).max(20),
});

const ModalRegisterView = ({
  className,
  showModal,
  loading,
  saveAuthShowModal,
  onSubmit,
  onClose,
}) => {
  const formik = useFormik({
    initialValues: {
      name: "",
      surname: "",
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit,
  });

  return (
    <ModalContainer
      showModal={showModal}
      leftComponent={
        <ModalInfo
          title="Welcome Back!"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel tempus dui, et interdum velit."
          buttonText="SIGN IN"
          onClick={() => saveAuthShowModal("login")}
        />
      }
      onClose={onClose}
    >
      <StyledContainer className={className}>
        <StyledTitle>Create Account</StyledTitle>

        <StyledRow>
          <StyledInput
            as={Input}
            placeholder="First Name"
            offset
            name="name"
            valid={!formik.errors.name || !formik.touched.name}
            value={formik.values.name}
            onChange={formik.handleChange("name")}
            onBlur={formik.handleBlur("name")}
          />

          <StyledInput
            as={Input}
            placeholder="Last Name"
            name="surname"
            valid={!formik.errors.surname || !formik.touched.surname}
            value={formik.values.surname}
            onChange={formik.handleChange("surname")}
            onBlur={formik.handleBlur("surname")}
          />
        </StyledRow>

        <StyledRow>
          <StyledInput
            as={Input}
            placeholder="Email Address"
            name="email"
            type="email"
            valid={!formik.errors.email || !formik.touched.email}
            value={formik.values.email}
            onChange={formik.handleChange("email")}
            onBlur={formik.handleBlur("email")}
          />
        </StyledRow>

        <StyledRow>
          <StyledInput
            as={Input}
            placeholder="Password"
            name="password"
            type="password"
            valid={!formik.errors.password || !formik.touched.password}
            value={formik.values.password}
            onChange={(value) => {
              formik.handleChange("password")(value);

              console.log("password", value);
            }}
            onBlur={formik.handleBlur("password")}
          />
        </StyledRow>

        <Button
          title="REGISTER"
          size="large"
          loading={loading}
          full
          onClick={() => formik.handleSubmit()}
        />

        <StyledOr>or</StyledOr>

        <StyledRow>
          <FacebookLogin
           appId={configApi.FACEBOOK_APP_ID}
            fields="id,email,name"
            callback={(data) => {
              onSubmit(data, "facebook");
            }}
            render={(renderProps) => (
              <StyledButtonSocial
                as={Button}
                size="base"
                backgroundColor="#3B5998"
                icon={require("./images/facebook.png")}
                loading={renderProps.isProcessing || loading}
                full
                offset
                onClick={renderProps.onClick}
              />
            )}
          />

          <StyledButtonSocial
            as={Button}
            size="base"
            backgroundColor="#256DC1"
            loading={loading}
            icon={require("./images/twitter.png")}
            full
          />
        </StyledRow>
      </StyledContainer>
    </ModalContainer>
  );
};

export default ModalRegisterView;
