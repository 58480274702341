import {
  forgotStatusSelector,
  loadingSelector,
} from "../../modules/auth/selectors";

import ModalForgotContainer from "./modal-forgot-container";
import { connect } from "react-redux";
import { forgot } from "../../modules/auth/actions";
import { saveAuthShowModal } from "../../modules/auth/actions";

const mapStateToProps = (state) => ({
  loading: loadingSelector(state),
  forgotStatus: forgotStatusSelector(state),
});

const mapDispatchToProps = {
  forgot,
  saveAuthShowModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalForgotContainer);
