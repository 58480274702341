import StoreTemplateContainer from "./store-template-conatiner";
import { connect } from "react-redux";
import { loadProjectData } from "../../../modules/project/actions";
import { projectSelector } from "../../../modules/project/selectors";

const mapStateToProps = (state) => ({
  project: projectSelector(state),
});

const mapDispatchToProps = {
  loadProjectData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreTemplateContainer);
