import { StyledContainer, StyledHeader } from "./gallery-content-styled";

import GalleryContentHeader from "../header";
import { Grid } from "../../../../components";
import React from "react";

// import { items } from "./gallery-content-data";

const GalleryContentView = ({ data }) => {
  const renderItem = item => <p>Hello</p>;

  return (
    <StyledContainer>
      <StyledHeader as={GalleryContentHeader} />

      <Grid items={data} renderItem={renderItem} />
    </StyledContainer>
  );
};

export default GalleryContentView;
