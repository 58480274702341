import { Grid } from "../../../../../../components";
import React from "react";
import { items } from "./store-projects-grid-data";
import { useHistory } from "react-router-dom";

const StoreProjectsGridStyled = ({ data, onChangeActive }) => {
  let history = useHistory();

  return (
    <Grid
      items={data}
      type="buy"
      onSelectItem={({ id, data }) => {
        console.log({ id, data });
        history.push(`/store/${id}`, { data });
      }}
    />
  );
};

export default StoreProjectsGridStyled;
