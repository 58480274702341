import {
  StyledBody,
  StyledContainer,
  StyledWrapper,
  StyledContent,
  StyledDescription,
  StyledHeader,
  StyledIcon,
  StyledImage,
  StyledItem,
  StyledSelect,
  StyledSize
} from "./editor-settings-type-styled";

import { animated, config, useSpring, useTransition } from "react-spring";

import React from "react";

import { responsiveSize } from "../../../../../../../../utils/font";

var settings = {
  height: 54,
  count: 5
};

const EditorSettingsTypeView = ({
  active,
  items,
  canvas,
  onChangeActive,
  onChange
}) => {
  const { height, transform, opacity } = useSpring({
    opacity: active ? 1 : 0,
    height: responsiveSize(
      active ? settings.height * settings.count + 3 : settings.height
    ),
    transform: `perspective(1000px) rotate(${active ? 180 : 0}deg)`,
    config: { mass: 5, tension: 300, friction: 60 },
    duration: 600
  });

  return (
    <StyledContainer>
      <StyledWrapper height={settings.height}>
        <StyledBody as={animated.div} active={active} style={{ height }}>
          <StyledHeader onClick={() => onChangeActive(!active)}>
            <StyledContent>
              <StyledSize>{items[canvas.type].size}</StyledSize>

              <StyledDescription>
                {items[canvas.type].description}
              </StyledDescription>
            </StyledContent>

            <StyledIcon
              as={animated.img}
              style={{
                transform: transform.interpolate(t => `${t}`)
              }}
              src={require("./images/arrow-bottom.png")}
            />
          </StyledHeader>

          <StyledSelect>
            {Object.values(items).map(item => (
              <StyledItem
                onClick={() => onChange(item.type)}
                disabled={item.type === canvas.type}
                last={item.type === "custom"}
                key={item.type}
              >
                <StyledContent>
                  <StyledSize>{item.size}</StyledSize>

                  <StyledDescription>{item.description}</StyledDescription>
                </StyledContent>

                {item.image && (
                  <StyledImage src={item.image} width={item.width} />
                )}
              </StyledItem>
            ))}
          </StyledSelect>

          {/* <p>hello1111</p> */}
        </StyledBody>
      </StyledWrapper>
    </StyledContainer>
  );
};

export default EditorSettingsTypeView;
