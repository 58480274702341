export const items = [
  {
    id: 1,
    name: "Presentation",
    preview: require("./images/presentation.svg"),
    created: "24.06.2020"
  },

  {
    id: 2,
    name: "Instagram Stories",
    preview: require("./images/stories.svg"),
    created: "24.06.2020"
  }
];
