import { responsiveSize } from "../../../../../../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledInput = styled.input`
  height: ${responsiveSize(40)};
  width: ${responsiveSize(327)};
  padding: ${responsiveSize(9)} ${responsiveSize(12)} ${responsiveSize(9)}
    ${responsiveSize(46)};

  font-size: ${responsiveSize(14)};
  font-family: "Roboto";

  background: #ececec url(${(props) => props.icon}) no-repeat;
  background-size: ${responsiveSize(22)} ${responsiveSize(22)};
  background-position: ${responsiveSize(12)} ${responsiveSize(9)};
  border: none;
  outline: none;
  border-radius: ${responsiveSize(8)};

  &::placeholder {
    font-size: inherit;
  }
`;
