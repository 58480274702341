export const items = [
  {
    icon: require("./images/recent.svg"),
    label: "Recent files",
    type: "recent-files",
    url: "/"
  },
  {
    icon: require("./images/templates.svg"),
    label: "Templates",
    type: "templates",
    url: "/templates"
  }
];

export const subitems = [
  {
    icon: require("./images/quick-guide.svg"),
    label: "Quick guide",
    type: "quick-guide"
  },
  {
    icon: require("./images/animation-tutorial.svg"),
    label: "Animation Tutorial",
    type: "animation-tutorial"
  },
  {
    icon: require("./images/guide.svg"),
    label: "Guide",
    type: "guide"
  }
];
