import { responsiveSize } from "../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;

  overflow: hidden;

  position: relative;
`;

export const Styledbody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const StyledContent = styled.div`
  flex: 1;
  padding: ${responsiveSize(30)} 0px;

  overflow-y: auto;
  overflow-x: hidden;

  position: relative;
`;
