import React from "react";

import { Icon, FileLoader } from "../../../../../../../../components";

import ContentControlGroup from "../group";

// import { StyledInput } from "./content-control-image-styled";

const ContentControlFontStyleView = ({ onChange }) => {
  return (
    <ContentControlGroup>
      <FileLoader onClick={({ url }) => onChange(url)}>
        <Icon src={require("./images/icon.svg")} type="button" />
      </FileLoader>
    </ContentControlGroup>
  );
};

export default ContentControlFontStyleView;
