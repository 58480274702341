import { createSelector } from "reselect";
import { find } from "lodash";

export const rootCommon = state => state.editor;

export const loadingSelector = createSelector(
  rootCommon,
  // data => false
  data => data.loading
);

export const successSelector = createSelector(
  rootCommon,
  data => data.success
);

export const defaultScaleSelector = createSelector(
  rootCommon,
  data => data.defaultScale
);

export const scaleSelector = createSelector(
  rootCommon,
  data => data.scale
);

export const canvasSelector = createSelector(
  rootCommon,
  data => data.canvas
);

export const itemsSelector = createSelector(
  rootCommon,
  data => data.items
);

export const slidesSelector = createSelector(
  rootCommon,
  data => {
    return data.slides;
  }
);

export const selectedSliderIndexesSelector = createSelector(
  rootCommon,
  data => {
    if (!data.selectedSlideId)
      return {
        row: 0,
        column: 0
      };

    const [idRow, idColumn] = data.selectedSlideId.split("/");

    const activeRow = find(data.slides, { id: +idRow });
    const indexActiveRow = data.slides.indexOf(activeRow);

    const column = find(activeRow.data, { id: +idColumn });

    const indexActiveColumn = activeRow.data.indexOf(column);

    return {
      row: indexActiveRow,
      column: indexActiveColumn
    };
  }
);

export const historyStackSelector = createSelector(
  rootCommon,
  data => data.historyStack
);

export const activeHistoryStepSelector = createSelector(
  rootCommon,
  data => data.activeHistoryStep
);

export const selectedItemIdSelector = createSelector(
  rootCommon,
  data => data.selectedItemId
);

export const selectedSlideIdSelector = createSelector(
  rootCommon,
  data => data.selectedSlideId
);

export const selectedItemSelector = createSelector(
  rootCommon,
  data => data.items[data.selectedItemId]
);

export const uploadSelector = createSelector(
  rootCommon,
  data => data.upload
);

export const metaSelector = createSelector(
  rootCommon,
  data => data.meta
);

export const showEffectSelector = createSelector(
  rootCommon,
  data => data.showEffect
);

export const groupedSlidesSelector = createSelector(
  rootCommon,
  data => data.groupedSlides
);
