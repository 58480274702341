import styled from "styled-components";

import { responsiveSize } from "../../../../utils/font";

export const StyledIcon = styled.img`
  width: ${responsiveSize(24)};
  height: ${responsiveSize(24)};
  margin-right: ${responsiveSize(16)};

  transition: 0.6s;
`;

export const StyledText = styled.div`
  font-weight: 500;
  font-size: ${responsiveSize(12)};
  line-height: ${responsiveSize(14)};
  /* text-align: center; */
  text-transform: uppercase;
  color: #3e4141;
`;

export const StyledContainer = styled.div`
  display: flex;
  /* flex-direction: column; */
  /* justify-content: center; */
  align-items: center;
  width: 100%;

  padding: ${responsiveSize(16)} ${responsiveSize(32)};

  opacity: ${props => (props.active ? 1 : 0.5)};
  transition: all 0.3s;

  border-right: 2px solid ${props =>
    props.active ? "#f71b43" : "transparent"};
  border-left: 2px solid transparent;

  background-color: ${props => (props.active ? "#fff" : "transparent")};

  cursor: pointer;

  /* ${StyledIcon} */

  &:hover ${StyledIcon}{
    transform: rotate(360deg);
  }
`;
