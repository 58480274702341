import styled from "styled-components";

import { responsiveSize } from "../../../../utils/font";

const BORDER_RADIUS = 4;

export const StyledContainer = styled.div`
  border-radius: ${responsiveSize(BORDER_RADIUS)};
  box-shadow: 0px ${responsiveSize(10)} ${responsiveSize(20)}
    rgba(0, 0, 0, 0.05);

  cursor: pointer;
`;

export const StyledInner = styled.div`
  transition: transform 0.5s;
`;

export const StyledPreview = styled.div`
  height: ${responsiveSize(240)};

  background: url(${props => props.image}) no-repeat center;
  background-size: cover;

  border-radius: ${responsiveSize(BORDER_RADIUS)}
    ${responsiveSize(BORDER_RADIUS)} 0px 0px;
`;

export const StyledContent = styled.div`
  border: 1px solid rgba(62, 65, 65, 0.1);
  border-top: none;
  border-radius: 0px 0px ${responsiveSize(BORDER_RADIUS)}
    ${responsiveSize(BORDER_RADIUS)};
`;

export const StyledInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${responsiveSize(16)};

  border-bottom: 1px solid rgba(62, 65, 65, 0.1);
`;

export const StyledName = styled.span`
  color: #3e4141;
  font-weight: 500;
  font-size: ${responsiveSize(16)};
`;

export const StyledPrice = styled.span`
  padding: ${responsiveSize(4)} ${responsiveSize(12)};

  font-size: ${responsiveSize(16)};
  line-height: ${responsiveSize(19)};
  font-weight: 500;
  color: ${props => (props.free ? "#ffffff" : "#f71b43")};

  border: 1px solid #f71b43;
  box-sizing: border-box;
  border-radius: ${responsiveSize(4)};
  background: ${props => (props.free ? "#f71b43" : "transparent")};
`;

export const StyledMeta = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${responsiveSize(12)} ${responsiveSize(16)};
`;

export const StyledMetaItem = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledMetaLabel = styled.span`
  margin-right: ${responsiveSize(12)};

  font-size: ${responsiveSize(12)};
  color: #3e4141;

  opacity: 0.5;
`;

export const StyledUsername = styled.span`
  font-size: ${responsiveSize(12)};
`;

export const StyledDownloads = styled.span`
  font-size: ${responsiveSize(12)};
  color: #f71b43;
`;
