import React, { useEffect, useLayoutEffect, useState } from "react";
import { StyledContainer, StyledHeader } from "./store-template-styles";
import { useLocation, useParams } from "react-router-dom";

import PuffLoader from "react-spinners/PuffLoader";
import StoreProjectsBody from "./components/body";
import StoreProjectsGrid from "./components/grid";
import StoreProjectsHeader from "./components/header";
import { Transition } from "react-spring/renderprops";

const StoreProjectsView = ({ project, loadProjectData, onChangeActive }) => {
  let location = useLocation();
  let { id } = useParams();

  const [data, setData] = useState(location.state ? location.state.data : null);

  useEffect(() => {
    setTimeout(() => {
      // setData([]);
      if (!location.state) loadProjectData({ id });
    }, 4000);
    // if (!location.state) loadProjectData({ id });
  }, []);

  useEffect(() => {
    if (project) setData(project);
  }, [project]);

  return (
    <StyledContainer loading={!data}>
      {/* <Transition
        items={data}
        from={{ opacity: 0 }}
        enter={{ opacity: 1 }}
        leave={{ opacity: 0 }}
      >
        {(data) =>
          data
            ? (props) => (
                <div style={props}>
                  <StyledHeader
                    as={StoreProjectsHeader}
                    data={data}
                    showBack={location.state}
                    onChangeActive={onChangeActive}
                  />

                  <StoreProjectsGrid data={data} />

                  <StoreProjectsBody data={data} />
                </div>
              )
            : (props) => (
                <div style={props}>
                  <PuffLoader size={50} color={"#000"} loading={true} />
                </div>
              )
        }
      </Transition> */}

      {data ? (
        <>
          <StyledHeader
            as={StoreProjectsHeader}
            data={data}
            showBack={location.state}
            onChangeActive={onChangeActive}
          />

          <StoreProjectsGrid data={data} />

          <StoreProjectsBody data={data} />
        </>
      ) : (
        <PuffLoader size={50} color={"#000"} loading={true} />
      )}
    </StyledContainer>
  );
};

export default StoreProjectsView;
