import EditorRecentEmptyCard from "./components/empty-card";
import { Grid } from "../../../components";
import { Link } from "react-router-dom";
import React from "react";
import { StyledContainer } from "./editor-recent-styled";
import { items } from "./editor-recent-data";

const GalleryContentView = ({data}) => {
  return (
    <StyledContainer>
      <Grid
        items={data}
        EmptyCard={
          <Link to="/canvas/new">
            <EditorRecentEmptyCard />
          </Link>
        }
      />
    </StyledContainer>
  );
};

export default GalleryContentView;
