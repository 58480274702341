import styled from "styled-components";

import { responsiveSize } from "../../../../utils/font";

const BORDER_RADIUS = 4;

export const StyledContainer = styled.div`
  border: 1px solid #ececec;
  border-radius: ${responsiveSize(BORDER_RADIUS)};
  box-shadow: 0px ${responsiveSize(10)} ${responsiveSize(20)}
    rgba(0, 0, 0, 0.05);
`;

export const StyledHeader = styled.div`
  padding: ${responsiveSize(24)};
`;

export const StyledPreview = styled.div`
  height: ${responsiveSize(176)};

  background: url(${props => props.image}) no-repeat center;
  background-size: contain;

  border-radius: ${responsiveSize(BORDER_RADIUS)}
    ${responsiveSize(BORDER_RADIUS)} 0px 0px;
`;

export const StyledContent = styled.div`
  padding: ${responsiveSize(16)} ${responsiveSize(24)};

  border-top: 1px solid #ececec;
`;

export const StyledInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledName = styled.span`
  color: #3e4141;
  font-weight: 500;
  font-size: ${responsiveSize(16)};
`;

export const StyledMeta = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledMetaLabel = styled.span`
  margin-right: ${responsiveSize(16)};

  font-size: ${responsiveSize(12)};
  color: #3e4141;

  opacity: 0.5;
`;

export const StyledMetaValue = styled.span`
  font-size: ${responsiveSize(12)};
  color: #3e4141;
`;
