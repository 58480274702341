import { responsiveSize } from "../../utils/font";
import styled from "styled-components";

export const StyledSelect = styled.select`
  min-width: ${responsiveSize(139)};
  font-size: ${responsiveSize(16)};
  color: #3e4141;

  background-color: transparent;
  background-image: url(${props => props.icon});
  background-repeat: no-repeat;
  background-size: ${responsiveSize(16)};
  background-position-x: 100%;
  background-position-y: 50%;

  border: none;
  border-radius: ${responsiveSize(4)};
  outline: none;

  -webkit-appearance: none;
  -moz-appearance: none;

  &::-ms-expand {
    display: none;
  }
`;
