import {
  canvasSelector,
  loadingSelector,
  successSelector,
  uploadSelector,
  showEffectSelector
} from "../../modules/editor/selectors";
import {
  cleanEditorData,
  loadEditorData,
  saveScale
} from "../../modules/editor/actions";
import { loadGalleryData, loadStoreData } from "../../modules/project/actions";

import EditorContainer from "./editor-container";
import { connect } from "react-redux";

const mapStateToProps = state => ({
  upload: uploadSelector(state),
  loading: loadingSelector(state),
  success: successSelector(state),
  canvas: canvasSelector(state),
  showEffect: showEffectSelector(state)
});

const mapDispatchToProps = {
  loadEditorData,
  loadGalleryData,
  loadStoreData,
  cleanEditorData,
  saveScale
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorContainer);
