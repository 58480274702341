import styled from "styled-components";

export const StyledContainer = styled.div`
  ${props => props.full && "width: 100%"};

  display: flex;
  align-items: center;
`;

export const StyledFileLoad = styled.input`
  display: none;
`;
