import { responsiveSize } from "../../../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  margin: ${responsiveSize(32)} 0;

  flex: 1;

  display: flex;

  overflow-x: hidden;
  /* flex-wrap: wrap; */
  /* justify-content: space-between; */
  padding: 0px ${responsiveSize(20)};
`;

export const StyledItem = styled.div`
  /* flex-shrink: 0; */
  padding: 0px ${responsiveSize(20)};

  height: ${responsiveSize(476)};
  width: ${responsiveSize(785)};

  /* width: calc(100% / 3); */
`;
