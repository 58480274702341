import styled from "styled-components";

import { responsiveSize } from "../../../../../../../../utils/font";

export const StyledItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${responsiveSize(24)};
  height: ${responsiveSize(24)};

  background: ${props => (props.active ? "#d8d9d9" : "transparent")};
  border-radius: ${responsiveSize(4)};

  opacity: ${props => (props.active ? 1 : 0.5)};
  transition: all 0.5s;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: ${responsiveSize(8)};
  }
`;

export const StyledIcon = styled.img`
  cursor: pointer;
`;
