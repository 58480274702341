import React, { useEffect, useState } from "react";

import ContentControlShadowView from "./content-control-shadow-view";

const ContentControlShadowContainer = props => {
  const [show, setShow] = useState(!!props.style.shadowColor);

  useEffect(() => {
    console.log("props.style", props.style);
    setShow(
      props.style.shadowOffset !== { width: 0, height: 0 } &&
        props.style.shadowRadius
    );
  }, [props.style]);

  const handleChange = ({ value, key }) => {
    props.onChangeItems({
      key: "style",
      data: {
        [key]: value
      }
    });
  };

  // const handleChangeLocalWidth = (value) => {
  //   console.log("value", value);

  //   if (value) {
  //     handleChange({ value, key: "borderWidth"})
  //   } else  handleChange({ value: 1, key: "borderWidth"})
  // };

  const handleAdd = () => {
    console.log("handleAdd");

    props.onChangeItems({
      key: "style",
      data: {
        shadowColor: "rgba(0,0,0,0.25)",
        shadowOffset: {
          width: 5,
          height: 10
        },
        shadowRadius: 40
      }
    });

    // setLocalWidth(1)
    setShow(true);
  };

  const handleRemove = () => {
    console.log("handleRemove");

    props.onChangeItems({
      key: "style",
      data: {
        shadowColor: "rgba(0,0,0,0.25)",
        shadowOffset: {
          width: 0,
          height: 0
        },
        shadowRadius: 0
      }
    });

    // setLocalWidth(0)
    setShow(false);

    // props.onRemove({
    //   keys: ["shadowColor", "shadowOffset", "shadowRadius"],
    //   type: "style",
    // });
  };

  return (
    <ContentControlShadowView
      {...props}
      show={show}
      onChange={handleChange}
      onAdd={handleAdd}
      onRemove={handleRemove}
      // onChangeLocalWidth={handleChangeLocalWidth}
    />
  );
};

export default ContentControlShadowContainer;
