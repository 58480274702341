import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import EditorView from "./editor-view";
import { NotificationManager } from "react-notifications";

const EditorContainer = props => {
  let { id } = useParams();
  // let query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    if (id !== "new") props.loadEditorData({ id });
    else props.cleanEditorData();
  }, []);

  useEffect(() => {
    if (props.upload.success === false) {
      NotificationManager.error("try later", "Something went wrong", 4000);
    } else if (props.upload.success) {
      NotificationManager.success("Template saved", "Success");

      props.loadGalleryData();
      props.loadStoreData();
    }
  }, [props.upload.success]);

  return <EditorView {...props} id={id} />;
};

export default EditorContainer;
