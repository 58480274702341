import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import StoreProjectsVew from "./store-projects-view";
import queryString from "query-string";

const StoreProjectsContainer = (props) => {
  const location = useLocation();
  const query = queryString.parse(location.search);

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [searchStr, setSearchStr] = useState(undefined);

  const handleRegister = () => {
    console.log("handleRegister");
    setShowLoginModal(!showLoginModal);
  };

  const filteredData = useMemo(() => {
    let res = [...props.data];

    if (query.type != undefined && query.type != "all") {
      res = res.filter((item) => {
        return item.category == query.type;
      });
    }

    if (searchStr != undefined)
      res = res.filter((item) => {
        return (
          item.name.toLowerCase().indexOf(searchStr.trim().toLowerCase()) !== -1
        );
      });

    return res;
  }, [query]);

  return (
    <StoreProjectsVew
      {...props}
      data={filteredData}
      showLoginModal={showLoginModal}
      searchStr={searchStr}
      onChangeSearchStr={setSearchStr}
      onRegister={handleRegister}
      onChangeShowLoginModal={setShowLoginModal}
    />
  );
};

export default StoreProjectsContainer;
