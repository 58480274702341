import React, { useEffect, useMemo, useState } from "react";
import {
  StyledColor,
  StyledColorBlur,
  StyledColorBlurContainer,
  StyledColorContainer,
  StyledColorInput,
  StyledColorPicker,
  StyledContainer,
  StyledInfo,
  StyledSwitch
} from "./color-picker-styled";
import { animated, useSpring } from "react-spring";

import { ChromePicker } from "react-color";
import Input from "../input";
import OutsideClickHandler from "react-outside-click-handler";
import Switch from "../switch";
import { responsiveSize } from "../../utils/font";

const InputView = ({
  style,
  value,
  defaultValue,
  active,
  label,
  type = "circle",
  blur = false,
  showInfo = false,
  onChange,
  onChangeActive
}) => {
  const rgb2hex = rgb => {
    rgb = rgb.match(
      /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
    );
    return rgb && rgb.length === 4
      ? "#" +
          ("0" + parseInt(rgb[1], 10).toString(16)).slice(-2) +
          ("0" + parseInt(rgb[2], 10).toString(16)).slice(-2) +
          ("0" + parseInt(rgb[3], 10).toString(16)).slice(-2)
      : "";
  };

  const tansformValueColor = () => {
    let data = value || defaultValue;

    if (data.indexOf("#") != -1) {
      return data.slice(1);
    } else {
      let hex = rgb2hex(data);

      return hex.slice(1);
    }
  };

  const color = useMemo(() => {
    let data = value || defaultValue;
    let res = data.slice(5, -1).split(",");

    return {
      r: res[0],
      g: res[1],
      b: res[2],
      a: res[3]
    };
  }, [value]);

  const tansformedValueColor = useMemo(() => {
    return tansformValueColor();
  }, [value]);

  const tansformedValueAlpha = useMemo(() => {
    let data = value || defaultValue;

    if (data.indexOf("#") != -1) {
      return 100;
    } else {
      let values = data.split(",");

      let percent = values[values.length - 1].slice(0, -1);

      console.log("percent", percent);

      return percent * 100;
    }
  }, [value]);

  const [localValueColor, setLocalValueColor] = useState(tansformedValueColor);
  const [localValueAlpha, setLocalValueAlpha] = useState(tansformedValueAlpha);
  const [show, setShow] = useState(true);
  const [animateValue, setAnimateValue] = useState(true);

  // useEffect(() => {
  //   if (active) setAnimateValue(true);
  //   else {
  //     setTimeout(() => {
  //       setAnimateValue(false);
  //     }, 300);
  //   }
  // }, [active]);

  useEffect(() => {
    if (localValueColor != value) {
      setLocalValueColor(tansformValueColor);
      setLocalValueAlpha(tansformedValueAlpha);
    }
  }, [value]);

  const handleChangeValue = () => {
    let rgba = hexToRgba(`#${localValueColor}`, localValueAlpha);

    if (rgba && rgba != value) onChange(rgba);
  };

  useEffect(() => {
    handleChangeValue();
  }, [localValueColor, localValueAlpha]);

  const hexToRgba = (hex, alpha) => {
    console.log("hex", hex);

    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");

      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");

      return (
        "rgba(" +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
        "," +
        alpha / 100 +
        ")"
      );
    }
  };

  const handleChangeColor = color => {
    setLocalValueColor(color.hex.slice(1));
    setLocalValueAlpha(color.rgb.a * 100);
  };

  const handleOutsideClick = () => {
    console.log("handleOutsideClick");
    onChangeActive(false);
  };

  const { transform, opacity } = useSpring({
    opacity: active ? 1 : 0,
    transform: `perspective(1000px) rotateX(${
      active ? 180 : 0
    }deg) translateX(${active ? 100 : -300})`,
    config: { mass: 5, tension: 300, friction: 60 },
    duration: 300
  });

  return (
    <>
      <StyledContainer>
        <StyledColorContainer
          style={style}
          color={value}
          type={type}
          blur={blur}
          onClick={() => {
            onChangeActive(!active);
          }}
        >
          <StyledColor color={value} type={type} blur={blur} />
        </StyledColorContainer>

        {/* <StyledColorBlurContainer
          color={value}
          onClick={() => {
            onChangeActive();
          }}
        >
          <StyledColorBlur />
        </StyledColorBlurContainer> */}

        {showInfo && (
          <StyledInfo
            onClick={() => {
              onChangeActive(!active);
            }}
          >
            <StyledColorInput
              as={Input}
              label="#"
              disabled
              width={responsiveSize(44)}
              value={localValueColor}
              defaultValue={tansformedValueColor}
              onChange={setLocalValueColor}
            />

            <Input
              // style={{ border: "none" }}
              value={localValueAlpha}
              defaultValue={tansformedValueAlpha}
              labelLeft="%"
              // type="number"
              color="#b3b3b3"
              min={0}
              max={100}
              disabled
              onChange={setLocalValueAlpha}
            />
          </StyledInfo>
        )}

        <StyledColorPicker
          // as={animated.div}
          style={
            {
              // transform: transform.interpolate(t => `${t} rotateX(180deg) `)
              // transform: transform.interpolate((t) => `${t}`),
            }
          }
          active={active}
          show={animateValue}
        >
          {/* <OutsideClickHandler onOutsideClick={() => handleOutsideClick()}> */}
          <ChromePicker
            color={color}
            width={responsiveSize(216)}
            onChange={handleChangeColor}
          />
          {/* </OutsideClickHandler> */}
        </StyledColorPicker>
      </StyledContainer>
    </>
  );
};

export default InputView;
