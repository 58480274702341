import * as Actions from "./constants";

import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  createProject,
  loadGalleryData,
  loadProjectData,
  loadStoreData,
} from "./service";

import { tokenSelector } from "../auth/selectors";

function* loadStoreDataSaga() {
  try {
    const res = yield call(loadStoreData);

    yield put({ type: Actions.LOADING_STORE_DATA_SUCCESS, payload: res });
  } catch (e) {
    console.log(e);
    yield put({ type: Actions.LOADING_STORE_DATA_ERROR });
  }
}

function* loadGalleryDataSaga() {
  try {
    const token = yield select(tokenSelector);

    const res = yield call(loadGalleryData, {token});

    yield put({ type: Actions.LOADING_GALLERY_DATA_SUCCESS, payload: res });
  } catch (e) {
    console.log(e);
    yield put({ type: Actions.LOADING_GALLERY_DATA_ERROR });
  }
}

function* loadProjectDataSaga({ payload }) {
  console.log("payloadpayloadpayloadpayloadpayload", payload);
  try {
    const res = yield call(loadProjectData, payload);

    yield put({
      type: Actions.LOADING_PROJECT_DATA_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    console.log(e);
    yield put({ type: Actions.LOADING_PROJECT_DATA_ERROR });
  }
}

function* createProjectSaga({ payload }) {
  try {
    console.log("payload", payload);
    const res = yield call(createProject, payload);

    yield put({
      type: Actions.CREATE_PROJECT_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    console.log(e);
    yield put({ type: Actions.CREATE_PROJECT_ERROR });
  }
}

export default function* iapSaga() {
  yield takeEvery(Actions.LOADING_STORE_DATA, loadStoreDataSaga);
  yield takeEvery(Actions.LOADING_GALLERY_DATA, loadGalleryDataSaga);
  yield takeEvery(Actions.LOADING_PROJECT_DATA, loadProjectDataSaga);
  yield takeEvery(Actions.CREATE_PROJECT, createProjectSaga);
}
