import CanvasControlGroup from "../group";
import React from "react";
import { Select } from "../../../../../../../../components";
import { StyledSelect } from "./canvas-control-font-styled";
import { fontFamilyOptions } from "../../../../../../../../config/font";

const CanvasControlFontView = ({ style, onChangeItems }) => {
  const handleChange = value => {
    onChangeItems({
      key: "style",
      data: {
        fontFamily: value
      }
    });
  };

  return (
    <CanvasControlGroup>
      <StyledSelect
        as={Select}
        value={style.fontFamily}
        options={fontFamilyOptions}
        onChange={handleChange}
      />
      {/* <StyledSelect>
        <option>Roboto</option>
      </StyledSelect> */}
    </CanvasControlGroup>
  );
};

export default CanvasControlFontView;
