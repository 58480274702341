import { Dimmer, Loader } from "semantic-ui-react";
import React, { createRef, useEffect } from "react";
import {
  StyledBody,
  StyledContainer,
  StyledControl,
  StyledEffect,
  StyledHeader,
  StyledSidebar,
  StyledWrapper
} from "./editor-styled";

import EditorContent from "./components/content";
// import EditorTest from "./components/test";
import EditorControl from "./components/control";
import EditorEffect from "./components/effect";
import EditorEmpty from "./components/empty";
import EditorHeader from "./components/header";
import EditorScaling from "./components/scaling";
import EditorSidebar from "./components/sidebar";
import EditorSlidesControl from "./components/slides-control";
import { NotificationContainer } from "react-notifications";
import { sizes } from "../../config/sizes";

// new EditorContent({
//   word: "OCEAN",
//   position: [-80, -10, 0],
//   rotation: [Math.PI, 0, 0],
//   zoom: 100
// });

export default function EditorView({ loading, success, id, showEffect }) {
  // const contentRef = createRef();

  // useEffect(() => {
  //   const scaleW =
  //     Math.floor(
  //       ((contentRef.current.offsetWidth - 64) / sizes[canvas.type].width) * 100
  //     ) / 100;
  //   const scaleH =
  //     Math.floor(
  //       ((contentRef.current.offsetHeight - 72) / sizes[canvas.type].height) *
  //         100
  //     ) / 100;

  //   const scale = Math.min(scaleW, scaleH);

  //   saveScale(scale);
  //   // onScale(scale <= 1 ? scale : 1);
  // }, [canvas.type]);

  return (
    <StyledContainer>
      {/* <Dimmer active={loading}>
        <Loader />
      </Dimmer> */}

      {!loading || !success || id === "new" ? (
        <div style={{ display: "flex", flex: 1, height: "100%" }}>
          <StyledSidebar as={EditorSidebar} />

          <StyledWrapper
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative"
              // zIndex: -1
            }}
          >
            <StyledHeader>
              <EditorHeader />
            </StyledHeader>

            <StyledWrapper>
              <StyledBody>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                    flex: 1
                  }}
                  // style={{ backgroundColor: "green" }}
                >
                  {!loading && <EditorContent />}
                  {/* {!loading && <EditorTest />} */}

                  {/* <section class="container">
                    <article class="tile">
                      <figure class="tile__figure">
                        <img
                          src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80"
                          data-hover="https://images.unsplash.com/photo-1522609925277-66fea332c575?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80"
                          class="tile__image"
                          alt="My image"
                          width="300"
                        />
                      </figure>
                    </article>
                  </section> */}

                  <StyledEffect as={EditorEffect} show={showEffect} />
                </div>

                <StyledControl>
                  <EditorControl />
                </StyledControl>
              </StyledBody>
            </StyledWrapper>
          </StyledWrapper>
        </div>
      ) : (
        <EditorEmpty />
      )}

      <NotificationContainer />
    </StyledContainer>
  );
}
