import { Button, Icon } from "../../../../../components";
import {
  StyledBackContainer,
  StyledBackIcon,
  StyledBackText,
  StyledBlock,
  StyledContainer,
  StyledDownloadConatiner,
  StyledDownloadLabel,
  StyledDownloadValue,
  StyledTitle,
} from "./store-projects-header-styles";

import React from "react";
import { useHistory } from "react-router-dom";

const StoreProjectsHeaderView = ({
  className,
  showBack,
  data,
  onChangeActive,
}) => {
  let history = useHistory();

  return (
    <StyledContainer className={className}>
      <div>
        <StyledBackContainer
          onClick={() => (showBack ? history.goBack() : history.push("/store"))}
        >
          <StyledBackIcon as={Icon} name="back" />

          <StyledBackText>Back</StyledBackText>
        </StyledBackContainer>

        <StyledTitle>{data.name}</StyledTitle>
      </div>

      <StyledBlock>
        <StyledDownloadConatiner>
          <StyledDownloadLabel>Downloads</StyledDownloadLabel>
          <StyledDownloadValue>{data.countDownloads}</StyledDownloadValue>
        </StyledDownloadConatiner>

        <Button title="USE THIS" size="big" />
      </StyledBlock>
      {/* <StoreProjectsHeaderSearch /> */}
    </StyledContainer>
  );
};

export default StoreProjectsHeaderView;
