import React from "react";
import TopLineView from "./top-line-view";
import { useHistory } from "react-router-dom";

const TopLineContainer = (props) => {
  let history = useHistory();

  const handlerCreateProject = () => {
    // alert("hello");
    history.push("/canvas/new");
  };

  const handlerChangeRegister = () => {
    props.saveAuthShowModal("register");
  };

  return (
    <TopLineView
      {...props}
      onCreateProject={handlerCreateProject}
      onRegister={handlerChangeRegister}
    />
  );
};

export default TopLineContainer;
