import {
  StyledArrow,
  StyledContainer,
  StyledContent,
  StyledIcon,
  StyledSubmenu,
  StyledText
} from "./editor-sidebar-item-styled";

import EditorSidebarSubmenu from "../submenu";
import { Icon } from "../../../../../../components";
import OutsideClickHandler from "react-outside-click-handler";
import PropTypes from "prop-types";
import React from "react";

const EditorSidebarItemView = ({
  type,
  icon,
  label,
  active,
  subitems,
  showSubmenu,
  onClick,
  onChangeShowSubmenu,
  onSelect
}) => {
  return (
    <OutsideClickHandler onOutsideClick={() => onChangeShowSubmenu(false)}>
      <StyledContainer>
        <StyledContent
          active={active}
          onClick={() =>
            subitems ? onChangeShowSubmenu(!showSubmenu) : onSelect({ type })
          }
        >
          <StyledIcon src={icon} alt="icon" />

          <StyledText>{label}</StyledText>

          {subitems && (
            <StyledArrow as={Icon} src={require("./images/arrow-right.svg")} />
          )}
        </StyledContent>

        <StyledSubmenu
          as={EditorSidebarSubmenu}
          items={subitems}
          show={subitems && showSubmenu}
          onCancel={() => onChangeShowSubmenu(false)}
          onSelect={subType => {
            onSelect({ type, subType: subType.type });
            onChangeShowSubmenu(false);
          }}
        />

        {/* {subitems && showSubmenu && ( */}

        {/* )} */}
      </StyledContainer>
    </OutsideClickHandler>
  );
};

EditorSidebarItemView.propTypes = {
  onClick: PropTypes.func,
  onSelect: PropTypes.func
};

EditorSidebarItemView.defaultProps = {
  onClick: () => {},
  onSelect: () => {}
};

export default EditorSidebarItemView;
