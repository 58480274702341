import { responsiveSize } from "../../../../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledTitle = styled.h1`
  font-weight: 500;
  font-size: ${responsiveSize(24)};
  /* line-height: 1; */
  font-family: "Roboto";
`;
