import EditorHeaderScalingContainer from "./editor-header-scaling-container";

import { connect } from "react-redux";
import { scaleSelector } from "../../../../../../modules/editor/selectors";
import { saveScale } from "../../../../../../modules/editor/actions";

const mapStateToProps = state => ({
  scale: scaleSelector(state)
});

const mapDispatchToProps = {
  saveScale
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorHeaderScalingContainer);
