import "./canvas-content-control-group-style.scss";

import {
  StyledContainer,
  StyledContent,
  StyledHeader,
  StyledPreview,
  StyledToggle
} from "./canvas-content-control-group-styled";
import { animated, useSpring, useTransition } from "react-spring";

import PropTypes from "prop-types";
import React from "react";
import { Toggle } from "../../../../../../../../components";
import { responsiveSize } from "../../../../../../../../utils/font";

const CanvasContentControlGroupView = ({
  children,
  placholder,
  last,
  showContent,
  onChangeShowContent,
  onShow,
  onHide
}) => {
  const { opacityShowMore, width, transform, opacity } = useSpring({
    opacityShowMore: showContent ? 1 : 0,
    width: showContent ? responsiveSize(281) : responsiveSize(130),
    opacity: showContent || !placholder ? 1 : 0,
    transform: `perspective(1000px) scale(${
      showContent || !placholder ? 1 : 0
    }) translateY(${
      showContent || !placholder ? "-100%" : "-300%"
    }) translateX(${showContent || !placholder ? "-50%" : "-200%"})`,
    // config: { mass: 5, tension: 300, friction: 60 },
    duration: 600
  });

  const transitions = useTransition(showContent, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  });

  return (
    <StyledContainer last={last}>
      {placholder && (
        <StyledHeader>
          <StyledPreview>{placholder}</StyledPreview>

          <StyledToggle
            as={Toggle}
            value={showContent}
            onChange={value => {
              !showContent ? onShow() : onHide();
            }}
          />
        </StyledHeader>
      )}

      <StyledContent
        as={animated.div}
        style={
          placholder &&
          {
            // opacity,
            // width
          }
        }
      >
        {/* {transitions.map(({ item, key, props }) => 
          item
            ? <animated.div style={{...props, ...{ display: "flex", flex: 1, overflow: "hidden"}}} key={key}>{children}</animated.div>
            : <animated.div style={{...props, ...{ display: "flex", flex: 1, overflow: "hidden"}}} key={key}>
              <StyledPreview>{placholder}</StyledPreview>
            </animated.div>
        )} */}
        {/* {!placholder ? (
          children
        ) : ( */}

        {showContent || !placholder ? children : <></>}
        {/* )} */}
      </StyledContent>
    </StyledContainer>
  );
};

CanvasContentControlGroupView.propTypes = {
  last: PropTypes.bool,
  onShow: PropTypes.func,
  onHide: PropTypes.func
};

CanvasContentControlGroupView.defaultProps = {
  last: false,
  onShow: () => {},
  onHide: () => {}
};

export default CanvasContentControlGroupView;
