import * as Actions from "./constants";

export function login(payload) {
  console.log("login", payload);

  return {
    type: Actions.LOGIN,
    payload,
  };
}

export function register(payload) {
  console.log("REGISTER", payload);
  return {
    type: Actions.REGISTER,
    payload,
  };
}

export function forgot(payload) {
  return {
    type: Actions.FORGOT,
    payload,
  };
}

export function logout(payload) {
  return {
    type: Actions.LOGOUT,
    payload,
  };
}

export function resetPassword(payload) {
  return {
    type: Actions.RESET_PASSWORD,
    payload,
  };
}

export function saveAuthShowModal(payload) {
  return {
    type: Actions.SAVE_AUTH_SHOW_MODAL,
    payload,
  };
}
