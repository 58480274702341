import EditorRecentContainer from "./editor-recent-container";
import { connect } from "react-redux";
import { gallerySelector } from "../../../modules/project/selectors";

const mapStateToProps = (state) => ({
  data: gallerySelector(state),
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(EditorRecentContainer);
