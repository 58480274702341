import { responsiveSize } from "../../../../../../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div``;

export const StyledWrapper = styled.div`
  height: ${props => responsiveSize(props.height)};

  position: relative;
`;

export const StyledBody = styled.div`
  /* height: ${props => responsiveSize(props.height)}; */
  width: 100%;

  border: 1px solid #d8d9d9;
  border-radius: ${responsiveSize(4)};

  /* filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1)); */
  background-color: #fff;

  position: absolute;

  z-index: 1;
  overflow: hidden;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`;

export const StyledHeader = styled.div`
  height: ${responsiveSize(54)};
  padding: ${responsiveSize(8)};

  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid rgba(62, 65, 65, 0.1);

  cursor: pointer;
`;

export const StyledItem = styled.div`
  height: ${responsiveSize(54)};

  padding: ${responsiveSize(8)} 0;

  margin: 0 ${responsiveSize(8)};

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: ${props =>
    props.last ? "none" : "1px solid rgba(62, 65, 65, 0.1);"};

  transition: all 0.4s;
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  opacity: ${props => (props.disabled ? 0.5 : 1)};

  ${props =>
    !props.disabled &&
    `
    &:hover {
      transition: all 0.4s;
      opacity: 0.7;
    }
  `}
`;

export const StyledSelect = styled.div`
  width: 100%;

  top: ${responsiveSize(54)};
  position: absolute;

  /* z-index: 1; */

  background-color: #fff;

  border-radius: ${responsiveSize(4)};
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledSize = styled.p`
  margin-bottom: ${responsiveSize(3)};

  font-size: ${responsiveSize(16)};
  line-height: ${responsiveSize(19)};

  color: #3e4141;
`;

export const StyledDescription = styled.p`
  font-size: ${responsiveSize(12)};
  line-height: ${responsiveSize(14)};

  color: #3e4141;

  opacity: 0.5;
`;

export const StyledIcon = styled.img`
  width: ${responsiveSize(16)};
  height: ${responsiveSize(16)};
`;

export const StyledImage = styled.img`
  width: ${props => props.width || "10%"};

  color: #9fa0a0;
`;
