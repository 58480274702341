const fontFamilyOptions = [
  { label: "Arial", value: "Arial" },
  {
    label: "Montserrat",
    value: "Montserrat"
  },
  {
    label: "Manrope",
    value: "Manrope"
  },
  {
    label: "Roboto",
    value: "Roboto"
  },
  {
    label: "Oswald",
    value: "Oswald"
  },
  {
    label: "Merriweather",
    value: "Merriweather"
  },
  {
    label: "Playfair Display",
    value: "Playfair Display"
  },
  {
    label: "Open Sans Condensed",
    value: "Open Sans Condensed"
  },
  {
    label: "Rubik",
    value: "Rubik"
  },
  {
    label: "Lobster",
    value: "Lobster"
  },
  {
    label: "Pacifico",
    value: "Pacifico"
  },
  {
    label: "Exo 2",
    value: "Exo 2"
  },
  {
    label: "Yanone Kaffeesatz",
    value: "Yanone Kaffeesatz"
  },
  {
    label: "Comfortaa",
    value: "Comfortaa"
  },
  {
    label: "Amatic SC",
    value: "Amatic SC"
  },
  {
    label: "Cormorant Garamond",
    value: "Cormorant Garamond"
  },
  {
    label: "Play",
    value: "Play"
  },
  {
    label: "Caveat",
    value: "Caveat"
  },
  {
    label: "IBM Plex Serif",
    value: "IBMPlexSerif"
  },
  {
    label: "Cuprum",
    value: "Cuprum"
  },
  {
    label: "Didact Gothic",
    value: "Didact Gothic"
  },
  {
    label: "Montserrat Alternates",
    value: "Montserrat Alternates"
  },
  {
    label: "Marck Script",
    value: "Marck Script"
  },
  {
    label: "Prata",
    value: "Prata"
  },
  {
    label: "PT Mono",
    value: "PT Mono"
  },
  {
    label: "Philosopher",
    value: "Philosopher"
  },
  {
    label: "Russo One",
    value: "Russo One"
  },
  {
    label: "Neucha",
    value: "Neucha"
  },
  {
    label: "Alice",
    value: "Alice"
  },
  {
    label: "Press Start 2P",
    value: "Press Start 2P"
  },
  {
    label: "Pangolin",
    value: "Pangolin"
  },
  {
    label: "Bad Script",
    value: "Bad Script"
  },
  {
    label: "Cormorant",
    value: "Cormorant"
  },
  {
    label: "Jura",
    value: "Jura"
  },
  {
    label: "Yeseva One",
    value: "Yeseva One"
  },
  {
    label: "Bellota",
    value: "Bellota"
  },
  {
    label: "Marmelad",
    value: "Marmelad"
  },
  {
    label: "Kelly Slab",
    value: "Kelly Slab"
  },
  {
    label: "Forum",
    value: "Forum"
  },
  {
    label: "Anonymous Pro",
    value: "Anonymous Pro"
  },
  {
    label: "Rubik Mono One",
    value: "Rubik Mono One"
  },
  {
    label: "Pattaya",
    value: "Pattaya"
  },
  {
    label: "Gabriela",
    value: "Gabriela"
  },
  {
    label: "Cormorant SC",
    value: "Cormorant SC"
  },
  {
    label: "Prosto One",
    value: "Prosto One"
  },
  {
    label: "Ruslan Display",
    value: "Ruslan Display"
  },
  {
    label: "Underdog",
    value: "Underdog"
  },
  {
    label: "Seymour One",
    value: "Seymour One"
  },
  {
    label: "Stalinist One",
    value: "Stalinist One"
  },
  {
    label: "Rosarivo",
    value: "Rosarivo"
  },
  {
    label: "Adamina",
    value: "Adamina"
  },
  {
    label: "Alegreya",
    value: "Alegreya"
  },
  {
    label: "Alex Brush",
    value: "Alex Brush"
  },
  {
    label: "Bilbo",
    value: "Bilbo"
  },
  {
    label: "Ribeye",
    value: "Ribeye"
  },
  {
    label: "Reenie Beanie",
    value: "Reenie Beanie"
  },
  {
    label: "Amatic SC",
    value: "Amatic SC"
  }
];

const fontWeigthOptions = [
  {
    label: "200",
    value: "200"
  },
  {
    label: "300",
    value: "300"
  },
  {
    label: "400",
    value: "400"
  },
  {
    label: "500",
    value: "500"
  },
  {
    label: "600",
    value: "600"
  },
  {
    label: "700",
    value: "700"
  },
  {
    label: "800",
    value: "800"
  },
  {
    label: "900",
    value: "900"
  }
];

const fontStyleOptions = [
  {
    label: "Regular",
    value: "normal"
  },
  {
    label: "Italic",
    value: "italic"
  }
];

export { fontFamilyOptions, fontWeigthOptions, fontStyleOptions };
