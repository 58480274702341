import * as yup from "yup";

import {
  Button,
  Input,
  Modal,
  Select,
  Textarea,
} from "../../../../../../../../../components";
import React, { useEffect, useState } from "react";
// import StoreProjectsLogin from "./components/login";
// import StoreProjectsRegister from "./components/register";
import {
  StyledFooter,
  StyledFooterButton,
  StyledFooterPrice,
  StyledFooterPriceValue,
  StyledInput,
  StyledItem,
  StyledLabel,
  StyledModal,
  StyledPrice,
  StyledPriceLabel,
  StyledSelect,
  StyledTitle,
} from "./canvas-header-modal-publishing-styles";
import { animated, useSpring } from "react-spring";

import CanvasHeaderModalPublishingPreview from "./components/preview";
import { Spring } from "react-spring/renderprops";
import { categoryOptions } from "./canvas-header-modal-publishing-options";
import { useFormik } from "formik";

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  category: yup.string().required(),
  description: yup.string().required(),
  preview: yup.object().required(),
  tags: yup.string().required(),
  price: yup.number().required(),
});

const CanvasHeaderModalPublishingView = ({ meta, preview, showModal, onClose, onSubmit, onChangePreview }) => {
  const formik = useFormik({
    initialValues: meta ? {
      name: meta.name,
      category: meta.category,
      description: meta.description,
      preview: meta.preview,
      tags: meta.tags.join(' '),
      price: 0,
    } : {
      name: "",
      category: "presentation",
      description: "",
      preview: "",
      tags: "",
      price: 0,
    },
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (showModal && meta) {
      formik.handleChange("name")(meta.name)
      formik.handleChange("category")(meta.category)
      formik.handleChange("description")(meta.description)
      formik.handleChange("tags")(meta.tags.join(' '))
    }
  }, [showModal])

  // useEffect(() => {
  //   // if (!showModal) {
  //   //   formik.resetForm({
  //   //     name: "",
  //   //     category: "presentation",
  //   //     description: "",
  //   //     preview: "",
  //   //     tags: "",
  //   //     price: 0,
  //   //   })
  //   // }
  // }, [showModal])

  return (
    <StyledModal as={Modal} showModal={showModal} onClose={onClose}>
      <StyledTitle>Project Publishing</StyledTitle>

      {/* <StyledLabel>{JSON.stringify(meta)}</StyledLabel> */}

      <StyledItem>
        <StyledLabel>Project name</StyledLabel>

        <StyledInput
          as={Input}
          placeholder="Name"
          name="name"
          valid={!formik.errors.name || !formik.touched.name}
          value={formik.values.name}
          onChange={formik.handleChange("name")}
          onBlur={formik.handleBlur("name")}
        />
      </StyledItem>

      <StyledItem>
        <StyledLabel>Category</StyledLabel>

        <StyledSelect as={Select} options={categoryOptions} />
      </StyledItem>

      <StyledItem>
        <StyledLabel>Description</StyledLabel>

        <Textarea
          as={Input}
          rows={5}
          name="description"
          valid={!formik.errors.description || !formik.touched.description}
          value={formik.values.description}
          onChange={formik.handleChange("description")}
          onBlur={formik.handleBlur("description")}
        />
      </StyledItem>

      <StyledItem>
        <StyledLabel>Preview</StyledLabel>

        <CanvasHeaderModalPublishingPreview
          preview={preview}
          valid={!formik.errors.preview || !formik.touched.preview}
          onChangePreview={onChangePreview}
          onChange={(value) => {
            formik.handleChange("preview")(value)
          }}
        />
      </StyledItem>

      <StyledItem style={{ marginTop: 0 }}>
        <StyledLabel>Tags</StyledLabel>

        <StyledInput
          as={Input}
          placeholder="Name"
          name="tags"
          valid={!formik.errors.tags || !formik.touched.tags}
          value={formik.values.tags}
          onChange={formik.handleChange("tags")}
          onBlur={formik.handleBlur("tags")}
        />
      </StyledItem>

      {/* <StyledItem>
        <StyledLabel>Set your price</StyledLabel>

        <StyledPrice>
          <StyledInput
            as={Input}
            placeholder="Name"
            label="$"
            value={24}
            type="number"
            name="price"
            valid={!formik.errors.price || !formik.touched.price}
            value={formik.values.price}
            onChange={formik.handleChange("price")}
            onBlur={formik.handleBlur("price")}
          />

          <StyledPriceLabel>+ Buyer fee 20%</StyledPriceLabel>
        </StyledPrice>
      </StyledItem> */}

      <StyledFooter>
        {/* <StyledFooterPrice>
          <StyledLabel>Total Price</StyledLabel>

          <Spring
            from={{ number: 0 }}
            to={{ number: +formik.values.price + formik.values.price * 0.2 }}
          >
            {(props) => (
              <StyledFooterPriceValue>
                {props.number.toFixed(2)}$
              </StyledFooterPriceValue>
            )}
          </Spring>
        </StyledFooterPrice> */}

        <StyledFooterButton
          as={Button}
          title="Save and Publish"
          onClick={() => formik.handleSubmit()}
        />
      </StyledFooter>
    </StyledModal>
  );
};

export default CanvasHeaderModalPublishingView;
