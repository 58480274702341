import {
  StyledContainer,
  StyledDate,
  StyledDescription,
  StyledItem,
  StyledText,
  StyledTitle,
} from "./store-template-body-content-styled";

import React from "react";

const StoreTemplateBodyContentView = ({ data }) => {
  return (
    <StyledContainer>
      <StyledDescription>{data.description}</StyledDescription>

      <StyledTitle>Version History</StyledTitle>

      {data.versions.map((item) => (
        <StyledItem>
          <StyledDate>{item.date}</StyledDate>

          <StyledText>{item.description}</StyledText>
        </StyledItem>
      ))}
    </StyledContainer>
  );
};

export default StoreTemplateBodyContentView;
