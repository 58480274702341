import { responsiveSize } from "../../../../../../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  backdrop-filter: blur(30px);
  background: rgba(255, 255, 255, 0.55);

  display: ${props => (props.show ? "flex" : "none")};
`;

export const StyledCancel = styled.button`
  width: 100%;
  padding: ${responsiveSize(16)};

  color: #fff;
  font-size: ${responsiveSize(12)};
  text-align: center;
  text-transform: uppercase;

  border: none;
  outline: none;
  box-shadow: none;

  background-color: #3e4141;

  cursor: pointer;
`;
