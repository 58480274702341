import styled from "styled-components";

import { responsiveSize } from "../../utils/font";

export const StyledContainer = styled.div`
  position: relative;
`;

export const StyledWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

export const StyledImage = styled.img`
  /* width: ${props => props.size}px; */
  /* height: ${props => props.size}px; */
   width: ${props => responsiveSize(props.size.width)};
  height: ${props => responsiveSize(props.size.height)};
  ${props => props.color && `tint-color: ${props.color}`};
  color: #b3b3b3;

  cursor: ${props => (props.type === "button" ? "pointer" : "default")}
`;
