import React, { useEffect, useMemo, useState } from "react";

import ModalLoginView from "./modal-login-view";

const ModalLoginContainer = (props) => {
  useEffect(() => {
    if (props.isAuthenticated) props.saveAuthShowModal(false);
  }, [props.isAuthenticated]);

  const handlerSubmit = (data, source = "native") => {
    if (source != "facebook") {
      props.login({
        email: data.email,
        facebookId: null,
        loginSource: "native",
        password: data.password,
      });
    } else if (source == "facebook" && data.status == undefined) {
      props.login({
        email: data.email || data.userID,
        facebookId: data.userID,
        loginSource: "facebook",
        password: data.userID,
      });
    }
  };

  return <ModalLoginView {...props} onSubmit={handlerSubmit} />;
};

export default ModalLoginContainer;
