import { metaSelector, uploadSelector } from "../../../../../../../../../modules/editor/selectors";

import CanvasHeaderModalPublishingContainer from "./canvas-header-modal-publishing-container";
import { connect } from "react-redux";
import { createProject } from "../../../../../../../../../modules/project/actions";
import { createProjectLoadingSelector } from "../../../../../../../../../modules/project/selectors";
import { uploadTemplate } from "../../../../../../../../../modules/editor/actions";

const mapStateToProps = (state) => ({
  createProjectLoading: createProjectLoadingSelector(state),
  upload: uploadSelector(state),
  meta: metaSelector(state)
});

const mapDispatchToProps = {
  createProject,
  uploadTemplate
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CanvasHeaderModalPublishingContainer);
