import styled from "styled-components";

import { responsiveSize } from "../../../../utils/font";

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: ${responsiveSize(24)} ${responsiveSize(24)};
  width: ${responsiveSize(256)};

  background: rgba(241, 241, 241, 0.7);
  /* Background Blur */

  backdrop-filter: blur(30px);
`;

export const StyledItem = styled.div`
  margin-bottom: ${responsiveSize(24)};
`;
