export const items = [
  { icon: require("./images/move.svg"), label: "Move", type: "move" },
  { icon: require("./images/image.svg"), label: "Image", type: "image" },
  { icon: require("./images/text.svg"), label: "Text", type: "text" },
  {
    icon: require("./images/shapes.svg"),
    label: "Shapes",
    type: "block",
    subitems: [
      {
        icon: require("./images/shapes.svg"),
        label: "Shapes",
        type: "block"
      },
      {
        icon: require("./images/circle.svg"),
        label: "Cirlce",
        type: "circle"
      },
      {
        icon: require("./images/triangle.svg"),
        label: "Polygon",
        type: "block"
        // type: "triangle"
      },
      {
        icon: require("./images/star.svg"),
        label: "Polygon",
        type: "block"
        // type: "star"
      }
    ]
  }
];
