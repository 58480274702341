import React, { useState } from "react";
import {
  StyledBody,
  StyledContainer,
  StyledContent,
  StyledFooter,
  StyledFooterIcon,
  StyledItems,
  StyledLogo,
  StyledSlot,
  StyledWrapper
} from "./editor-sidebar-styled";

import EditorSidebarItem from "./components/item";
import EditorSidebarItemImage from "./components/item-image";
import { Link } from "react-router-dom";
import { items } from "./editor-sidebar-config";

const EditorSidebarView = ({
  activeItem,
  onAddItem,
  onSelectItem,
  onLogout
}) => {
  const renderItem = (item, index) => {
    if (item.type === "image") {
      return (
        <EditorSidebarItemImage
          {...item}
          key={index}
          accept=".jpg, .jpeg, .png"
          active={activeItem === item.type}
          onClick={onAddItem}
        />
      );
    }

    if (item.type === "video") {
      return (
        <EditorSidebarItemImage
          {...item}
          key={index}
          accept=".mp3, .ogg, .wav, .mp4, .ogv, .webm"
          active={activeItem === item.type}
          onClick={onAddItem}
        />
      );
    }

    if (item.type === "svg") {
      return (
        <EditorSidebarItemImage
          {...item}
          key={index}
          accept=".svg"
          active={activeItem === item.type}
          onClick={onAddItem}
        />
      );
    }

    return (
      <EditorSidebarItem
        {...item}
        key={index}
        active={activeItem === item.type}
        onSelect={onAddItem}
      />
    );
  };

  return (
    <StyledContainer>
      {/* <StyledLogo as={Link} to={"/"} /> */}

      {items.map(
        renderItem
        // (item, index) =>
        // item.type === "image" ? (
        // <EditorSidebarItemImage
        //   {...item}
        //   key={index}
        //   active={activeItem === item.type}
        //   onClick={onAddItem}
        // />
        // ) : (
        // <EditorSidebarItem
        //   {...item}
        //   key={index}
        //   active={activeItem === item.type}
        //   onSelect={onAddItem}
        // />
        // )
      )}

      {/* <StyledItems>
        <EditorSidebarAnimation show={activeItem === "animation"} />
      </StyledItems> */}
    </StyledContainer>
  );
};

export default EditorSidebarView;
