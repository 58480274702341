import { responsiveSize } from "../../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div``;

export const StyledHeader = styled.div`
  padding: 0px ${responsiveSize(40)};
  margin-bottom: ${responsiveSize(32)};
`;

export const StyledSort = styled.div`
  margin-bottom: ${responsiveSize(24)};
  padding: 0px ${responsiveSize(40)};
`;
