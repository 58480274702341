import {
  StyledArrow,
  StyledContainer,
  StyledContent,
  StyledIcon,
  StyledSubmenu,
  StyledText
} from "./editor-sidebar-item-styled";

import EditorSidebarSubmenu from "../submenu";
import { Icon } from "../../../../../../../../components";
import OutsideClickHandler from "react-outside-click-handler";
import PropTypes from "prop-types";
import React from "react";

const EditorSidebarItemView = ({
  type,
  icon,
  label,
  active,
  subitems,
  showSubmenu,
  onClick,
  onChangeShowSubmenu,
  onSelect
}) => {
  return (
    <OutsideClickHandler onOutsideClick={() => onChangeShowSubmenu(false)}>
      <StyledContainer>
        <StyledContent
          active={active}
          onClick={() => {
            if (type != "animation") {
              subitems ? onChangeShowSubmenu(!showSubmenu) : onSelect({ type });
            }
          }}
        >
          <StyledIcon type={type} src={icon} alt="icon" />

          <StyledText type={type}>{label}</StyledText>

          {subitems && (
            <StyledArrow as={Icon} src={require("./images/arrow-right.svg")} />
          )}
        </StyledContent>
      </StyledContainer>

      <StyledSubmenu
        as={EditorSidebarSubmenu}
        items={subitems}
        show={+(subitems && showSubmenu)}
        onCancel={() => onChangeShowSubmenu(false)}
        onSelect={subType => {
          onSelect({ type: subType });
          onChangeShowSubmenu(false);
        }}
      />
    </OutsideClickHandler>
  );
};

EditorSidebarItemView.propTypes = {
  onClick: PropTypes.func,
  onSelect: PropTypes.func
};

EditorSidebarItemView.defaultProps = {
  onClick: () => {},
  onSelect: () => {}
};

export default EditorSidebarItemView;
