import request from "../../utils/request";

export const loadStoreData = () => request.get(`/get/project/store`);

export const loadGalleryData = ({ token }) => {
  console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!stoken", token);

  return request.get(`/get/project/gallery`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}

export const loadProjectData = ({ id }) => request.get(`/get/project/${id}`);

export const createProject = (data) => request.post(`/create/project`, data);
