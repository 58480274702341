import React, { useMemo } from "react";
import {
  StyleHeader,
  StyledContainer,
  StyledItem,
  StyledPlus,
  StyledTile
} from "./editor-control-styled";

import EditorControlArtboard from "./componets/artboard";
import EditorControlBlock from "./componets/block";
import EditorControlCircle from "./componets/circle";
import EditorControlContainer from "./componets/container";
import EditorControlEffects from "./componets/effects";
import EditorControlFill from "./componets/fill";
import EditorControlImage from "./componets/image";
import EditorControlSettings from "./componets/settings";
import EditorControlStroke from "./componets/stroke";
import EditorControlStyles from "./componets/styles";
import EditorControlText from "./componets/text";
import EditorControlUploader from "./componets/uploader";
import { Icon } from "../../../../components";

const components = {
  text: EditorControlText,
  image: EditorControlImage,
  block: EditorControlBlock,
  circle: EditorControlCircle,
};

const EditorControlView = ({
  items,
  selectedItemId,
  selectedSlideId,
  selectedItem,
  canvas,
  slides,
  historyStack,
  onChange,
  onChangeSlide,
  onRemove,
  selectedSliderIndexes
}) => {

  const ActiveComponent = useMemo(() => {
    if (selectedItem) return components[selectedItem.type];
  }, [selectedItem])

  // const ActiveComponent = components[selectedItem.type];
  // console.log("strValuesElements", strValuesElements);

  return (
    <StyledContainer>
      {/* <EditorControlArtboard /> */}

      <EditorControlSettings />

      {/* {selectedItemId && items[selectedItemId].uploader && (
        <pre style={{ overflow: "scroll" }}>
          {JSON.stringify(items[selectedItemId].style)}
        </pre>
      )} */}

      {/* {(items[selectedItemId] || selectedItemId == null) && ( */}
      {/* <EditorControlContainer
        items={items}
        selectedItemId={selectedItemId}
        canvas={canvas}
        onChange={onChange}
      /> */}
      {/* )} */}

      {/* {selectedItemId &&
        items[selectedItemId] &&
        items[selectedItemId].type == "text" && (
          <EditorControlText
            items={items}
            selectedItemId={selectedItemId}
            onChange={onChange}
          />
        )} */}

      {/* {slides.backgroundColor && ( */}
      {/* <EditorControlFill
        slides={slides}
        selectedSlideId={selectedSlideId}
        selectedSliderIndexes={selectedSliderIndexes}
        onChange={onChangeSlide}
      /> */}
      {/* )} */}

      {/* <pre>selectedItem {JSON.stringify(selectedItem)}</pre> */}

      {selectedItem && (
        <ActiveComponent
          style={selectedItem.style}
          onChangeItems={(data) => {
            onChangeSlide(data)
          }}
        />
      )}

      {/* {((selectedItemId &&
        items[selectedItemId] &&
        (items[selectedItemId].type == "image" ||
          items[selectedItemId].type == "upload")) ||
        selectedItemId == null) && (
        <EditorControlImage
          items={items}
          selectedItemId={selectedItemId}
          canvas={canvas}
          onChange={onChange}
        />
      )} */}

      {/* {selectedItemId && items[selectedItemId].type == "upload" && (
        <EditorControlUploader
          items={items}
          selectedItemId={selectedItemId}
          canvas={canvas}
          onChange={onChange}
        />
      )} */}

      {selectedItemId && items[selectedItemId] && (
        <>
          {/* <EditorControlStroke
            items={items}
            selectedItemId={selectedItemId}
            onChange={onChange}
            onRemove={onRemove}
          /> */}

          {/* <EditorControlEffects
            items={items}
            selectedItemId={selectedItemId}
            onChange={onChange}
            onRemove={onRemove}
          /> */}
        </>
      )}
    </StyledContainer>
  );
};

export default EditorControlView;
