import { all } from "redux-saga/effects";
import authSaga from "./modules/auth/saga";
import editorSaga from "./modules/editor/saga";
import projectSaga from "./modules/project/saga";

/**
 * Root saga
 * @returns {IterableIterator<AllEffect | GenericAllEffect<any> | *>}
 */
export default function* rootSagas() {
  // yield all([]);
  yield all([editorSaga(), authSaga(), projectSaga()]);
}
