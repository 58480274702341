import { responsiveSize } from "../../../../../../../../utils/font";
import styled from "styled-components";

export const StyledInput = styled.input`
  /* width: 47.5%; */
  flex: 1;

  &:not(:last-child) {
    margin-right: 8px;
  }
`;

export const StyledRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;
