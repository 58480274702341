import React from "react";

import EditorContentScalingView from "./editor-content-scaling-view";

const EditorContentScalingContainer = props => {
  const handlerToggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  const handlerChangeScale = value => {
    const _value = Math.ceil(value * 100) / 100;

    if (_value < 0 || _value === 0) {
      props.saveScale(0.01);
    } else {
      props.saveScale(_value);
    }

    // if (_value < props.scale) {
    // alert(_value);
    // }
  };

  return (
    <EditorContentScalingView
      {...props}
      onToggleFullScreen={handlerToggleFullScreen}
      onChangeScale={handlerChangeScale}
    />
  );
};

export default EditorContentScalingContainer;
