import {
  StyledContainer,
  StyledIcon,
  StyledText
} from "./editor-sidebar-item-image-styled";

import { FileLoader } from "../../../../../../../../components";
import Item from "../item";
import React from "react";

const EditorSidebarItemView = ({
  icon,
  label,
  type,
  accept,
  active,
  onClick,
  onSelect
}) => {
  return (
    <FileLoader onClick={value => onClick({ type, value })} accept={accept}>
      <Item icon={icon} label={label} active={active} />{" "}
    </FileLoader>
  );
};

export default EditorSidebarItemView;
