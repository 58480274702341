import styled from "styled-components";

import { responsiveSize } from "../../../../../../../../utils/font";

export const StyledSelect = styled.select`
  width: ${responsiveSize(100)};

  font-size: ${responsiveSize(14)};
  line-height: ${responsiveSize(16)};
`;
