import React, { useState } from "react";
import {
  StyledBody,
  StyledContainer,
  StyledContent,
  StyledLogo,
  StyledWrapper
} from "./editor-sidebar-styled";

import EditorSidebarItem from "./components/item";
import EditorSidebarItemImage from "./components/item-image";
import { items } from "./editor-sidebar-config";

const EditorSidebarView = ({ activeItem, onSelectItem }) => {
  return (
    <StyledContainer>
      {/* <StyledBody>
        <StyledWrapper image={require("./images/bg.jpg")}>
          <StyledContent> */}
      <StyledLogo />

      {/* {items.map((item, index) => (
              <EditorSidebarItem
                {...item}
                key={index}
                active={activeItem === item.type}
                onSelect={onSelectItem}
              />
            ))} */}
      {/* </StyledContent>
        </StyledWrapper>
      </StyledBody> */}
    </StyledContainer>
  );
};

export default EditorSidebarView;
