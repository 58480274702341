import styled from "styled-components";

import { responsiveSize } from "../../../../../utils/font";

export const StyledContainer = styled.div`
  flex: 1;
  height: ${responsiveSize(345)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledContent = styled.div`
  padding: ${responsiveSize(32)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border: 1px solid rgba(62, 65, 65, 0.2);
  border-radius: 6px;

  cursor: pointer;
  transition: all 0.4s;

  &:hover {
    opacity: 0.8;
    transform: scale(0.93);
  }
`;

export const StyledText = styled.span`
  margin-top: ${responsiveSize(12)};

  font-weight: 500;
  font-size: ${responsiveSize(16)};
  text-align: center;
  color: #3f4141;
`;

export const StyledIcon = styled.img`
  cursor: pointer;
`;
