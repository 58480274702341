import {
  canvasSelector,
  historyStackSelector,
  itemsSelector,
  selectedItemIdSelector,
  selectedSlideIdSelector,
  selectedSliderIndexesSelector,
  slidesSelector
} from "../../../../modules/editor/selectors";
import {
  saveCanvas,
  saveHistoryStack,
  saveItems,
  saveSelectedSlideId,
  saveSlides,
  saveselectedItemId
} from "../../../../modules/editor/actions";

import EditorContentContainer from "./editor-control-container";
import { connect } from "react-redux";

const mapStateToProps = state => ({
  items: itemsSelector(state),
  selectedItemId: selectedItemIdSelector(state),
  canvas: canvasSelector(state),
  slides: slidesSelector(state),
  historyStack: historyStackSelector(state),
  selectedSlideId: selectedSlideIdSelector(state),
  selectedSliderIndexes: selectedSliderIndexesSelector(state)
});

const mapDispatchToProps = {
  saveItems,
  saveSlides,
  saveCanvas,
  saveselectedItemId,
  saveSelectedSlideId,
  saveHistoryStack
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorContentContainer);
