import React, { useEffect, useState } from "react";

import CanvasContentControlGroupView from "./canvas-content-control-group-view";

const CanvasContentControlGroupContainer = props => {
  // const [showContent, setShowContent] = useState(props.defaultShow || false);

  return (
    <CanvasContentControlGroupView
      {...props}
      // showContent={defaultShow}
      // onShow={onShow}
      // onHide={onHide}
      // onChangeShowContent={setShowContent}
    />
  );
};

export default CanvasContentControlGroupContainer;
