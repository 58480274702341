import GalleryContainer from "./gallery-container";
import { connect } from "react-redux";
import { storeSelector } from "../../modules/project/selectors";

const mapStateToProps = (state) => ({
  data: storeSelector(state),
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(GalleryContainer);
