import { Page, TopLine } from "../../components";

import GalleryContent from "./components/content";
import GallerySidebar from "./components/sidebar";
import React from "react";

const GalleryView = ({data}) => {
  return (
    <Page
      SidebarComponent={<GallerySidebar />}
      ContentComponent={<GalleryContent data={data} />}
      TopLineComponent={<TopLine base activeItem={"gallery"} />}
    />
  );
};

export default GalleryView;
