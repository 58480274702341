import * as Actions from "./constants";

export function loadStoreData(payload) {
  return {
    type: Actions.LOADING_STORE_DATA,
    payload,
  };
}

export function loadGalleryData(payload) {
  return {
    type: Actions.LOADING_GALLERY_DATA,
    payload,
  };
}

export function loadProjectData(payload) {
  return {
    type: Actions.LOADING_PROJECT_DATA,
    payload,
  };
}

export function createProject(payload) {
  return {
    type: Actions.CREATE_PROJECT,
    payload,
  };
}
