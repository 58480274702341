import {
  StyledContainer,
  StyledImage,
  StyledName
} from "./canvas-effect-item-styled";

import React from "react";

const CanvasEffectItemView = ({ className, name, image, active, onClick }) => {
  return (
    <StyledContainer className={className} onClick={onClick}>
      <StyledImage src={image} active={active} />

      <StyledName>{name}</StyledName>

      {/* <p>{active  ? "true" :  "false"}</p> */}
    </StyledContainer>
  );
};

export default CanvasEffectItemView;
