import React, { useEffect, useMemo, useState } from "react";

import ColorPickerView from "./color-picker-view";

const ColorPickerContainer = (props) => {
  const [active, setActive] = useState(false);

  const handleChangeActive = (value) => {
    console.log("handleChangeActive", value);

    setActive(value);
  };

  return (
    <ColorPickerView
      {...props}
      active={active}
      onChangeActive={handleChangeActive}
    />
  );
};

export default ColorPickerContainer;
