import { responsiveSize } from "../../../../../../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
`;

export const StyledItem = styled.div`
  flex: 1;

  &:not(:last-child) {
    margin-right: 12px;
  }
`;

export const StyledLabel = styled.span`
  font-size: 12px;

  color: #9fa0a0;
`;
