import React, { useEffect } from "react";

import CanvasEffectView from "./canvas-effect-view";

const CanvasEffectContainer = props => {
  useEffect(() => {
    props.saveShowEffect(false);
  }, [props.selectedItemId]);

  // const handleChange = (value) => {
  //   console.log("handleChange", {
  //     selectedSlideId,
  //     selectedItemId
  //   });

  //   if (selectedSlideId && selectedItemId) {
  //     let res = [...slides];

  //     res[selectedSliderIndexes.row].data[selectedSliderIndexes.column].items[
  //       selectedItemId
  //     ]['effect'] = value;

  //     saveSlides([...res]);
  //   }
  // };

  return <CanvasEffectView {...props} />;
};

export default CanvasEffectContainer;
