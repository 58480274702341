import { responsiveSize } from "../../../../../../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 100%;
  
  margin-bottom: ${responsiveSize(8)};

  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-wrap: wrap;
  /* height: ${responsiveSize(58)}; */

  &:not(:last-child) {
    border-right: 1px solid #ececec;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  align-items: center;

  padding: 0px ${responsiveSize(16)};
`;

export const StyledToggle = styled.div`
  margin-left: auto;
`;

export const StyledPreview = styled.div`
  /* width: 100%; */

  
  
  text-transform: uppercase;
  font-size: ${responsiveSize(12)};
  /* line-height: ${responsiveSize(14)}; */
  color: #9fa0a0;
`;

export const StyledHeader = styled.div`
  padding: 0px 1rem;
  margin-bottom: ${responsiveSize(8)};

  width: 100%;

  display: flex;
`;
