import styled from "styled-components";
import { responsiveSize } from "../../../../../../../../utils/font";

export const StyledInput = styled.div`
  margin-left: ${responsiveSize(16)};
`;

export const StyledSelect = styled.select`
  min-width: ${responsiveSize(70)};
  margin-left: ${responsiveSize(20)};

  font-size: ${responsiveSize(14)};
  line-height: ${responsiveSize(16)};
`;
