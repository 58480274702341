import { responsiveSize } from "../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  width: ${props => (props.show ? "100%" : 0)};
  height: ${props => (props.show ? "100%" : 0)};

  padding: ${responsiveSize(24)} 0;

  display: flex;
  align-items: center;
  /* justify-content: center;
  align-items: center; */

  position: absolute;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: rgba(0, 0, 0, 0.3);

  overflow: hidden;
  overflow-y: scroll;

  z-index: 10;
`;

export const StyledContent = styled.div`
  /* display: flex; */

  /* margin: ${responsiveSize(24)} 0; */

  margin: auto;

  background-color: #fff;

  filter: drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.5));

  z-index: 11;
`;

export const StyledClose = styled.div`
  position: absolute;
  top: ${responsiveSize(24)};
  right: ${responsiveSize(24)};

  cursor: pointer;
`;
