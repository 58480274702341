import styled from "styled-components";

import { responsiveSize } from "../../../../../../../../../../utils/font";

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${responsiveSize(28)};
  height: ${responsiveSize(28)};

  border: 1px solid #d8d9d9;
  border-radius: 50%;
  background-color: #fff;

  box-sizing: border-box;
  cursor: pointer;
`;

export const StyledColor = styled.div`
  width: ${responsiveSize(20)};
  height: ${responsiveSize(20)};

  border-radius: 50%;
  background: #2b6ef6;
`;
