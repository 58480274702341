import React, { useEffect, useState } from "react";
import {
  StyledClose,
  StyledContainer,
  StyledContent,
  StyledContentContainer,
} from "./modal-styled";
import { animated, useSpring } from "react-spring";

import Icon from "../icon";

// import StoreProjectsLogin from "./components/login";
// import StoreProjectsRegister from "./components/register";

const ModalView = ({ className, children, showModal, onClose }) => {
  const [animateValue, setAnimateValue] = useState(true);

  useEffect(() => {
    if (showModal) setAnimateValue(true);
    else {
      setTimeout(() => {
        setAnimateValue(false);
      }, 600);
    }
  }, [showModal]);

  const { transform, opacity } = useSpring({
    opacity: showModal ? 1 : 0,
    transform: `perspective(1000px) rotateX(${showModal ? 180 : 0}deg) scale(${
      showModal ? 1 : 0
    })`,
    config: { mass: 5, tension: 300, friction: 60 },
    duration: 600,
  });

  return (
    <StyledContainer
      show={animateValue}
      as={animated.div}
      style={{
        opacity,
      }}
    >
      <StyledContent
        className={className}
        as={animated.div}
        style={{
          opacity,
          transform: transform.interpolate((t) => `${t} rotateX(180deg) `),
        }}
      >
        {children}

        <StyledClose as={Icon} name="close" onClick={() => onClose()} />
      </StyledContent>
    </StyledContainer>
  );
};

export default ModalView;
