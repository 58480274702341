import GalleryContentView from "./editor-recent-view";
import React from "react";

const GalleryContentContainer = props => {
  // const handlerCreateProgect = () => {
  //   alert("hello");
  // };

  return <GalleryContentView {...props} />;
};

export default GalleryContentContainer;
