import PropTypes from "prop-types";
import React from "react";
import { StyledSelect } from "./select-styled";

const SelectView = ({ className, value, options, onChange }) => {
  return (
    <StyledSelect className={className} icon={require("./images/arrow.svg")}  value={value} onChange={(event) => onChange(event.target.value)}>
      {options.map((item, index) => (
        <option value={item.value} key={index}>
          {item.label}
        </option>
      ))}
    </StyledSelect>
  );
};

SelectView.propTypes = {
  options: PropTypes.array
};

SelectView.defaultTypes = {
  options: []
};

export default SelectView;
