import styled from "styled-components";

import { responsiveSize } from "../../../../utils/font";

export const StyledContainer = styled.div`
  display: flex;

  border-top: 1px solid #ececec;
`;

export const StyledContnet = styled.div`
  display: flex;
  flex-grow: 1;
  padding: ${responsiveSize(20)} ${responsiveSize(40)} ${responsiveSize(24)};

  overflow-x: auto;
`;

export const StyledItem = styled.div`
  padding: 2px;

  border-radius: ${responsiveSize(8)};

  &:not(:last-child) {
    margin-right: ${responsiveSize(20)};
  }

  border: 2px solid ${props => (props.active ? "#f71b43" : "transparent")};

  transition: all 0.5s;

  &:hover {
    transform: scale(0.95);
    opacity: 0.75;
  }
`;

export const StyledItemImage = styled.div`
  width: ${responsiveSize(173)};
  height: ${responsiveSize(95)};

  background: url(${props => props.image}) no-repeat;
  background-size: cover;
  border-radius: ${responsiveSize(8)};
`;
