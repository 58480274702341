import * as Actions from "./constants";

import { removeHeader } from "../../utils/request";

export const initState = {
  isAuthenticated: false,
  token: null,
  // "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiYmEwNjA2YjkyMmFhODJlZWRkMGIzNWVhOTdkNThlM2NlZGE0MDY1NTVhZWFhODc1ZmY3OTYwNGVlOGYxNGEyYTc1MzY4MWE3YWM1MmExMWMiLCJpYXQiOjE1OTI2ODM3OTYsIm5iZiI6MTU5MjY4Mzc5NiwiZXhwIjoxNjI0MjE5Nzk2LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.ZNMrwZCLr9PxpQ98PxLlH241AgaY7SpHNsNMMMswU4wcbIQFQUtWWISLuzTtnpw6DQdnR_HOPJWSaHZswF3PoJk9comS21xUMoAK7CoPZj8ioXamVA81lBFWHdYYH95i_jAzMzxX0edVw-Mg23EzFyybupgOKrmRVVnBQvcxWgXcLv-0WN0SMrxHLCm9rDf57-6377fNX_h9D22fHYD5SdvBqGnEVF-CKH0CDsOQBWx__k2vfmIaDYOkfVKgN9GYxJMQXvAjuAffYoWMForbhh7ixj5KmgLII3UPOA89r0osUOY1c9vHXPKEl2tDUTICKzRDCpOZ-bRvAlJ7rk-oRdmKlBG7564wT7Va9WxC7M6zi9Av_MaPZUwFrNlBxcma1J_mGEzSZ9P4rdEwvRtDmZY2GD5No9A4zt4G8zamuLPpc0d1qsRG-aRIHjPEgG9ACM-OXh73zescuaLM6XnV-9WkrRXedZRpLQ8MXZ40YKHOrsjUze0eGKAM4bHsZ4dUjOZ2Rt_Fsp7r8moH6fhvEKcpibODHQWEbcMWoBRf09AESlrYZ6mHiI-IrU6_hVcJOyvIXqYuw9hInW-TBheFw1JlQMTJpfws_fw4xKRjDTNGm_t__mEIJvcWP3yQADLtVW8ynLnnZftjGLpa3LnGfnjbrv5ZElO7bS_IwRn7bxw",
  user: null,
  loading: false,
  error: null,
  showModal: false,

  forgotStatus: null,
  resetPasswordStatus: null
};

/**
 * Common reducer
 * @param state
 * @param action
 * @returns {*}
 */
function commonReducer(state = initState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case Actions.SAVE_AUTH_TOKEN:
      return {
        ...state,
        token: payload
      };

    case Actions.REGISTER:
      console.log("Actions.REGISTER");
      return { ...state, loading: true, error: null };
    case Actions.REGISTER_SUCCESS:
      console.log("REGISTER_SUCCESS", payload);

      // setHeader(payload.token);

      return {
        ...state,
        loading: false,
        error: null,
        isAuthenticated: true,
        user: payload.user,
        token: payload.token
      };
    case Actions.REGISTER_ERROR:
      return { ...state, loading: false, error: payload };

    case Actions.LOGIN:
      console.log("Actions.LOGIN");
      return { ...state, loading: true, error: null };
    case Actions.LOGIN_SUCCESS:
      console.log("LOGIN_SUCCESS", payload);

      return {
        ...state,
        loading: false,
        error: null,
        isAuthenticated: true,
        user: payload.user,
        token: payload.token
      };
    case Actions.LOGIN_ERROR:
      return { ...state, loading: false, error: payload };
    
    case Actions.LOGOUT:
      removeHeader();
      
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        user: null,
        loading: false,
        error: null,
      };

    case Actions.FORGOT:
      return { ...state, loading: true, forgotStatus: null };
    case Actions.FORGOT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        forgotStatus: true
      };
    case Actions.FORGOT_ERROR:
      return { ...state, forgotStatus: false, error: payload };

    case Actions.RESET_PASSWORD:
      return { ...state, loading: true, resetPasswordStatus: null };
    case Actions.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        resetPasswordStatus: true
      };
    case Actions.RESET_PASSWORD_ERROR:
      return { ...state, resetPasswordStatus: false, error: payload };

    case Actions.SAVE_AUTH_USER_DATA:
      return {
        ...state,
        user: payload,
        isAuthenticated: true
      };

    case Actions.SAVE_AUTH_SHOW_MODAL:
      return {
        ...state,
        showModal: payload
      };

    default:
      return state;
  }
}

export default commonReducer;
