import { responsiveSize } from "../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  /* width: ${(props) => (props.empty ? "30%" : "50%")}; */
  width: ${(props) => (props.empty ? "28%" : "50%")};

  display: flex;
  /* flex: 1; */
  /* flex: 1.33; */

  background-image: url(${require("./images/left_bg.png")});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const StyledContent = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: ${responsiveSize(40)};

  background-color: rgba(0, 0, 0, 0.5);
`;

export const StyledTitle = styled.h1`
  font-weight: bold;
  font-size: ${responsiveSize(36)};
  line-height: ${responsiveSize(42)};

  font-family: "Roboto";

  color: #ffffff;
`;

export const StyledDescription = styled.p`
  margin: ${responsiveSize(20)} 0;

  text-align: center;

  font-size: ${responsiveSize(16)};
  line-height: ${responsiveSize(19)};

  font-family: "Roboto";

  color: #ffffff;
`;

export const StyledButton = styled.div.attrs((props) => ({
  styleContainer: {
    width: "60%",
  },
  styleText: {
    fontSize: responsiveSize(14),
    fontWeight: "bold",
  },
}))``;
