import React, { useState } from "react";
import { StyledIcon, StyledItem } from "./content-control-text-align-styled";

import ContentControlGroup from "../group";
import { Icon } from "../../../../../../../../components";

const ContentControlTextAlignView = ({style, onChangeItems}) => {
  const [activeItem, setActiveItem] = useState("center");

  const handleChange = (value) => {
    onChangeItems({
      key: "style",
      data: {
        textAlign: value
      }
    });
  }

  return (
    <ContentControlGroup>
      <StyledItem
        active={style.textAlign === "left"}
        onClick={() => handleChange("left")}
      >
        <StyledIcon as={Icon} src={require("./images/left.svg")} />
      </StyledItem>

      <StyledItem
        active={style.textAlign === "center"}
        onClick={() => handleChange("center")}
      >
        <StyledIcon as={Icon} src={require("./images/center.svg")} />
      </StyledItem>

      <StyledItem
        active={style.textAlign === "right"}
        onClick={() => handleChange("right")}
      >
        <StyledIcon as={Icon} src={require("./images/right.svg")} />
      </StyledItem>
    </ContentControlGroup>
  );
};

export default ContentControlTextAlignView;
