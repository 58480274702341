export const items = [
  {
    icon: require("./images/projects.svg"),
    label: "All Projects",
    type: "projects",
    url: "/store",
  },
  {
    icon: require("./images/presentations.svg"),
    label: "Presentations",
    type: "presentation",
    url: "/store?type=presentation",
  },
  {
    icon: require("./images/stories.svg"),
    label: "Stories",
    type: "story",
    url: "/store?type=story",
  },
  {
    icon: require("./images/stories.svg"),
    label: "Other",
    key: "other",
    url: "/store?type=other",
  },
  {
    icon: require("./images/stories.svg"),
    label: "Other",
    key: "other1",
    url: "/store?type=other1",
  },
];
