import React from "react";

import { Icon } from "../../../../../components";

import {
  StyledContainer,
  StyledContent,
  StyledText,
  StyledIcon
} from "./editor-empty-card-styled";

const EditorEmptyCardView = () => {
  return (
    <StyledContainer>
      <StyledContent>
        <StyledIcon as={Icon} src={require("./images/plus.svg")} size={39} />

        <StyledText>Create Project</StyledText>
      </StyledContent>
    </StyledContainer>
  );
};

export default EditorEmptyCardView;
