import { connect } from "react-redux";

import CanvasContentView from "./canvas-content-view";

import {
  canvasSelector,
  itemsSelector,
  scaleSelector,
  selectedItemIdSelector,
  selectedSlideIdSelector,
  selectedSliderIndexesSelector,
  slidesSelector,
  groupedSlidesSelector
} from "../../../../modules/editor/selectors";

import {
  initScale,
  removeItem,
  saveItems,
  saveScale,
  saveSelectedSlideId,
  saveSlides,
  saveselectedItemId
} from "../../../../modules/editor/actions";

const mapStateToProps = state => ({
  // scale: scaleSelector(state),
  items: itemsSelector(state)
  // slides: slidesSelector(state),
  // selectedItemId: selectedItemIdSelector(state),
  // selectedSlideId: selectedSlideIdSelector(state),
  // selectedSliderIndexes: selectedSliderIndexesSelector(state),
  // canvas: canvasSelector(state),
  // groupedSlides: groupedSlidesSelector(state)
});

const mapDispatchToProps = {
  // initScale,
  // saveScale,
  // saveItems,
  // saveSlides,
  // removeItem,
  // saveselectedItemId,
  // saveSelectedSlideId
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CanvasContentView);
