import { StyledContainer, StyledItem } from "./top-line-menu-styled";

import PropTypes from "prop-types";
import React from "react";
import TopLineMenuItem from "./components/item";

const TopLineView = ({ active }) => {
  return (
    <StyledContainer>
      <TopLineMenuItem text="Editor" active={active === "editor"} to="/" />
      <TopLineMenuItem
        text="Gallery"
        active={active === "gallery"}
        to="/gallery"
      />
      <TopLineMenuItem text="Store" active={active === "store"} to="/store" />
    </StyledContainer>
  );
};

TopLineView.propTypes = {
  active: PropTypes.string
};

TopLineView.defaultProps = {
  active: "editor"
};

export default TopLineView;
