import React, { useEffect, useMemo, useState } from "react";
import {
  StyledContainer,
  StyledIcon,
  StyledLabel,
  StyledValue,
} from "./input-styled";

import Icon from "../icon";
import { responsiveSize } from "../../utils/font";

const InputView = ({
  className,
  styleInput,
  styleLabel,
  width = "100%",
  color = "#3E4141",
  value,
  valid = true,
  disabled = false,
  defaultValue,
  placeholder,
  icon,
  iconLeft,
  iconSize = 9,
  min,
  max,
  transformValue,
  label,
  labelLeft,
  type = "string",
  textarea = false,
  rows = 1,
  unit,
  onChange,
  onBlur,
}) => {
  const [localValue, setLocalValue] = useState(
    transformValue ? transformValue(value) : value
  );

  useEffect(() => {
    if (localValue != value)
      setLocalValue(transformValue ? transformValue(value) : value);
  }, [value]);

  const handleChangeValue = (event) => {
    if (!localValue && (defaultValue || value))
      setLocalValue(defaultValue || value);
    if (localValue != value)
      onChange(
        localValue || (!defaultValue && !value)
          ? localValue
          : defaultValue || value
      );
  };

  const handleChange = (value) => {
    if (min != null && max != null) {
      if (value >= min && value <= max)
        setLocalValue(transformValue ? transformValue(value) : value);
    } else setLocalValue(transformValue ? transformValue(value) : value);

    if (onBlur) onChange(transformValue ? transformValue(value) : value);
  };

  return (
    <StyledContainer className={className} valid={valid}>
      {icon && <Icon src={icon} size={iconSize} />}
      {label && <StyledLabel style={styleLabel}>{label}</StyledLabel>}

      <StyledValue
        // as={textarea ? "textarea" : "input"}
        label={label || icon}
        style={{ width, color, ...styleInput }}
        placeholder={placeholder}
        // style={{ marginLeft: label || icon ? 10 : 0 }}
        value={localValue}
        // rows={rows}
        type={type}
        fluid
        disabled={disabled}
        onChange={(event) => handleChange(event.target.value)}
        // onBlur={handleChangeValue}
        onBlur={(value) => {
          if (!onBlur) handleChangeValue();
          else onBlur(value);
        }}
        onKeyDown={(event) => {
          let allowKeys = ["ArrowUp", "ArrowDown", "Enter"];

          if (allowKeys.indexOf(event.key) != -1) handleChangeValue();
        }}
      />

      {iconLeft && <Icon src={iconLeft} size={iconSize} />}
      {labelLeft && <StyledLabel>{labelLeft}</StyledLabel>}
    </StyledContainer>
  );
};

export default InputView;
