import { StyledContainer, StyledTitle } from "./store-projects-header-styles";

import React from "react";
import StoreProjectsHeaderSearch from "./components/search";

const StoreProjectsHeaderView = ({
  className,
  searchStr,
  onChangeSearchStr,
}) => {
  return (
    <StyledContainer className={className}>
      <StyledTitle>All Projects</StyledTitle>

      <StoreProjectsHeaderSearch
        value={searchStr}
        onChange={onChangeSearchStr}
      />
    </StyledContainer>
  );
};

export default StoreProjectsHeaderView;
