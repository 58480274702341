import React, { useState } from "react";

import EditorSidebarItemView from "./editor-sidebar-item-view";

const EditorSidebarItemContainer = props => {
  const [showSubmenu, setShowSubmenu] = useState(false);

  return (
    <EditorSidebarItemView
      {...props}
      showSubmenu={showSubmenu}
      onChangeShowSubmenu={setShowSubmenu}
    />
  );
};

export default EditorSidebarItemContainer;
