import styled from "styled-components";

import { responsiveSize } from "../../../../../../utils/font";

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: ${responsiveSize(12)} 0px ${responsiveSize(24)}; */
`;

export const StyledContent = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${responsiveSize(24)};
`;

export const StyledValue = styled.span`
  width: ${responsiveSize(51)};
  text-align: center;
  line-height: 1;

  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;

  color: #3e4141;

  opacity: 0.5;
`;
