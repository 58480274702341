import { responsiveSize } from "../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.textarea`
  padding: ${responsiveSize(8)} ${responsiveSize(6)};
  width: 100%;

  display: flex;
  align-items: center;

  border-radius: ${responsiveSize(4)};
  border: solid 1px ${(props) => (props.valid ? "#d8d9d9" : "#EE0D29")};

  font-size: ${responsiveSize(12)};

  outline: none;
  resize: none;

  transition: all 0.4s;
`;
