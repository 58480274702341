import {
  itemsSelector,
  selectedItemIdSelector,
  selectedItemSelector,
  selectedSlideIdSelector,
  selectedSliderIndexesSelector,
  slidesSelector
} from "../../../../modules/editor/selectors";
import {
  saveItems,
  saveSlides,
  saveShowEffect
} from "../../../../modules/editor/actions";

import CanvasEffectContainer from "./canvas-effect-container";
import { connect } from "react-redux";
import { logout } from "../../../../modules/auth/actions";

const mapStateToProps = state => ({
  selectedItem: selectedItemSelector(state),
  slides: slidesSelector(state),
  selectedItemId: selectedItemIdSelector(state),
  selectedSlideId: selectedSlideIdSelector(state),
  selectedSliderIndexes: selectedSliderIndexesSelector(state)
});

const mapDispatchToProps = {
  saveShowEffect,
  saveSlides,
  logout
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CanvasEffectContainer);
