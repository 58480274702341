import React from "react";

import EditorControlSettingsView from "./editor-control-settings-view";

const EditorControlSettingsContainer = ({
  saveGroupedSlides,
  saveCanvas,
  ...props
}) => {
  return (
    <EditorControlSettingsView
      {...props}
      onGroupdSlides={saveGroupedSlides}
      onChangeSize={saveCanvas}
    />
  );
};

export default EditorControlSettingsContainer;
