import { responsiveSize } from "../../../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  padding: 0px ${responsiveSize(40)};
  margin-top: ${responsiveSize(32)};

  display: flex;
  /* align-items: center; */
  justify-content: space-between;
`;
