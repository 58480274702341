import Group from "../group";
import Input from "../input";
import React from "react";

const CanvasControlBorderRadiusView = ({style, onChangeItems}) => {
  const handleChange = (value) => {
    onChangeItems({
      key: "style",
      data: {
        transform: `rotate(${+value}deg)`
      }
    });
  }

  return (
    <Group>
      <Input
        icon={require("./images/icon.svg")}
        size="base"
        value={style.transform ? style.transform.slice(7, -4) : 0}
        defaultValue={0}
        valueType="number"
        onChange={handleChange}
      />
    </Group>
  );
};

export default CanvasControlBorderRadiusView;
