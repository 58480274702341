import EditorControlSettingsContainer from "./editor-control-settings-container";

import { connect } from "react-redux";

import {
  groupedSlidesSelector,
  canvasSelector
} from "../../../../../../modules/editor/selectors";

import {
  saveGroupedSlides,
  saveCanvas
} from "../../../../../../modules/editor/actions";

const mapStateToProps = state => ({
  groupedSlides: groupedSlidesSelector(state),
  canvas: canvasSelector(state)
});

const mapDispatchToProps = { saveGroupedSlides, saveCanvas };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorControlSettingsContainer);
