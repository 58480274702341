import { responsiveSize } from "../../../../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  /* flex-direction: column; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${responsiveSize(24)};

  border-bottom: 1px solid #ececec;
`;

export const StyledText = styled.span`
  font-size: ${responsiveSize(16)};

  line-height: 1;
`;

export const StyledActions = styled.div`
  display: flex;
`;

export const StyledIcon = styled.img`
  opacity: ${props => (props.disable ? 0.5 : 1)};

  &:not(:last-child) {
    margin-right: ${responsiveSize(8)};
  }

  transition: all 0.3s;
`;
