import {
  StyledActions,
  StyledContainer,
  StyledIcon,
  StyledText
} from "./editor-control-artboard-styled";

import { Icon } from "../../../../../../components";
import React from "react";

const EditorControlArtboardView = ({
  historyStack,
  activeHistoryStep,
  historyStackLength,
  onChange
}) => {
  return (
    <StyledContainer>
      <StyledText>Artboard Settings</StyledText>
      <pre>{JSON.stringify(activeHistoryStep)}</pre>

      <StyledActions>
        <StyledIcon
          as={Icon}
          src={require("./images/undo.svg")}
          type="button"
          size={26}
          disable={!historyStackLength || activeHistoryStep == 0}
          onClick={() => onChange("prev")}
        />

        <StyledIcon
          as={Icon}
          src={require("./images/redo.svg")}
          type="button"
          size={26}
          disable={
            !historyStackLength || activeHistoryStep >= historyStackLength - 1
          }
          onClick={() => onChange("next")}
        />
      </StyledActions>
    </StyledContainer>
  );
};

export default EditorControlArtboardView;
