import { responsiveSize } from "../../../../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${responsiveSize(96)};

  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    transform: scale(0.85);
  }
`;

export const StyledImage = styled.img`
  width: ${responsiveSize(96)};
  height: ${responsiveSize(96)};

  border-radius: ${responsiveSize(5)};

  border: ${(props) => props.active ? 1 : 0}px solid red;

  /* transition: all 0.5s; */
`;

export const StyledName = styled.div`
  margin-top: ${responsiveSize(4)};

  font-size: ${responsiveSize(12)};
`;
