import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import {
  StyledActiveLine,
  StyledContainer,
  StyledContent,
  StyledText
} from "./top-line-menu-item-styled";

const TopLineView = ({ active, text, to }) => {
  return (
    <StyledContainer as={Link} to={to}>
      <StyledContent active={active}>
        <StyledText>{text}</StyledText>
      </StyledContent>

      <StyledActiveLine active={active} />
    </StyledContainer>
  );
};

export default TopLineView;
