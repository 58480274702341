import { responsiveSize } from "../../../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledBlock = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
`;

export const StyledTitle = styled.h1`
  font-weight: 500;
  font-size: ${responsiveSize(24)};
  /* line-height: 1; */
  font-family: "Roboto";
`;

export const StyledBackContainer = styled.div`
  margin-bottom: ${responsiveSize(8)};

  display: flex;
  align-items: center;

  cursor: pointer;

  opacity: 0.5;
  transition: all 0.6s;

  &:hover {
    transition: all 0.6s;
    opacity: 0.25;
  }
`;

export const StyledBackIcon = styled.div`
  margin-right: ${responsiveSize(8)};
`;

export const StyledBackText = styled.p`
  font-size: ${responsiveSize(16)};
  color: #3e4141;
`;

export const StyledDownloadConatiner = styled.div`
  margin-right: ${responsiveSize(40)};

  display: flex;
  align-items: center;
`;

export const StyledDownloadLabel = styled.p`
  margin-right: ${responsiveSize(24)};

  font-size: ${responsiveSize(20)};
  line-height: ${responsiveSize(23)};
  color: #3e4141;

  opacity: 0.5;
`;

export const StyledDownloadValue = styled.p`
  font-size: ${responsiveSize(20)};
  line-height: ${responsiveSize(23)};

  color: #f71b43;
`;
