import { responsiveSize } from "../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  height: 100%;

  /* margin-top: 35vh; */

  flex: 1;

  ${(props) =>
    props.loading &&
    `display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `};
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  /* width: 100%; */
`;

export const StyledHeader = styled.div`
  padding: 0px ${responsiveSize(40)};

  /* margin-bottom: ${responsiveSize(32)}; */
`;
