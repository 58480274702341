import { responsiveSize } from "../../../../../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  padding: ${responsiveSize(24)} ${responsiveSize(18)};

  // margin-bottom: 20px;

  display: flex;
  justify-content: space-between;
  /* flex-direction: column; */
  /* align-items: flex-start; */

  border-bottom: 1px solid #ececec;
`;

export const StyleHeader = styled.div`
  width: 100%;

  margin: 0;
  margin-bottom: ${responsiveSize(11)};

  display: flex;
  justify-content: space-between;

  opacity: ${props => (props.active ? 1 : 0.5)};
`;

export const StyleContent = styled.div`
  width: 100%;
  /* padding: 0 5px; */
`;

export const StyledTitle = styled.p`
  font-size: ${responsiveSize(12)};
  line-height: ${responsiveSize(14)};
  text-transform: uppercase;
  color: #9fa0a0;
`;

export const StyledSwitch = styled.div`
  margin-left: ${responsiveSize(12)};
`;

export const StyledContent = styled.div`
  /* display: flex; */
  /* flex-wrap: wrap; */
`;
