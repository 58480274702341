import React, { useEffect, useMemo, useState } from "react";

import ModalRegisterView from "./modal-register-view";

const ModalRegisterContainer = (props) => {
  useEffect(() => {
    if (props.isAuthenticated) props.saveAuthShowModal(false);
  }, [props.isAuthenticated]);

  const handlerSubmit = (data, source = "native") => {
    if (source == "facebook") {
      if (data.status == undefined) {
        let names = data.name.split(" ");

        console.log("props.register", {
          name: names[0],
          surname: names[1],
          email: data.email,
          userID: data.userID,
          loginSource: "native",
          password: data.userID,
        });

        props.login({
          name: names[0],
          surname: names[1],
          email: data.email || data.userID,
          facebookId: data.userID,
          loginSource: "facebook",
          password: data.userID,
        });
      }
    } else props.register({ ...data, loginSource: "native", facebookId: null });
  };

  return <ModalRegisterView {...props} onSubmit={handlerSubmit} />;
};

export default ModalRegisterContainer;
