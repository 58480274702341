import { StyledContainer, StyledGroup } from "./top-line-styled";

import Button from "../button";
import PropTypes from "prop-types";
import React from "react";
import TopLineMenu from "./components/menu";

const TopLineView = ({
  activeItem,
  base,
  componentLeft,
  componentRight,
  isAuthenticated,
  onCreateProject,
  onRegister
}) => {
  return (
    <StyledContainer>
      <StyledGroup>{componentLeft}</StyledGroup>

      <StyledGroup>
        {componentRight ? (
          componentRight
        ) : isAuthenticated ? (
          <Button title="Create Project" onClick={onCreateProject} />
        ) : (
          <Button title="REGISTER" onClick={onRegister} />
        )}
      </StyledGroup>
    </StyledContainer>
  );
};

export default TopLineView;

TopLineMenu.propTypes = {
  base: PropTypes.bool
};

TopLineMenu.defaultProps = {
  base: false
};
