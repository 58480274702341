import { loadGalleryData, loadStoreData } from "../../modules/project/actions";
import { showModalSelector, tokenSelector } from "../../modules/auth/selectors";

import RootContainer from "./root-container";
import { connect } from "react-redux";
import { saveAuthShowModal } from "../../modules/auth/actions";

const mapStateToProps = (state) => ({
  showModal: showModalSelector(state),
  token: tokenSelector(state),
});

const mapDispatchToProps = {
  loadGalleryData,
  loadStoreData,
  saveAuthShowModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(RootContainer);
