import {
  activeHistoryStepSelector,
  canvasSelector,
  historyStackSelector,
  slidesSelector
} from "../../../../../../modules/editor/selectors";
import {
  saveActiveHistoryStep,
  saveCanvas,
  saveHistoryStack,
  saveSlides
} from "../../../../../../modules/editor/actions";

import EditorContentArtboardContainer from "./editor-control-artboard-container";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  canvas: canvasSelector(state),
  slides: slidesSelector(state),
  historyStack: historyStackSelector(state),
  activeHistoryStep: activeHistoryStepSelector(state),
});

const mapDispatchToProps = {
  saveSlides,
  saveCanvas,
  saveHistoryStack,
  saveActiveHistoryStep
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorContentArtboardContainer);
