import styled from "styled-components";

import { responsiveSize } from "../../utils/font";

export const StyledContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: ${responsiveSize(36)};
  height: ${responsiveSize(18)};
  padding: 0px ${responsiveSize(2)};

  background-color: ${props => (props.active ? "#ee0d29" : "#C5C6C6")};
  border-radius: ${responsiveSize(31)};

  cursor: pointer;
  transition: all 0.6s;
`;

export const StyledIndificator = styled.div`
  width: ${responsiveSize(14)};
  height: ${responsiveSize(14)};

  border-radius: 50%;
  background-color: ${props => (props.active ? "#ffffff" : "#5A5C5C")};

  transform: translateX(
    ${props => (props.active ? responsiveSize(18) : responsiveSize(0))}
  );
  transition: all 0.6s;
`;
