export const items = [
  {
    icon: require("./images/search.svg"),
    label: "Search",
    type: "search"
  },
  {
    icon: require("./images/recent.svg"),
    label: "Recent files",
    type: "recent-files"
  },
  {
    icon: require("./images/all-files.svg"),
    label: "All Files",
    type: "all-files"
  },
  {
    icon: require("./images/draft.svg"),
    label: "Draft",
    type: "draft"
  }
];
