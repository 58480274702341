import { ColorPicker } from "../../../../../../../../components";
import ContentControlGroup from "../group";
import React from "react";

const ContentControlFontStyleView = ({style, onChangeItems}) => {
  const handleChange = (value) => {
    onChangeItems({
      key: "style",
      data: {
        backgroundColor: value 
      }
    });
  }

  return (
    <ContentControlGroup>
      <ColorPicker value={style.backgroundColor} showInfo={true} onChange={handleChange} />
    </ContentControlGroup>
  );
};

export default ContentControlFontStyleView;
