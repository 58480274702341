import * as Actions from "./constants";

import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  canvasSelector,
  itemsSelector,
  selectedSliderIndexesSelector,
  slidesSelector
} from "./selectors";
import { loadEditorData, uploadTemplate } from "./service";

function* loadEditorDataSaga({ payload }) {
  try {
    const res = yield call(loadEditorData, payload.id);

    console.log(res);

    yield put({ type: Actions.LOAD_EDITOR_DATA_SUCCESS, payload: res.data });
  } catch (e) {
    console.log(e);
    yield put({ type: Actions.LOAD_EDITOR_DATA_ERROR });
  }
}

function* addSideSaga({ payload: { type, position } }) {
  const slides = yield select(slidesSelector);
  const selectedSliderIndexes = yield select(selectedSliderIndexesSelector);

  let newSliders = JSON.parse(JSON.stringify(slides));

  const activeRow = slides[selectedSliderIndexes.row];

  if (type === "column") {
    let indexNewColumn =
      selectedSliderIndexes.column + (position === "left" ? -1 : 1);

    if (indexNewColumn < 0) indexNewColumn = 0;

    newSliders = newSliders.map(row => {
      const newIdColumn = Math.max(...activeRow.data.map(item => +item.id)) + 1;

      row.data.splice(indexNewColumn, 0, {
        items: {},
        canvas: {},
        id: newIdColumn
      });

      return row;
    });
  } else {
    const newIdRow = Math.max(...slides.map(item => +item.id)) + 1;

    let indexNewRow = selectedSliderIndexes.row + (position === "up" ? -1 : 1);

    if (indexNewRow < 0) indexNewRow = 0;

    newSliders.splice(indexNewRow, 0, {
      id: newIdRow,
      data: activeRow.data.map((item, index) => ({
        items: {},
        canvas: {},
        id: index + 1
      }))
    });
  }

  yield put({
    type: Actions.SAVE_SLIDES,
    payload: newSliders
  });
}

function* removeSideSaga({ payload: { type } }) {
  const slides = yield select(slidesSelector);
  const selectedSliderIndexes = yield select(selectedSliderIndexesSelector);

  let newSliders = [...slides];

  if (type === "column") {
    newSliders = newSliders.map(row => {
      row.data.splice(selectedSliderIndexes.column, 1);

      return row;
    });

    yield put({
      type: Actions.SAVE_SELECTED_SLIDE_ID,
      payload: `${newSliders[0].id}/${newSliders[0].data[0].id}`
    });
  } else {
    newSliders.splice(selectedSliderIndexes.row, 1);

    if (!newSliders.length) {
      newSliders = [
        {
          id: 1,
          data: [
            {
              id: 1,
              items: {},
              canvas: {}
            }
          ]
        }
      ];

      yield put({
        type: Actions.SAVE_SELECTED_SLIDE_ID,
        payload: "1/1"
      });
    } else {
      yield put({
        type: Actions.SAVE_SELECTED_SLIDE_ID,
        payload: `${newSliders[0].id}/${newSliders[0].data[0].id}`
      });
    }
  }

  yield put({
    type: Actions.SAVE_SLIDES,
    payload: newSliders
  });
}

function* cleanSideSaga() {
  const slides = yield select(slidesSelector);
  const selectedSliderIndexes = yield select(selectedSliderIndexesSelector);

  let newSliders = [...slides];

  console.log("selectedSliderIndexes", selectedSliderIndexes);

  const item =
    newSliders[selectedSliderIndexes.row].data[selectedSliderIndexes.column];

  item.items = {};
  item.canvas = {};

  yield put({
    type: Actions.SAVE_SLIDES,
    payload: newSliders
  });
}

function* uploadTemplateSaga({ payload }) {
  try {
    console.log("uploadTemplateSaga", payload);
    // const token =/
    const canvas = yield select(canvasSelector);
    const slides = [...(yield select(slidesSelector))];
    let formData = new FormData();

    for (let row of slides) {
      for (let column of row.data) {
        for (let item of Object.values(column.items)) {
          if (item && item.type == "image" && item.file) {
            formData.append(`image${item.id}`, item.file);
            console.log("ormData.image", item.file);
          }
        }
      }
    }

    for (let key in payload.preview) {
      let item = payload.preview[key];

      if (item && item.file) {
        console.log("ormData.preview", item.file);
        formData.append(`preview${key}`, item.file);
      }
    }

    formData.append("template", JSON.stringify({ canvas, slides }));
    formData.append("preview", JSON.stringify(payload.preview));
    formData.append("category", payload.category);
    formData.append("description", payload.description);
    formData.append("name", payload.name);
    formData.append("tags", JSON.stringify(payload.tags.split(" ")));

    // return

    const res = yield call(uploadTemplate, {
      form: formData,
      id: payload.id
      // token: payload.token,
    });

    // console.log("res", res);

    // yield put(loadGalleryData);

    // const yield call(uploadTemplate);
    // yield call(uploadTemplate);

    // yield put({
    //   type: Actions.UPLOAD_TEMPLATE_SUCCESS,
    //   payload:
    // });

    yield put({
      type: Actions.UPLOAD_TEMPLATE_SUCCESS,
      payload: {
        canvas: res.data.template.canvas,
        slides: res.data.template.slides
      }
    });
  } catch (e) {
    console.log(e);
    yield put({ type: Actions.UPLOAD_TEMPLATE_ERROR });
  }
}

export default function* iapSaga() {
  yield takeEvery(Actions.LOAD_EDITOR_DATA, loadEditorDataSaga);
  yield takeEvery(Actions.UPLOAD_TEMPLATE, uploadTemplateSaga);
  yield takeEvery(Actions.ADD_SLIDE, addSideSaga);
  yield takeEvery(Actions.REMOVE_SLIDE, removeSideSaga);
  yield takeEvery(Actions.CLEAN_SLIDE, cleanSideSaga);
}
