import { createSelector } from "reselect";

export const rootCommon = (state) => state.project;

export const storeSelector = createSelector(rootCommon, (data) => data.store);
export const gallerySelector = createSelector(
  rootCommon,
  (data) => data.gallery
);
export const projectSelector = createSelector(
  rootCommon,
  (data) => data.project
);
export const createProjectLoadingSelector = createSelector(
  rootCommon,
  (data) => data.createProjectLoading
);
