import { responsiveSize } from "../../../../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  border: 1px solid #fff;
`;

export const StyledIcon = styled.img`
  width: ${responsiveSize(16)};
  height: ${responsiveSize(16)};

  margin: 0 ${responsiveSize(16)};
`;
