import React, { useState } from "react";

import ToggleView from "./toggle-view";

const ToggleContainer = props => {
  const [active, setActive] = useState(props.value || false);

  const handlerChange = () => {
    setActive(!active);
    props.onChange && props.onChange(!active);
  };

  return <ToggleView {...props} active={active} onChange={handlerChange} />;
};

export default ToggleContainer;
