import React from "react";
import { Sidebar } from "../../../../components";
import { items } from "./store-sidebar-config";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

const GallerySidebarView = () => {
  const location = useLocation();
  const query = queryString.parse(location.search);

  return <Sidebar items={items} selectedItem={query.type} />;
};

export default GallerySidebarView;
