import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import StoreVew from "./store-view";

const StoreContainer = (props) => {
  const [active, setActive] = useState("projects");
  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleRegister = () => {
    console.log("handleRegister");
    setShowLoginModal(!showLoginModal);
  };

  const handleChangeActive = ({ value, id }) => {
    setActive(value);

    // if (id) window.location.href = "/";
  };

  return (
    <StoreVew
      {...props}
      active={active}
      showLoginModal={showLoginModal}
      onRegister={handleRegister}
      onChangeShowLoginModal={setShowLoginModal}
      onChangeActive={handleChangeActive}
    />
  );
};

export default StoreContainer;
