import React from "react";
import { StyledInput } from "./store-projects-header-search-styled";

const StoreProjectsHeaderSearchView = ({ value, onChange }) => {
  return (
    <StyledInput
      value={value}
      onChange={(event) => {
        onChange(event.target.value);
      }}
      placeholder="Search"
      icon={require("./images/search.svg")}
    />
  );
};

export default StoreProjectsHeaderSearchView;
