import styled from "styled-components";

import { responsiveSize } from "../../../../../../utils/font";

export const StyledContainer = styled.div`
  width: ${responsiveSize(122)};

  position: relative;

  cursor: pointer;
`;

export const StyledContent = styled.div`
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: ${props => (props.active ? 1 : 0.6)};
  transition: all 0.3s;

  &:hover {
    transition: all 0.3s;

    opacity: 1;
    /* transform: scale(1.1); */
  }
`;

export const StyledText = styled.span`
  font-size: ${responsiveSize(16)};
  line-height: ${responsiveSize(19)};

  color: #3e4141;
`;

export const StyledActiveLine = styled.div`
  /* width: ${props => (props.active ? "100%" : "0%")}; */
  width: 100%;
  height: 2px;

  position: absolute;
  bottom: -1px;

  opacity: ${props => (props.active ? 1 : 0)};

  background-color: #f71b43;

  transition: all 0.5s;
`;
