import { createSelector } from "reselect";

export const rootCommon = (state) => state.auth;

export const userSelector = createSelector(rootCommon, (data) => data.user);
export const showModalSelector = createSelector(
  rootCommon,
  (data) => data.showModal
);
export const tokenSelector = createSelector(rootCommon, (data) => data.token);
export const isAuthenticatedSelector = createSelector(
  rootCommon,
  (data) => data.isAuthenticated
);
export const loadingSelector = createSelector(
  rootCommon,
  (data) => data.loading
);

export const forgotStatusSelector = createSelector(
  rootCommon,
  (data) => data.forgotStatus
);

export const resetPasswordSelector = createSelector(
  rootCommon,
  (data) => data.resetPassword
);
