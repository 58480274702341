import { ColorPicker, Icon } from "../../../../../../../../components";
import {
  StyledColorPicker,
  StyledInput
} from "./content-control-shadow-styled";

import ContentControlGroup from "../group";
import ContentControlInput from "../input";
import React from "react";

const ContentControlShadowView = ({
  style,
  show,
  onChange,
  onAdd,
  onRemove
}) => {
  return (
    <ContentControlGroup
      placholder="Shadow"
      showContent={show}
      onShow={onAdd}
      onHide={onRemove}
    >
      <ColorPicker
        value={style.shadowColor}
        blur
        defaultValue="rgba(0,0,0,0.25)"
        onChange={value => onChange({ key: "shadowColor", value })}
      />

      <StyledInput
        as={ContentControlInput}
        value={style.shadowOffset ? style.shadowOffset.width : 5}
        valueType="number"
        size="small"
        iconSize={{ width: 8, height: 16 }}
        icon={require("./images/x.svg")}
        onChange={value =>
          onChange({
            key: "shadowOffset",
            value: { ...style.shadowOffset, width: +value }
          })
        }
        // IconComponent={<Icon src={require("./images/x.svg")} size={14} />}
      />
      <StyledInput
        as={ContentControlInput}
        value={style.shadowOffset ? style.shadowOffset.height : 10}
        valueType="number"
        size="small"
        iconSize={{ width: 8, height: 16 }}
        icon={require("./images/y.svg")}
        onChange={value =>
          onChange({
            key: "shadowOffset",
            value: { ...style.shadowOffset, height: +value }
          })
        }
        // IconComponent={<Icon src={require("./images/y.svg")} size={14} />}
      />
      <StyledInput
        as={ContentControlInput}
        value={style.shadowRadius}
        valueType="number"
        size="small"
        iconSize={16}
        icon={require("./images/blur.svg")}
        onChange={value =>
          onChange({
            key: "shadowRadius",
            value: +value
          })
        }
      />
    </ContentControlGroup>
  );
};

export default ContentControlShadowView;
