import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import RootVew from "./root-view";
import queryString from "query-string";
import { setHeader } from "../../utils/request";

const RootContainer = (props) => {
  const location = useLocation();
  const query = queryString.parse(location.search);

  useEffect(() => {
    if (props.token) {
      setHeader(props.token);

      props.loadGalleryData();
    }

    props.loadStoreData();
  }, [props.token]);

  // useEffect(() => {
  //   console.log("query.type;", query.modal);
  //   props.saveAuthShowModal(query.modal);
  // }, [query]);

  return (
    <RootVew
      {...props}
      onChangeShowModal={() => props.saveAuthShowModal(!props.showModal)}
    />
  );
};

export default RootContainer;
