import styled from "styled-components";

import { responsiveSize } from "../../utils/font";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;

  border: none;
  outline: none;

  overflow: hidden;
`;

export const StyledHeader = styled.div`
  width: 100%;
  /* padding-left: ${responsiveSize(120)}; */
`;

export const StyledBody = styled.div`
  display: flex;
  height: 100%;
  /* padding-left: ${responsiveSize(120)}; */
`;

export const StyledWrapper = styled.div`
  flex: 1;

  overflow: hidden;
`;

export const StyledControl = styled.div`
  margin-left: auto;
  height: 100%;
  display: flex;
`;

export const StyledSidebar = styled.div`
  /* position: absolute;
  top: 0px;
  left: 0px; */
`;

export const StyledEffect = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: ${responsiveSize(255)};

  z-index: 99;
`;
