import { StyledContainer, StyledContent, Styledbody } from "./page-styled";

import React from "react";

const PageView = ({ SidebarComponent, TopLineComponent, ContentComponent }) => {
  return (
    <StyledContainer>
      {SidebarComponent}

      <Styledbody>
        {TopLineComponent}

        <StyledContent>{ContentComponent}</StyledContent>
      </Styledbody>
    </StyledContainer>
  );
};

export default PageView;
