import React from "react";

import { Sidebar } from "../../../../components";

import { items } from "./gallery-sidebar-config";

const GallerySidebarView = () => {
  return <Sidebar items={items} />;
};

export default GallerySidebarView;
