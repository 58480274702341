import React, { useMemo, useState } from "react";

import EditorHeaderExportView from "./editor-header-export-view";

import domtoimage from "dom-to-image";

import { saveAs } from "file-saver";

const EditorHeaderExportContainer = props => {
  const [showModalExport, setShowModalExport] = useState(false);

  const handlerExport = () => {
    const node = document.getElementById("canvas-content");

    domtoimage.toBlob(node).then(function(blob) {
      saveAs(blob, "my-node.png");
    });
  };

  return (
    <EditorHeaderExportView
      {...props}
      showModalExport={showModalExport}
      onChangeShowModalExport={setShowModalExport}
      onExport={handlerExport}
    />
  );
};

export default EditorHeaderExportContainer;
