import React, { useEffect, useState, useMemo } from "react";

import EditorSettingsTypeView from "./editor-settings-type-view";

import { sizes } from "../../../../../../../../config/sizes";

const items = {
  vertical: {
    size: `${sizes.vertical.width}x${sizes.vertical.height}`,
    description: "Instagram Post 4:5",
    image: require("./images/vertical.png"),
    type: "vertical",
    width: "10%"
  },
  horizontal: {
    size: `${sizes.horizontal.width}x${sizes.horizontal.height}`,
    description: "Instagram Post 5:4",
    image: require("./images/horisontal.png"),
    type: "horizontal",
    width: "15%"
  },
  square: {
    size: `${sizes.square.width}x${sizes.square.height}`,
    description: "Instagram Post 1:1",
    image: require("./images/square.png"),
    type: "square",
    width: "10%"
  },

  custom: {
    size: `1000x1000`,
    description: "Custom",
    image: require("./images/square.png"),
    type: "custom",
    width: "10%"
  }
  // story: {
  //   size: `${sizes.story.width}x${sizes.story.height}`,
  //   description: "Instagram Story 9:16",
  //   image: require("./images/story.png"),
  //   type: "story",
  //   width: "10%"
  // }
};

const EditorSettingsTypeContainer = props => {
  const [active, setActive] = useState(false);

  // const render

  const handleChange = type => {
    props.saveCanvas({
      type,
      width: sizes[type].width,
      height: sizes[type].height
    });
    setActive(false);
  };

  return (
    <EditorSettingsTypeView
      {...props}
      items={items}
      active={active}
      onChange={handleChange}
      onChangeActive={setActive}
    />
  );
};

export default EditorSettingsTypeContainer;
