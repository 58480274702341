import { responsiveSize } from "../../../../../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  /* width: ${responsiveSize(265)}; */
  padding-left: ${responsiveSize(40)};

  display: flex;
  flex-direction: column;
  flex: 1;

  border-left: 1px solid rgba(62, 65, 65, 0.1);
`;

export const StyledItem = styled.div`
  margin-bottom: ${responsiveSize(32)};
`;

export const StyledTitle = styled.h3`
  margin-bottom: ${responsiveSize(16)};

  font-weight: 500;
  font-size: ${responsiveSize(20)};
  line-height: ${responsiveSize(22)};
`;

export const StyledTagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledTagItem = styled.div`
  padding: ${responsiveSize(4)} ${responsiveSize(12)};
  margin-bottom: ${responsiveSize(12)};
  margin-right: ${responsiveSize(12)};

  font-weight: 500;
  font-size: ${responsiveSize(16)};
  line-height: ${responsiveSize(19)};

  border: 1px solid rgba(62, 65, 65, 0.5);
  border-radius: 4px;
`;

export const StyledPublisherContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledPublisherLabel = styled.p`
  font-size: ${responsiveSize(16)};
  line-height: ${responsiveSize(22)};
`;

export const StyledPublisherIcon = styled.div`
  opacity: 0.5;
`;
