export const SAVE_AUTH_TOKEN = "auth/SAVE_AUTH_TOKEN";
export const SAVE_AUTH_USER_DATA = "auth/SAVE_AUTH_USER_DATA";

export const LOGIN = "auth/LOGIN";
export const LOGIN_SUCCESS = "auth/LOGIN_SUCCESS";
export const LOGIN_ERROR = "auth/LOGIN_ERROR";

export const REGISTER = "auth/REGISTER";
export const REGISTER_SUCCESS = "auth/REGISTER_SUCCESS";
export const REGISTER_ERROR = "auth/REGISTER_ERROR";

export const FORGOT = "auth/FORGOT";
export const FORGOT_SUCCESS = "auth/FORGOT_SUCCESS";
export const FORGOT_ERROR = "auth/FORGOT_ERROR";

export const LOGOUT = "auth/LOGOUT";

export const RESET_PASSWORD = "auth/RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "auth/RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "auth/RESET_PASSWORD_ERROR";

export const SAVE_AUTH_SHOW_MODAL = "auth/SAVE_AUTH_SHOW_MODAL";
