import styled from "styled-components";

import { responsiveSize } from "../../../../../../../../utils/font";

export const StyledInput = styled.input`
  width: ${responsiveSize(29)};
  height: ${responsiveSize(26)};
  padding: ${responsiveSize(6)};

  border: 1px solid #d8d9d9;
  border-radius: ${responsiveSize(4)};

  font-size: ${responsiveSize(12)};
  line-height: ${responsiveSize(14)};
  text-align: center;
`;

export const StyledSlider = styled.div`
  width: ${responsiveSize(85)};
  padding: 0px ${responsiveSize(12)};
`;
