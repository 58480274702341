export const items = [
  {
    id: 1,
    name: "Template_1_Edit",
    preview: require("./images/1.jpg"),
    price: 24,
    createdBy: "Username",
    downloads: 3468,
    user: {
      name: "Name",
      surname: "Surname"
    },
    tags: ["tag1", "tag2"],
    versions: [
      {
        date: "06.09.2020",
        description: "Description"
      }
    ]
  },

  {
    id: 2,
    name: "Template_2_Edit",
    preview: require("./images/2.jpg"),
    price: 0,
    createdBy: "Username",
    downloads: 3468,
    user: {
      name: "Name",
      surname: "Surname"
    },
    tags: ["tag1", "tag2"],
    versions: [
      {
        date: "06.09.2020",
        description: "Description"
      }
    ]
  },

  {
    id: 3,
    name: "Template_3_Edit",
    preview: require("./images/3.jpg"),
    price: 35,
    createdBy: "Username",
    downloads: 3468,
    user: {
      name: "Name",
      surname: "Surname"
    },
    tags: ["tag1", "tag2"],
    versions: [
      {
        date: "06.09.2020",
        description: "Description"
      }
    ]
  },

  {
    id: 4,
    name: "Template_4_Edit",
    preview: require("./images/4.jpg"),
    price: 17,
    createdBy: "Username",
    downloads: 3468,
    user: {
      name: "Name",
      surname: "Surname"
    },
    tags: ["tag1", "tag2"],
    versions: [
      {
        date: "06.09.2020",
        description: "Description"
      }
    ]
  },

  {
    id: 5,
    name: "Template_5_Edit",
    preview: require("./images/5.jpg"),
    price: 0,
    createdBy: "Username",
    downloads: 3468,
    user: {
      name: "Name",
      surname: "Surname"
    },
    tags: ["tag1", "tag2"],
    versions: [
      {
        date: "06.09.2020",
        description: "Description"
      }
    ]
  },

  {
    id: 6,
    name: "Template_6_Edit",
    preview: require("./images/6.jpg"),
    price: 24,
    createdBy: "Username",
    downloads: 3468,
    user: {
      name: "Name",
      surname: "Surname"
    },
    tags: ["tag1", "tag2"],
    versions: [
      {
        date: "06.09.2020",
        description: "Description"
      }
    ]
  }
];
