import {
  StyledContainer,
  StyledItem,
  StyledPublisherContainer,
  StyledPublisherIcon,
  StyledPublisherLabel,
  StyledTagContainer,
  StyledTagItem,
  StyledTitle,
} from "./store-template-body-info-styled";

import { Icon } from "../../../../../../../components";
import React from "react";

const StoreTemplateBodyInfoView = ({ data }) => {
  return (
    <StyledContainer>
      <StyledItem>
        <StyledTitle>Publisher</StyledTitle>

        <StyledPublisherContainer>
          <StyledPublisherLabel>
            {data.user.name} {data.user.surname}
          </StyledPublisherLabel>

          <StyledPublisherIcon as={Icon} name="arror-right" />
        </StyledPublisherContainer>
      </StyledItem>

      <StyledItem>
        <StyledTitle>Tags</StyledTitle>

        <StyledTagContainer>
          {data.tags.map((item) => (
            <StyledTagItem>{item}</StyledTagItem>
          ))}
        </StyledTagContainer>
      </StyledItem>
    </StyledContainer>
  );
};

export default StoreTemplateBodyInfoView;
