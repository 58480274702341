import React, { useMemo } from "react";
import {
  SrtledIcon,
  StyledContainer,
  StyledContent,
  StyledInfo,
  StyledMeta,
  StyledMetaItem,
  StyledMetaLabel,
  StyledName,
  StyledPreview,
  StyledValue
} from "./grid-card-file-styled";

import Icon from "../../../icon";
import { Link } from "react-router-dom";
import MouseEffect from "../../../mouse-effect";
import { animated } from "react-spring";
import moment from "moment";

const StoreProjectsGridCardView = ({ data, animateStyle }) => {
  const createdAt = useMemo(() => {
    return moment(data.created_at).format("DD.MM.YYYY");
  });

  return (
    <MouseEffect>
      <StyledContainer as={animated.div} style={animateStyle}>
        <Link to={`/canvas/${data.id}`}>
          <StyledPreview image={Object.values(data.preview)[0].src} />
        </Link>

        <StyledContent>
          <StyledInfo>
            <StyledName>{data.name}</StyledName>

            <SrtledIcon as={Icon} button src={require("./images/dots.svg")} />
          </StyledInfo>

          <StyledMeta>
            <StyledMetaItem>
              <StyledMetaLabel>Edited</StyledMetaLabel>

              <StyledValue>
                {data.user.name} {data.user.surname}
              </StyledValue>
            </StyledMetaItem>

            <StyledMetaItem>
              <StyledMetaLabel>Created</StyledMetaLabel>

              <StyledValue>{createdAt}</StyledValue>
            </StyledMetaItem>
          </StyledMeta>
        </StyledContent>
      </StyledContainer>
    </MouseEffect>
  );
};

export default StoreProjectsGridCardView;
