import styled from "styled-components";

import { responsiveSize } from "../../../../utils/font";

export const StyledContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  /* flex-direction: row; */
`;

export const StyledItem = styled.div`
  width: ${responsiveSize(122)};

  display: flex;
  justify-content: center;
  align-items: center;
`;
