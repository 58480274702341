import { responsiveSize } from "../../../../../../../../../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  margin-bottom: ${responsiveSize(4)};
`;

export const StyledImageContainer = styled.div`
  width: ${responsiveSize(158)};
  height: ${responsiveSize(108)};

  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: ${responsiveSize(20)};
  margin-bottom: ${responsiveSize(20)};
  /* padding: ${responsiveSize(2)}; */

  border: ${responsiveSize(2)} solid #f71b43;
  border-radius: ${responsiveSize(4)};
`;

export const StyledImage = styled.img`
  /* width: ${responsiveSize(150)};
  height: ${responsiveSize(100)}; */

  width: 100%;
  height: 100%;

  padding: ${responsiveSize(2)};

  border-radius: ${responsiveSize(4)};
`;

export const StyledUpload = styled.img`
  width: ${responsiveSize(150)};
  height: ${responsiveSize(100)};

  margin-bottom: ${responsiveSize(20)};

  border-radius: ${responsiveSize(4)};

  border: solid 1px ${(props) => (props.valid ? "#fff" : "#EE0D29")};

  cursor: pointer;
  transition: all 0.4s;

  &:hover {
    opacity: 0.6;
    transition: all 0.3s;

    transform: scale(1.05);
  }
`;
