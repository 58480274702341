import React, { useEffect, useState } from "react";
import { containerStyle, elements } from "../../../../config/elements";
import { useLocation, useParams } from "react-router-dom";

import EditorHeaderView from "./editor-header-view";

const EditorHeaderContainer = ({
  defaultScale,
  scale,
  items,
  upload,
  saveItems,
  saveScale,
  uploadTemplate,
}) => {
  let { id } = useParams();
  let query = new URLSearchParams(useLocation().search);

  const handleAddItem = ({ type, value }) => {
    console.log("handleAddItem");

    let res = { ...items };
    let length = Object.keys(res).length;
    let nextId = length != 0 ? +Object.keys(res)[length - 1] + 1 : 1;

    res[nextId] = {
      id: nextId,
      type,
      containerStyle: containerStyle,
      style: elements[type].style,
      value: "hello2",
    };

    if (elements[type].uploader) res[nextId].uploader = elements[type].uploader;

    if (type == "image") {
      res[nextId].src = value.url;
      res[nextId].file = value.file;
    } else {
      res[nextId].value = "text";
    }

    saveItems(res);
  };

  const handlerUpload = () => {
    uploadTemplate({ token: query.get("token"), id });
  };

  return (
    <EditorHeaderView
      defaultScale={defaultScale}
      scale={scale}
      upload={upload}
      onAddItem={handleAddItem}
      onChangeScale={saveScale}
      onUpload={handlerUpload}
    />
  );
};

export default EditorHeaderContainer;
