export const baseSize = 270;

export const sizes = {
  vertical: {
    width: 1080,
    height: 1920
  },

  horizontal: {
    width: 1080,
    height: 1350
  },

  square: {
    width: 1080,
    height: 1080
  },

  custom: {
    width: 1000,
    height: 1000
  }
};
