import { StyledContainer, StyledItem } from "./grid-styled";
import { animated, useSpring, useSprings } from "react-spring";

import CardFile from "./components/card-file";
import CardPrice from "./components/card";
import CardTemplate from "./components/card-template";
import PropTypes from "prop-types";
import React from "react";

const StoreProjectsGridView = ({
  items,
  type,
  flex,
  EmptyCard,
  onSelectItem
}) => {
  const springs = useSprings(
    items.length,
    items.map((item, i) => ({
      opacity: 1,
      transform: `perspective(1000px) scale(1) `,
      from: {
        opacity: 0,
        transform: `perspective(1000px) scale(0)`
      },
      delay: (i + 1) * 200
    }))
  );

  const emptyCardStyle = useSpring({
    to: { opacity: 1, transform: `perspective(1000px) scale(1) ` },
    from: { opacity: 0, transform: `perspective(1000px) scale(0)` },
    delay: items.length * 150
  });

  return (
    <StyledContainer>
      {springs.map((prop, i) => {
        return (
          <StyledItem
            flex={flex}
            key={items[i].id}
            onClick={() => onSelectItem({ id: items[i].id, data: items[i] })}
          >
            {/* <animated.div key={i} style={prop}> */}
            {type === "file" && (
              <CardFile data={items[i]} animateStyle={prop} />
            )}
            {type === "buy" && (
              <CardPrice data={items[i]} animateStyle={prop} />
            )}
            {type === "template" && (
              <CardTemplate data={items[i]} animateStyle={prop} />
            )}
            {/* </animated.div> */}
          </StyledItem>
        );
      })}

      {EmptyCard && (
        <StyledItem flex={flex}>
          <animated.div style={emptyCardStyle}>{EmptyCard}</animated.div>
        </StyledItem>
      )}
    </StyledContainer>
  );
};

StoreProjectsGridView.propTypes = {
  items: PropTypes.array,
  type: PropTypes.string,
  // type: PropTypes.oneOfType(["file", "buy"]),
  flex: PropTypes.number,
  onSelectItem: PropTypes.func
};

StoreProjectsGridView.defaultProps = {
  items: [],
  type: "file",
  flex: 3,
  onSelectItem: () => {}
};

export default StoreProjectsGridView;
