import React from "react";

import {
  StyledContainer,
  StyledItem,
  StyledText
} from "./store-projects-sort-tabs-styles";

const StoreProjectsSortTabsView = () => {
  return (
    <StyledContainer>
      <StyledItem active>
        <StyledText>Featured</StyledText>
      </StyledItem>

      <StyledItem>
        <StyledText>Summer Sale</StyledText>
      </StyledItem>

      <StyledItem>
        <StyledText>New Releases</StyledText>
      </StyledItem>
    </StyledContainer>
  );
};

export default StoreProjectsSortTabsView;
