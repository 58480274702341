import { responsiveSize } from "../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  /* padding: 0 5px; */
  /* width: 100%; */

  /* flex: 1; */
  display: flex;
  align-items: center;

  position: relative;

  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  /* border-radius: 3px; */
`;

export const StyledColorContainer = styled.div`
  width: ${props => responsiveSize(props.blur ? 32 : 25)};
  height: ${props => responsiveSize(props.blur ? 32 : 25)};

  padding: ${props => responsiveSize(props.blur ? 5 : 3)};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  position: relative;

  border: ${props =>
    `1px solid ${props.type == "circle" ? "#d8d9d9" : `${props.color}`}`};
  border-radius: ${props =>
    props.type == "circle" ? "50%" : responsiveSize(8)};
  background-color: #fff;

  box-sizing: border-box;
  cursor: pointer;
`;

export const StyledColor = styled.div`
  /* margin: ${responsiveSize(2)}; */

  width: 100%;
  height: 100%;
  
  /* width: ${responsiveSize(18)};
  height: ${responsiveSize(18)}; */

  /* position: absolute; */


  border-radius: ${props =>
    props.type == "circle" ? "50%" : responsiveSize(4)};
  background-color: ${props => `${props.color}`};

  flex-shrink: 0;

  filter: ${props => `blur(${responsiveSize(props.blur ? 3 : 0)})`};

  /* filter: blur(${80 / 100}px); */
`;

export const StyledValue = styled.input`
  // margin-left: 10px;
  width: 100%;

  /* border: none; */

  line-height: 13px;
  font-size: 11px;

  &:hover,
  &:focus {
    /* outline: none;
    border: none; */
  }
`;

export const StyledColorBlurContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${responsiveSize(32)};
  height: ${responsiveSize(32)};

  border: 1px solid #d8d9d9;
  border-radius: 50%;
  background-color: #fff;

  box-sizing: border-box;
  cursor: pointer;
`;

export const StyledColorBlur = styled.div`
  width: ${responsiveSize(23)};
  height: ${responsiveSize(23)};

  border-radius: 50%;
  background-color: ${props => `${props.color}`};
  filter: blur(${responsiveSize(3)});

  overflow: hidden;
`;

export const StyledColorInput = styled.div`
  margin-left: ${responsiveSize(12)};
  margin-right: ${responsiveSize(9)};
  width: 66px;
  /* border-right: 1px solid rgba(0, 0, 0, 0.1); */
`;

export const StyledSwitch = styled.div`
  margin-left: ${responsiveSize(12)};
`;

export const StyledColorPicker = styled.div`
  /* padding: 10px; */
  /* margin-top: ${responsiveSize(15)}; */
  /* width: ${props => (props.show ? responsiveSize(280) : 0)}; */

  /* display: ${props => (props.show ? "flex" : "none")}; */

  position: absolute;
  top: ${responsiveSize(40)};
  /* left: ${responsiveSize(-220)}; */

  /* overflow: hidden; */

  z-index: 2;

  perspective: 850;
  transition: all 0.5s;
  transform: ${(props) => `scale(${props.active ? 1 : 0})`};
  opacity: ${(props) => props.active ? 1 : 0};
`;

export const StyledInfo = styled.div`
  display: flex;
`;