import {
  StyledContainer,
  StyledImage,
  StyledImageContainer,
  StyledUpload,
} from "./canvas-header-modal-publishing-preview-styles";

import { FileLoader } from "../../../../../../../../../../../components";
import React from "react";
import { Transition } from "react-spring/renderprops";

const CanvasHeaderModalPublishingPreviewView = ({
  valid,
  preview,
  onChange,
}) => {
  return (
    <StyledContainer>
      
      <Transition
        items={Object.values(preview)}
        keys={(item) => item.url || item.src}
        from={{ opacity: 0, transform: "scale(0)" }}
        enter={{ opacity: 1, transform: "scale(1)" }}
        leave={{ opacity: 0, transform: "scale(0)" }}
      >
        {(item) => (props) => (
          <StyledImageContainer style={props}>
            <StyledImage src={item.url || item.src} />
          </StyledImageContainer>
        )}
      </Transition>

      {/* <StyledImageContainer>
        <StyledImage />
      </StyledImageContainer>

      <StyledImageContainer>
        <StyledImage />
      </StyledImageContainer> */}

      <FileLoader onClick={onChange} full={false}>
        <StyledUpload
          valid={valid}
          src={require("./images/upload-image.jpg")}
        />
      </FileLoader>
    </StyledContainer>
  );
};

export default CanvasHeaderModalPublishingPreviewView;
