import React from "react";

import CanvasContentSettingsSizeView from "./canvas-content-settings-size-view";

const CanvasContentSettingsSizeContainer = ({ value, onChange, ...props }) => {
  const handlerChange = data => {
    onChange({ ...value, ...data });
  };

  return (
    <CanvasContentSettingsSizeView
      value={value}
      onChange={handlerChange}
      {...props}
    />
  );
};

export default CanvasContentSettingsSizeContainer;
