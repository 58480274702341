import React, { useState } from "react";

import ContentControlSizeView from "./content-control-size-view";

const ContentControlSizeContainer = props => {
  const [lock, setLock] = useState(false);

  const handleChange = ({value, key}) => {
    props.onChangeItems({
      key: "style",
      data: {
        [key]: +value 
      }
    });
  }

  return (
    <ContentControlSizeView {...props} lock={lock} onChangeLock={setLock} onChange={handleChange} />
  );
};

export default ContentControlSizeContainer;
