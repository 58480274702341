import styled from "styled-components";

import { responsiveSize } from "../../utils/font";

export const StyledContainer = styled.div`
  position: absolute;

  padding: 8px 0px;

  background: #fff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  z-index: 999;
`;
