import "@brainhubeu/react-carousel/lib/style.css";

// import Slider from "infinite-react-carousel";
import Carousel, { slidesToShowPlugin } from "@brainhubeu/react-carousel";
import React, { useEffect } from "react";
import { StyledContainer, StyledItem } from "./store-projects-grid-styled";

import { Link } from "react-router-dom";
import Slider from "react-slick";
import StoreProjectsGridCard from "./components/card";

// import { items } from "./store-projects-grid-data";

var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const StoreProjectsGridStyled = ({ data }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <StyledContainer>
      {/* <div
        style={{
          width: 5000,
          // overflow: "hidden",
          display: "flex",
          backgroundColor: "green"
        }}
      ></div> */}
      {Object.values(data.preview).map(item => (
        <StyledItem>
          <Link to={`/canvas/${data.id}`}>
            <StoreProjectsGridCard preview={item.src} />
          </Link>
        </StyledItem>
      ))}

      {/* <div style={{ width: "50%", overflow: "hidden", display: "flex" }}>
        <StyledItem key={items[0].id}>
          <Link to="/1?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiYmEwNjA2YjkyMmFhODJlZWRkMGIzNWVhOTdkNThlM2NlZGE0MDY1NTVhZWFhODc1ZmY3OTYwNGVlOGYxNGEyYTc1MzY4MWE3YWM1MmExMWMiLCJpYXQiOjE1OTI2ODM3OTYsIm5iZiI6MTU5MjY4Mzc5NiwiZXhwIjoxNjI0MjE5Nzk2LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.ZNMrwZCLr9PxpQ98PxLlH241AgaY7SpHNsNMMMswU4wcbIQFQUtWWISLuzTtnpw6DQdnR_HOPJWSaHZswF3PoJk9comS21xUMoAK7CoPZj8ioXamVA81lBFWHdYYH95i_jAzMzxX0edVw-Mg23EzFyybupgOKrmRVVnBQvcxWgXcLv-0WN0SMrxHLCm9rDf57-6377fNX_h9D22fHYD5SdvBqGnEVF-CKH0CDsOQBWx__k2vfmIaDYOkfVKgN9GYxJMQXvAjuAffYoWMForbhh7ixj5KmgLII3UPOA89r0osUOY1c9vHXPKEl2tDUTICKzRDCpOZ-bRvAlJ7rk-oRdmKlBG7564wT7Va9WxC7M6zi9Av_MaPZUwFrNlBxcma1J_mGEzSZ9P4rdEwvRtDmZY2GD5No9A4zt4G8zamuLPpc0d1qsRG-aRIHjPEgG9ACM-OXh73zescuaLM6XnV-9WkrRXedZRpLQ8MXZ40YKHOrsjUze0eGKAM4bHsZ4dUjOZ2Rt_Fsp7r8moH6fhvEKcpibODHQWEbcMWoBRf09AESlrYZ6mHiI-IrU6_hVcJOyvIXqYuw9hInW-TBheFw1JlQMTJpfws_fw4xKRjDTNGm_t__mEIJvcWP3yQADLtVW8ynLnnZftjGLpa3LnGfnjbrv5ZElO7bS_IwRn7bxw">
            <StoreProjectsGridCard data={items[0]} />
          </Link>
        </StyledItem>

        <StyledItem key={items[1].id}>
          <Link to="/1?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiYmEwNjA2YjkyMmFhODJlZWRkMGIzNWVhOTdkNThlM2NlZGE0MDY1NTVhZWFhODc1ZmY3OTYwNGVlOGYxNGEyYTc1MzY4MWE3YWM1MmExMWMiLCJpYXQiOjE1OTI2ODM3OTYsIm5iZiI6MTU5MjY4Mzc5NiwiZXhwIjoxNjI0MjE5Nzk2LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.ZNMrwZCLr9PxpQ98PxLlH241AgaY7SpHNsNMMMswU4wcbIQFQUtWWISLuzTtnpw6DQdnR_HOPJWSaHZswF3PoJk9comS21xUMoAK7CoPZj8ioXamVA81lBFWHdYYH95i_jAzMzxX0edVw-Mg23EzFyybupgOKrmRVVnBQvcxWgXcLv-0WN0SMrxHLCm9rDf57-6377fNX_h9D22fHYD5SdvBqGnEVF-CKH0CDsOQBWx__k2vfmIaDYOkfVKgN9GYxJMQXvAjuAffYoWMForbhh7ixj5KmgLII3UPOA89r0osUOY1c9vHXPKEl2tDUTICKzRDCpOZ-bRvAlJ7rk-oRdmKlBG7564wT7Va9WxC7M6zi9Av_MaPZUwFrNlBxcma1J_mGEzSZ9P4rdEwvRtDmZY2GD5No9A4zt4G8zamuLPpc0d1qsRG-aRIHjPEgG9ACM-OXh73zescuaLM6XnV-9WkrRXedZRpLQ8MXZ40YKHOrsjUze0eGKAM4bHsZ4dUjOZ2Rt_Fsp7r8moH6fhvEKcpibODHQWEbcMWoBRf09AESlrYZ6mHiI-IrU6_hVcJOyvIXqYuw9hInW-TBheFw1JlQMTJpfws_fw4xKRjDTNGm_t__mEIJvcWP3yQADLtVW8ynLnnZftjGLpa3LnGfnjbrv5ZElO7bS_IwRn7bxw">
            <StoreProjectsGridCard data={items[1]} />
          </Link>
        </StyledItem>
      </div> */}
    </StyledContainer>
  );
};

export default StoreProjectsGridStyled;
