import styled from "styled-components";

import { responsiveSize } from "../../../../../../../../../../utils/font";

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${responsiveSize(28)};
  height: ${responsiveSize(28)};

  border: ${responsiveSize(2)} solid #8d01fb;
  border-radius: ${responsiveSize(8)};
  background-color: #fff;

  box-sizing: border-box;
  cursor: pointer;
`;

export const StyledColor = styled.div`
  width: ${responsiveSize(20)};
  height: ${responsiveSize(20)};

  border-radius: ${responsiveSize(4)};
  background: #8d01fb;
`;
