import ContentControlGroup from "../group";
import ContentControlInput from "../input";
import React from "react";
// import { responsiveSize } from "../../../../../../../../utils/font";

import { StyledInput, StyledRow } from "./canvas-control-border-radius-styled";

const CanvasControlBorderRadiusView = ({
  style,
  show,
  onAdd,
  onRemove,
  onChangeItems
}) => {
  const handleChange = ({ value, key }) => {
    onChangeItems({
      key: "style",
      data: {
        [key]: +value
      }
    });
  };

  return (
    <ContentControlGroup
      placholder="Rounding"
      showContent={show}
      onShow={onAdd}
      onHide={onRemove}
    >
      <StyledRow>
        <StyledInput
          as={ContentControlInput}
          icon={require("./images/icon-top-left.svg")}
          value={style.borderTopLeftRadius}
          valueType="number"
          onChange={value =>
            handleChange({ value, key: "borderTopLeftRadius" })
          }
        />

        <StyledInput
          as={ContentControlInput}
          icon={require("./images/icon-top-right.svg")}
          value={style.borderTopRightRadius}
          valueType="number"
          onChange={value =>
            handleChange({ value, key: "borderTopRightRadius" })
          }
        />
      </StyledRow>

      <StyledRow>
        <StyledInput
          as={ContentControlInput}
          icon={require("./images/icon-bottom-left.svg")}
          value={style.borderBottomLeftRadius}
          valueType="number"
          onChange={value =>
            handleChange({ value, key: "borderBottomLeftRadius" })
          }
        />

        <StyledInput
          as={ContentControlInput}
          icon={require("./images/icon-bottom-right.svg")}
          value={style.borderBottomRightRadius}
          valueType="number"
          onChange={value =>
            handleChange({ value, key: "borderBottomRightRadius" })
          }
        />
      </StyledRow>
    </ContentControlGroup>
  );
};

export default CanvasControlBorderRadiusView;
