import React, { useMemo, useState } from "react";

import CanvasHeaderModalPublishingPreviewView from "./canvas-header-modal-publishing-preview-view";

const CanvasHeaderModalPublishingPreviewContainer = (props) => {
  // const [images, setImages] = useState({});

  const handleChange = (value) => {
    let res = { ...props.preview };
    
    let keys = Object.keys(res)
    let id = keys.length == 0 ? 1 : +keys[keys.length - 1] + 1;
    
    console.log("keys", keys);
    console.log("id", id);

    res[id] = value;

    console.log("res", res);

    props.onChangePreview(res);

    props.onChange(JSON.stringify(res));
  };

  return (
    <CanvasHeaderModalPublishingPreviewView
      {...props}
      onChange={handleChange}
    />
  );
};

export default CanvasHeaderModalPublishingPreviewContainer;
