import React, { useMemo, useState } from "react";
import { StyledContainer, StyledItem } from "./canvas-effect-styled";
import { animated, useSpring } from "react-spring";

import CanvasEffectItem from "./components/item";
import { responsiveSize } from "../../../../utils/font";

const items = [
  {
    name: "No effects",
    key: "no_effects",
    image:
      "https://static.canva.com/web/images/d461ce14740df06b826ab8517b88344c.png",
    style: {}
  },
  {
    name: "Shadow",
    key: "shadow",
    image:
      "https://static.canva.com/web/images/544ebef63f65bc118af1a353d8e3456c.png",
    style: { "text-shadow": "rgba(0, 0, 0, 0.4) 7.64559px 7.64559px 0px" }
    // style: "text-shadow: rgba(0, 0, 0, 0.4) 7.64559px 7.64559px 0px;"
  },
  {
    name: "Hovering",
    key: "hovering",
    image:
      "https://static.canva.com/web/images/39e8991a556615f8130d7d36580f9276.jpg",
    // style:
    //   "text-shadow: rgba(0, 0, 0, 0.325) 0px 6.4875px 27.5719px; filter: opacity(1);"
    style: {
      "text-shadow": "rgba(0, 0, 0, 0.325) 0px 6.4875px 27.5719px",
      filter: "opacity(1)"
    }
  },
  {
    name: "Circuit",
    key: "circuit",
    image:
      "https://static.canva.com/web/images/014d16e44f8c5dfddf2ccdb10fb97b6b.png",
    // style:
    //   "caret-color: rgb(0, 0, 0); -webkit-text-stroke: 6.4875px rgb(0, 0, 0); --color-override:transparent;"
    style: {
      "caret-color": "rgb(0, 0, 0)",
      "-webkit-text-stroke": "6.4875px rgb(0, 0, 0)",
      "--color-override": "transparent"
    }
  },
  {
    name: "Combination",
    key: "combination",
    image:
      "https://static.canva.com/web/images/6bec5afe4433f7030024ed9287752d08.png",
    // style:
    //   "caret-color: rgb(0, 0, 0); -webkit-text-stroke: 6.4875px rgb(0, 0, 0); --color-override:transparent; text-shadow: rgb(128, 128, 128) 7.64559px 7.64559px 0px;",
    style: {
      "caret-color": "rgb(0, 0, 0)",
      "-webkit-text-stroke": "6.4875px rgb(0, 0, 0)",
      "--color-override": "transparent",
      "text-shadow": "rgb(128, 128, 128) 7.64559px 7.64559px 0px"
    }
  },
  {
    name: "Echo",
    key: "echo",
    image:
      "https://static.canva.com/web/images/3df11ae4feb176c0e1326a83078863d2.png",
    style: {
      "text-shadow":
        "rgba(0, 0, 0, 0.5) 7.64559px 7.64559px 0px, rgba(0, 0, 0, 0.3) 15.2912px 15.2912px 0px"
    }
    // style:
    //   "text-shadow: rgba(0, 0, 0, 0.5) 7.64559px 7.64559px 0px, rgba(0, 0, 0, 0.3) 15.2912px 15.2912px 0px;"
  },
  {
    name: "Distortion",
    key: "distortion",
    image:
      "https://static.canva.com/web/images/10d3bc08aa9d6ba94ddba7a67a6ff83e.png",
    style: {
      "text-shadow":
        "rgb(0, 255, 255) -3.24375px 0px 0px, rgb(255, 0, 255) 3.24375px 0px 0px"
    }
  },
  {
    name: "Neon",
    key: "neon",
    image:
      "https://static.canva.com/web/images/e4e2863c827d84431b3580f781f95177.jpg",
    style: {
      transform: "translateZ(0px)",
      filter:
        "drop-shadow(rgba(26, 24, 24, 0.95) 0px 0px 2.85991px) drop-shadow(rgba(26, 26, 26, 0.75) 0px 0px 14.2995px) drop-shadow(rgba(26, 26, 26, 0.44) 0px 0px 42.8986px)",
      "--color-override": "rgb(176,176,176)"
    }
  }
];

const FOOTER_HEIGHT = 276;

const CanvasEffectView = ({
  className,
  show,
  slides,
  selectedItemId,
  selectedSlideId,
  saveSlides,
  selectedSliderIndexes
}) => {
  const { transformX, opacity } = useSpring({
    opacity: show ? 1 : 0.5,
    transformX: `perspective(1000px) translateX(${
      show ? 0 : responsiveSize(-256)
    })`,
    duration: 400
  });

  const effect = useMemo(() => {
    return selectedSlideId && selectedItemId && slides[selectedSliderIndexes.row].data[selectedSliderIndexes.column]
          .items[selectedItemId]
      ? slides[selectedSliderIndexes.row].data[selectedSliderIndexes.column]
          .items[selectedItemId]["effect"]
      : false;
  }, [slides, selectedSliderIndexes, selectedItemId]);

  const handleChange = value => {
    console.log("handleChange", {
      selectedSlideId,
      selectedItemId
    });

    if (selectedSlideId && selectedItemId) {
      let res = [...slides];

      res[selectedSliderIndexes.row].data[selectedSliderIndexes.column].items[
        selectedItemId
      ]["effect"] = value;

      saveSlides([...res]);
    }
  };

  return (
    <StyledContainer
      className={className}
      as={animated.div}
      style={{
        opacity,
        transform: transformX.interpolate(t => `${t}`)
      }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between"
        }}
      >
        {items.map(item => (
          <StyledItem
            as={CanvasEffectItem}
            {...item}
            key={item.key}
            active={effect == item.style}
            onClick={() => handleChange(item.style)}
          />
        ))}
      </div>
    </StyledContainer>
  );
};

export default CanvasEffectView;
