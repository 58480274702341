import authReducer from "./modules/auth/reducer";
import { combineReducers } from "redux";
import editorReducer from "./modules/editor/reducer";
import { persistReducer } from "redux-persist";
import projectReducer from "./modules/project/reducer";
import storage from "redux-persist/lib/storage";

const authPersistConfig = {
  key: "auth",
  storage,
  blacklist: ["loading", "error", "showModal"],
  whitelist: ["token", "user", "isAuthenticated"],
};

const projectPersistConfig = {
  key: "project",
  storage,
  // whitelist: ["gallery", "store"],
  blacklist: ["storeLoading", "galleryLoading", "projectLoading", "createProjectLoading"]
};

/**
 * Root reducer
 * @type {Reducer<any> | Reducer<any, AnyAction>}
 */
const rootReducers = combineReducers({
  editor: editorReducer,
  auth: persistReducer(authPersistConfig, authReducer),
  project: persistReducer(projectPersistConfig, projectReducer),
});

export default rootReducers;
