import React from "react";

import { Button } from "../../../../../../components";

import EditorControlSettingsType from "./components/type";
import EditorControlSettingsSize from "./components/size";

import {
  StyledButton,
  StyledContainer,
  StyledContent,
  StyledLabel,
  StyledSettings,
  StyledSlides,
  StyledSize
} from "./editor-control-settings-styled";

const EditorControlSettingsView = ({
  groupedSlides,
  canvas,
  onGroupdSlides,
  onChangeSize
}) => {
  return (
    <>
      <StyledContainer>
        <StyledSettings>
          <StyledLabel>Presets</StyledLabel>
          <EditorControlSettingsType />

          {canvas.type === "custom" && (
            <StyledSize
              as={EditorControlSettingsSize}
              value={{ width: canvas.width, height: canvas.height }}
              onChange={onChangeSize}
            />
          )}
        </StyledSettings>

        <StyledSlides>
          <StyledLabel>Slides</StyledLabel>

          <StyledContent>
            {!groupedSlides ? (
              <StyledButton
                as={Button}
                title="Group Slides"
                onClick={() => onGroupdSlides(true)}
              />
            ) : (
              <StyledButton
                as={Button}
                fill
                title="Ungroup Slides"
                onClick={() => onGroupdSlides(false)}
              />
            )}

            {/* <StyledButton as={Button} title="Edit Slides" /> */}
          </StyledContent>
        </StyledSlides>
      </StyledContainer>
    </>
  );
};

export default EditorControlSettingsView;
