import {
  isAuthenticatedSelector,
  loadingSelector,
  userSelector,
} from "../../modules/auth/selectors";
import { login, register } from "../../modules/auth/actions";

import ModalLoginContainer from "./modal-login-container";
import { connect } from "react-redux";
import { saveAuthShowModal } from "../../modules/auth/actions";

const mapStateToProps = (state) => ({
  user: userSelector(state),
  loading: loadingSelector(state),
  isAuthenticated: isAuthenticatedSelector(state),
});

const mapDispatchToProps = {
  register,
  login,
  saveAuthShowModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalLoginContainer);
