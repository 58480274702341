import React from "react";

import StoreProjectsSortTabs from "./components/tabs";

import { StyledContainer, StyledSelect } from "./store-projects-sort-styles";

const StoreProjectsSortView = ({ className }) => {
  return (
    <StyledContainer className={className}>
      <StoreProjectsSortTabs />

      <StyledSelect icon={require("./images/arrow.svg")}>
        <option>Last Created</option>
      </StyledSelect>
    </StyledContainer>
  );
};

export default StoreProjectsSortView;
