import styled from "styled-components";

import { responsiveSize } from "../../utils/font";

export const StyledContainer = styled.div`
  perspective: 25px;
`;

export const StyledInner = styled.div`
  transition: transform 0.5s;
`;
