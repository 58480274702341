import React, { useEffect, useState, useMemo } from "react";

import EditorControlArtboardView from "./editor-control-artboard-view";

const EditorControlArtboardContainer = ({
  historyStack,
  activeHistoryStep,
  saveSlides,
  saveCanvas,
  saveActiveHistoryStep
}) => {
  const historyStackLength = useMemo(() => {
    return Object.keys(historyStack).length;
  }, [historyStack]);

  const handleChange = step => {
    let historyStackKeys = Object.keys(historyStack);
    let nextId = step == "next" ? activeHistoryStep + 1 : activeHistoryStep - 1;

    console.log({
      nextId,
      historyStack: historyStack
    });

    console.log(
      "handleChange",
      nextId < 0 || nextId > +historyStackKeys[historyStackKeys.length - 1]
    );

    if (
      nextId < 0 ||
      !historyStackKeys.length ||
      nextId > +historyStackKeys[historyStackKeys.length - 1]
    )
      return;

    saveActiveHistoryStep(nextId);

    console.log("historyStack", historyStack);
  };

  return (
    <EditorControlArtboardView
      historyStack={historyStack}
      activeHistoryStep={activeHistoryStep}
      historyStackLength={historyStackLength}
      onChange={handleChange}
    />
  );
};

export default EditorControlArtboardContainer;
