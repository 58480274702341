import {
  StyledIcon,
  StyledInput,
  StyledLabel
} from "./canvas-content-control-input-styled";

import { Icon } from "../../../../../../../../components";
import PropTypes from "prop-types";
import React from "react";

const sizes = {
  extraSmall: 47,
  small: 54,
  base: 62,
  big: 73
};

const CanvasContentControlInputView = ({
  icon,
  iconSize,
  value,
  defaultValue,
  valueType,
  size,
  type,
  className,
  onChange
}) => {
  const handleChange = (value) => {
    var numbers = /^[0-9]+$/;

    console.log("valueType", valueType);

    if (valueType == "number" && value.match(numbers) || !value) {
     onChange(value)
    } else if (valueType != "number") onChange(value)
  }

  return (
    <StyledLabel className={className} width={sizes[size]}>
      <StyledInput
        value={value}
        type={type}
        isIcon={!!icon}
        value={value}
        onChange={(event) => handleChange(event.target.value)}
        onBlur={() => { if (!value && defaultValue) onChange(defaultValue) }}
      />

      {/* <p>{valueType}</p> */}

      {icon && <StyledIcon as={Icon} src={icon} size={iconSize} />}
    </StyledLabel>
  );
};

CanvasContentControlInputView.propTypes = {
  size: PropTypes.oneOf(["small", "base", "big"]),
  iconSize: PropTypes.number
};

CanvasContentControlInputView.defaultProps = {
  size: "base",
  iconSize: 16
};

export default CanvasContentControlInputView;
