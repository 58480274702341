import { StyledContainer, StyledImage, StyledWrapper } from "./icon-styled";

import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { icons } from "./icon-config";

const Icon = ({ name, src, size, color, type, style, className, onClick }) => {
  const iconSize = useMemo(() => {
    if (typeof size === "object") {
      return size;
    } else {
      return {
        width: size,
        height: size
      };
    }
  }, [size]);

  return (
    (!!src || icons[name]) && (
      // <StyledContainer onClick={onClick}>
      <StyledImage
        style={style}
        className={className}
        src={src || icons[name]}
        size={iconSize}
        color={color}
        type={type}
        onClick={onClick}
      />

      // {/* <StyledWrapper /> */}
      // </StyledContainer>
    )
  );
};

Icon.propTypes = {
  name: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  color: PropTypes.string
};

Icon.defaultProps = {
  size: 24,
  color: null
};

export default Icon;
