import ArrowDown from "./images/arrow-down.png";
import ArrowLeft from "./images/arrow-left.png";
import ArrowRight from "./images/arrow-right.png";
import ArrowUp from "./images/arrow-up.png";
import Back from "./images/back.svg";
import Close from "./images/close.svg";
import Download from "./images/download.png";
import Folder from "./images/folder.png";
import Help from "./images/help.png";
import Keynote from "./images/keynote.png";
import Like from "./images/like.png";
import Liked from "./images/liked.png";
import Menu from "./images/menu.png";
import Minus from "./images/minus.svg";
import Plus from "./images/plus.svg";
import Search from "./images/search.png";
import Share from "./images/share.png";
import Snape from "./images/snape.png";
import Сlosed from "./images/closed.png";

export const icons = {
  "arror-right": ArrowRight,
  "arror-left": ArrowLeft,
  "arror-up": ArrowUp,
  "arrow-down": ArrowDown,
  close: Close,
  menu: Menu,
  search: Search,
  snape: Snape,
  share: Share,
  back: Back,
  folder: Folder,
  like: Like,
  liked: Liked,
  help: Help,
  download: Download,
  closed: Сlosed,
  keynote: Keynote,
  plus: Plus,
  minus: Minus,
};
