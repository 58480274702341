import TopLineContainer from "./top-line-container";
import { connect } from "react-redux";
import { isAuthenticatedSelector } from "../../modules/auth/selectors";
import { saveAuthShowModal } from "../../modules/auth/actions";
import { showModalSelector } from "../../modules/auth/selectors";

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticatedSelector(state),
  showModal: showModalSelector(state),
});

const mapDispatchToProps = {
  saveAuthShowModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopLineContainer);
