import React, { createRef, useEffect } from "react";
import {
  StyledContainer,
  StyledContent,
  StyledIcon,
  StyledLoader,
  StyledText,
  StyledTextLoader
} from "./button-styled";

import Icon from "../icon";
import PropTypes from "prop-types";
import RingLoader from "react-spinners/RingLoader";
import { responsiveSize } from "../../utils/font";

const options = {
  big: { height: 51, borderRadius: 6, fontSize: 16 },
  large: { height: 40, borderRadius: 4, fontSize: 12 },
  small: { height: 36, borderRadius: 8, fontSize: 12 },
  base: { height: 30, borderRadius: 4, fontSize: 12 }
};

const Button = ({
  title,
  icon,
  size,
  type,
  round,
  animated,
  full,
  backgroundColor,
  titleColor,
  loading,
  disabled,
  progress,
  styleContainer,
  styleText,
  styleIcon,
  onClick
}) => {
  return (
    <StyledContainer
      style={styleContainer}
      size={size}
      backgroundColor={backgroundColor}
      round={round}
      full={full}
      options={options}
      disabled={loading || disabled}
      onClick={onClick}
      activeOpacity={animated ? 1 : 0.2}
    >
      <StyledContent loading={loading}>
        {icon && <StyledIcon style={styleIcon} src={icon} />}

        {title && (
          <StyledText
            style={styleText}
            round={round}
            size={size}
            options={options}
            backgroundColor={backgroundColor}
            titleColor={titleColor}
          >
            {title}
          </StyledText>
        )}
      </StyledContent>

      {loading && (
        <StyledLoader>
          {/* {progress != null && (
            <StyledTextLoader
              round={round}
              backgroundColor={backgroundColor}
              titleColor={titleColor}
            >
              {progress}%
            </StyledTextLoader>
          )} */}

          <RingLoader
            size={responsiveSize(20)}
            color={"#fff"}
            loading={loading}
          />
        </StyledLoader>
      )}
    </StyledContainer>
  );
};

Button.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  progress: PropTypes.string,
  backgroundColor: PropTypes.string,
  titleColor: PropTypes.string,
  size: PropTypes.oneOf(["big", "large", "small", "base"]),
  type: PropTypes.oneOf(["solid", "clear"]),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  animated: PropTypes.bool,
  full: PropTypes.bool,
  styleContainer: PropTypes.object,
  styleText: PropTypes.object,
  onPress: PropTypes.func
};

Button.defaultProps = {
  size: "base",
  type: "solid",
  backgroundColor: "#EE0D29",
  titleColor: "#fff",
  round: false,
  loading: false,
  disabled: false,
  progress: null,
  animated: false,
  full: false,
  styleContainer: {},
  styleText: {}
};

export default Button;
