import React from "react";

import {
  StyledContainer,
  StyledItem,
  StyledLabel
} from "./canvas-content-settings-size-styled";

import { Input } from "../../../../../../../../components";

const CanvasContentSettingsSizeView = ({ className, value, onChange }) => {
  return (
    <StyledContainer className={className}>
      <StyledItem>
        <StyledLabel>Width</StyledLabel>

        <Input
          value={value.width}
          onChange={value => onChange({ width: value })}
        />
      </StyledItem>
      <StyledItem>
        <StyledLabel>height</StyledLabel>

        <Input
          value={value.height}
          onChange={value => onChange({ height: value })}
        />
      </StyledItem>
    </StyledContainer>
  );
};

export default CanvasContentSettingsSizeView;
