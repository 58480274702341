import PropTypes from "prop-types";

import React, { useState } from "react";

import { StyledContainer, StyledIndificator } from "./toggle-styled";

const ToggleView = ({ className, active, onChange }) => {
  return (
    <StyledContainer
      className={className}
      active={active}
      onClick={() => onChange(!active)}
    >
      <StyledIndificator active={active} />
    </StyledContainer>
  );
};

ToggleView.propTypes = {
  active: PropTypes.bool,
  onChange: PropTypes.func
};

ToggleView.defaultProps = {
  active: false,
  onChange: () => {}
};

export default ToggleView;
