import Button from "../../../../components/button";
import React from "react";
import TopLine from "../../../../components/top-line";

// import EditorHeaderExport from "./componets/export";
// import EditorHeaderPosition from "./componets/position";

const EditorHeaderView = ({ onRegister }) => {
  return (
    <TopLine
      // componentRight={<Button title="Create Project" />}
      // componentRight={<Button title="REGISTER" onClick={onRegister} />}
      // onRegister={onRegister}
      activeItem={"store"}
      // componentRight={
      //   <>
      //     <EditorHeaderExport />

      //     <EditorHeaderPosition />
      //   </>
      // }
    />
  );
};

export default EditorHeaderView;
