import React from "react";

import { Select } from "../../../../components";

import { StyledContainer } from "./gallery-header-styled";

const options = [{ value: "last-created", label: "Last Created" }];

const GalleryHeaderView = ({ className }) => {
  return (
    <StyledContainer className={className}>
      <Select options={options} />
    </StyledContainer>
  );
};

export default GalleryHeaderView;
