import styled from "styled-components";

import { responsiveSize } from "../../utils/font";

export const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  padding: 0px ${responsiveSize(20)};
`;

export const StyledItem = styled.div`
  flex-shrink: 0;
  padding: 0px ${responsiveSize(20)};
  margin-bottom: ${responsiveSize(45)};
  width: calc(100% / ${props => props.flex});
`;
